import React, { useEffect,useRef } from 'react';
import { useState } from 'react';
import axios from 'axios'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
 
// import faker from 'faker';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);


export default function Histogram(prop) {


  // console.log(histogramDate); // Check the value of histogramDate
  console.log(prop)
  console.log(prop.farmID,prop.farmName,prop.index,prop.date);
  const [labelsData,setLabelsData] =useState([]);
const [histogramNDVI,setHistogramNDVI] =useState([]);
const [histogramNDMI,setHistogramNDMI] =useState([]);
const options = {
  scales: {
    y: {
      beginAtZero: true,
      title: {
        display: true,
        text: 'Frequency',
        font: {
          size: 15,
      },
      }
    },
    x: {
      title: {
        display: true,
        text: 'Bins',
        font: {
          size: 15,
      },
      }
    }
  },
  responsive: true,
  plugins: {
    legend: {
      position: 'top' as const,
    },
    title: {
      display: true,
      text: 'Chart.js Bar Chart',
    },
  },
};


 
 const roundedLabels = labelsData.map((label) => parseFloat(label).toFixed(2));
 const labels = roundedLabels;
const  data = {
  labels,
  datasets: [
    {
      label: 'NDVI',
      data:(prop.index==="NDVI"|| prop.index==="both") ?histogramNDVI:null,
      backgroundColor: 'rgba(255, 99, 132, 0.5)',
    },
    {
      label: 'NDMI',
      data:(prop.index==="NDMI"|| prop.index==="both") ?histogramNDMI:null,
      backgroundColor: 'rgba(45, 99, 132, 0.5)',
    },
  ],
};
// let yourDate = new Date()
// yourDate.toISOString().split('T')[0]
// function formatDate(yourDate) {
//   var d = new Date(yourDate),
//       month = '' + (d.getMonth() + 1),
//       day = '' + d.getDate(),
//       year = d.getFullYear();

//   if (month.length < 2) 
//       month = '0' + month;
//   if (day.length < 2) 
//       day = '0' + day;

//   return [year, month, day].join('-');
// }
// const fDate=formatDate(yourDate);
// console.log(fDate)
// const date = useRef(fDate);
// const index = useRef("NDVI");
// function handleIndexChange(event)
// {
// index.current=event.target.value;
// console.log(index.current);
// }
// function handleDateChange(event)
// {
//   date.current=event.target.value;
//   console.log(date.current);
// }


// function trigger(event)
// {
//   event.preventDefault();
//   console.log("HIT")
//   console.log(index.current,date.current,farmID.farmID,farmID.farmName);
//     const res = axios.get(`https://tonwv78p3g.execute-api.us-west-2.amazonaws.com/test1/histogram?farmID=${farmID}&farmName=${farmName}&index=${index}&date=${date}`);
//     res.then((response)=>{
//       console.log(response)
//       setLabelsData(response.data.bins);
//       setHistogramData(response.data.histogram);
//     })
  
// }
useEffect(()=>{
  const  trigger=async()=>
  {
    // event.preventDefault();
    console.log("HIT")
    // console.log(farmID.farmID,farmID.farmName,index,date);
      const res1 = axios.get(`https://tonwv78p3g.execute-api.us-west-2.amazonaws.com/test1/histogram?farmID=${prop.farmID}&farmName=${prop.farmName}&index=NDVI&date=${prop.date}`);
      res1.then((response)=>{
        console.log(response)
        setLabelsData(response.data.bins);
        setHistogramNDVI(response.data.histogram);
      })
      const res2 = axios.get(`https://tonwv78p3g.execute-api.us-west-2.amazonaws.com/test1/histogram?farmID=${prop.farmID}&farmName=${prop.farmName}&index=NDMI&date=${prop.date}`);
      res2.then((response)=>{
        console.log(response)
        setLabelsData(response.data.bins);
        setHistogramNDMI(response.data.histogram);
      })
    
  }
  trigger()
},[prop.date])
 return(<>
  
  <Bar options={options} data={data}/>
  </>);
}
