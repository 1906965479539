import React, { useEffect,useState,useRef } from "react";
import axios from "axios";
import { CSVLink, CSVDownload } from "react-csv";

function DownloadButton({farmID,fieldValue,farmName}) {
    const [clicked,setClicked]=useState(false);
    console.log(fieldValue);
    const startDate = useRef(null);
    const endDate = useRef(null);
    const headers = [
        { label: "Timestamp", key: "timestamp" },
        { label: "Atmospheric Temprature (°C)", key: "atmosphericTemprature" },
        { label: "Atmospheric Pressure (hPa)", key: "atmosphericPressure" },
        { label: "Atmospheric Humidity (%RH)", key: "atmosphericHumidity" },
        { label: "Wind Speed (km/h)", key: "windSpeed" },
        // { label: "Wind Direction (°N) ", key: "windDirection" },
        { label: "Canopy Temprature(°C)", key: "canopyTemprature" },
        { label: "Leaf Wetness (%RH)", key: "leafWetness" },
        { label: "Solar Voltage (V)", key: "solarVoltage" },
        // { label: "Lux Readings (w/m2)", key: "luxReadings" },
        { label: "N Readings (mg/kg)", key: "nReadings" },
        { label: "P Readings (mg/kg)", key: "pReadings" },
        { label: "K Readings (mg/kg)", key: "kReadings" },
        { label: "Soil Moisture_1 (cb)", key: "soilMoisture1" },
        { label: "Soil Moisture_2 (cb)", key: "soilMoisture2" },
        { label: "Soil Humidity (%RH)", key: "soilHumidity" },
        { label: "Precipitation (mm)", key: "precipitation" },
        { label: "Battery Voltage (V)", key: "batteryVoltage" },
        { label: "Soil Temperature(°C)", key: "soilTemperature" },
      ];
    const [csvLinkData, setCsvLinkData] = useState([]); 
  async function fetchData() {
    console.log(startDate.current.value);
    console.log(endDate.current.value);
    console.log(farmID);
    let API_data = [];
    if(startDate.current.value && endDate.current.value )
    {
    let res = await axios.get(
      `https://fmserver.escorts.co.in/admin/farmer/readings?parameter=rain_guage_readings&start_date=${startDate.current.value}&end_date=${endDate.current.value}&farm_id=${farmID}`
    );
    console.log(res);
    const timestamps = res?.data?.result?.map((item) => item?.obj?.timestamp);
    console.log(timestamps);
    const apiRequests = fieldValue.map(async (value, index) => {
      let res = await axios.get(
      `https://fmserver.escorts.co.in/admin/farmer/readings?parameter=${value.api_name}&start_date=${startDate.current.value}&end_date=${endDate.current.value}&farm_id=${farmID}`
      );
      return res.data.result;
    });
    API_data = await Promise.all(apiRequests);
    console.log(API_data);
    const data = [];
    for (let i = 0; i < timestamps?.length; i++) {
      data.push({
        timestamp: timestamps[i],
        atmosphericTemprature: API_data?.[0]?.[i]?.obj?.reading,
        atmosphericPressure: API_data?.[1]?.[i]?.obj?.reading,
        atmosphericHumidity: API_data?.[2]?.[i]?.obj?.reading,
        windSpeed: API_data?.[3]?.[i]?.obj?.reading,
        // windDirection: API_data?.[4]?.[i]?.obj?.reading,
        canopyTemprature: API_data?.[4]?.[i]?.obj?.reading?.leaf_temp,
        leafWetness: API_data?.[5]?.[i]?.obj?.reading?.leaf_humi,
        solarVoltage: API_data?.[6]?.[i]?.obj?.reading,
        luxReadings: API_data?.[7]?.[i]?.obj?.reading,
        nReadings: API_data?.[8]?.[i]?.obj?.reading.N,
        pReadings: API_data?.[9]?.[i]?.obj?.reading.P,
        kReadings: API_data?.[10]?.[i]?.obj?.reading.K,
        soilMoisture1: API_data?.[11]?.[i]?.obj?.reading,
        soilMoisture2: API_data?.[12]?.[i]?.obj?.reading,
        soilHumidity: API_data?.[13]?.[i]?.obj?.reading,
        precipitation: API_data?.[14]?.[i]?.obj?.reading,
        batteryVoltage: API_data?.[15]?.[i]?.obj?.reading,
        soilTemperature: API_data?.[16]?.[i]?.obj?.reading,
      });
    }
    console.log(data);
    setCsvLinkData(data);
    console.log(csvLinkData.length);
  }
  }
  const handleSubmit = (event) => {
    event.preventDefault();
    fetchData();
  };

  useEffect(() => {
    if (clicked) {
      fetchData();
    }
  }, [clicked]);
  useEffect(() => {
    setCsvLinkData([]);
    if (endDate.current) {
        endDate.current.value = null;
    }
    if (startDate.current) {
        startDate.current.value = null;
    }
}, [farmID]);

  return (
    <>
      {!clicked ? (
        <button
          style={{borderRadius: "20px", backgroundColor: "#55a3ffe8" }}
          onClick={() => setClicked(true)}
        >
          Download IoT data
        </button>
      ) : (
        <>
          <form onSubmit={handleSubmit}>
            <input style={{height:"28px"}} ref={startDate} type="date" />
            <input style={{height:"28px"}} ref={endDate} type="date" />
            <button type="submit">Fetch Data</button>
          </form>
          {csvLinkData.length >0 ? 
            <CSVLink filename={`${farmName},${startDate.current.value} to ${endDate.current.value}`} data={csvLinkData} headers={headers}>
            Download CSV
            </CSVLink>
          :<p>Data is not present</p>}
        </>
      )}
    </>
  );
}

export default DownloadButton;