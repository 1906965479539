import React from 'react';
import WindCompass from './WindCompass.jsx';
function Compass({iot}) {
  return (
    <div>
      <WindCompass windDirection={iot?.readings?.wind_direction_readings?.at?.(-1).reading} />
    </div>
  );
}

export default Compass;
