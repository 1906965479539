import { useEffect } from "react";
import "./notificationCenter.css";
import Card from "../card/Card";
import NotificationBar from "./NotificationBar.jsx";
import axios from "axios";
import { useContext, useState } from "react";
import { UserContext } from "../../context/UserContext";
const NotificationCenter=()=>{
    const { state } = useContext(UserContext);
    const authToken = state.authToken;
    const [Notifications,setNotifications]=useState([]);
    const [loading, setLoading] = useState(true);

     useEffect(() => {
    const fetchData = async () => {
       await axios.get("https://fmserver.escorts.co.in/admin/notificationsAdmin/admin_notification", {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((response) => {
          setLoading(false);  
          console.log(response.data.notifications);
          setNotifications(response.data.notifications);

        })
        .catch((error) => {
          console.log(error);
        });
    };
    fetchData();
  }, [authToken]);

    return(
        <>
            <Card otherClass="notification">
                {loading?<p key="loading-message">Loading....</p>:
                    Notifications.length!=0? Notifications.map((value,index)=>{
                    return(<NotificationBar  key={value.farm_id}  farm_id={value.farm_id} farmer_id={value.farmer_id} notification_body={value.notification_body} notification_title={value.notification_title}  />)
                    }):<p>No Notifcations</p>
                }
            </Card>        
        </>
    )
}
export default NotificationCenter;