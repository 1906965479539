import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { useContext, useEffect, useState, useRef } from "react";
import { UserContext } from "../../context/UserContext";
import axios from "axios";
import "./AverageNew.css";
import { CSVLink } from "react-csv";
import Topbar from "../../components/topbar/Topbar";
import Navbar from "../../components/navbar/Navbar";

export default function AverageNew()
 {
  const { state } = useContext(UserContext);
  const authToken = state.authToken;
  const startDate = useRef();
  const endDate = useRef();
  const farmID = useRef();
  const [csvLinkData, setCsvLinkData] = useState("");
  const [avgData, setAvgData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(1000);
  const [loading, setLoading] = useState(true);
  
  const headers = [
    { label: "farm_id", key: "farmId" },
    { label: "AvgAtmosphericTemp", key: "avgTemp" },
    { label: "AvgHumidity", key: "avgRelHum" },
    { label: "AvgSoilTemperature", key: "avgSoilTemp" },
    { label: "AvgRain", key: "avgRain" },
    { label: "createdAt", key: "createdAt" },
  ];
  const columns = [
    { id: "farm_id", label: "farmId", Width: 10 },
    { id: "avg_atmospheric_temp", label: "avgTemp", Width: 10 },
    { id: "avg_humidity", label: "avgRelHum", Width: 10 },
    { id: "avg_soil_temp", label: "avgSoilTemp", Width: 10 },
    { id: "avg_rainfall", label: "avgRain", Width: 10 },
    { id: "createdAt", label: "createdAt", Width: 200 },
  ];

  function createData(
    farmId,
    avgTemp,
    avgRelHum,
    avgSoilTemp,
    avgRain,
    createdAt,
  ) {
    return {
      farm_id:farmId,
      avg_atmospheric_temp:avgTemp,
      avg_humidity:avgRelHum,
      avg_soil_temp:avgSoilTemp,
      avg_rainfall:avgRain,
      createdAt,
    };
  }

  useEffect(() => {
    const fetchData = async () => {
       await axios
        .get("https://fmserver.escorts.co.in/admin/farmer/average-sensor-data-new", {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((response) => {
          console.log(response.data);
          setAvgData(response.data);
          console.log(avgData);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
        });
    };
    fetchData();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  function convertToIst(timeStamp)
  {
    const utcDate = new Date(timeStamp);
    const istFormatted = utcDate.toLocaleString("en-IN");
    return istFormatted;
  }
  function changeDateFormat(val) {
    const date = new Date(val);
    const new_date = date.toUTCString();
    return new_date;
  }
  const filterDataByDate = (data, startDate, endDate) => {
    console.log(data, startDate, endDate)
    if (!startDate || !endDate) {
      return data;
    }
  
    const filteredData = data.filter((item) => {
      const createdAt = new Date(item.createdAt);
      const formattedDate=createdAt.toISOString().split("T")[0]
      console.log(formattedDate);
      return formattedDate >= startDate && formattedDate <= endDate;
    });
  
    return filteredData;
  };
  function rtwsFormSubmitHandler(e) {
    setLoading(true);
    e.preventDefault();
  
    const fetchData = async () => {
      const startDateValue = startDate.current.value;
      const endDateValue = endDate.current.value;
  
      if (!farmID.current.value) {
        console.log("Farm ID is required");
        // return;
      }
  
      if (!startDateValue || !endDateValue) {
        console.log("Start date and end date are required");
        // return;
      }
  
      console.log(farmID.current.value, startDateValue, endDateValue);
      const farm = farmID.current.value;
  
      try {
        const response = await axios.get(
          `https://fmserver.escorts.co.in/admin/farmer/average-sensor-data-new/${farm}`,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );
  
        const filteredData = filterDataByDate(
          response.data,
          startDateValue,
          endDateValue
        );
  
        console.log(filteredData);
        setAvgData(filteredData);
        setLoading(false);
        console.log(avgData);
      } catch (error) {
        console.log(error);
      }
    };
  
    fetchData();
  }
  useEffect(() => {
    // const timestamps = iot?.readings?.rain_guage_readings?.map(item => item?.timestamp)
    // console.log(timestamps)
    console.log(avgData.length);
    const data = [];
    for (let i = 0; i < avgData.length; i++) {
      data.push({
        // timestamp: timestamps[i],
        farmId: avgData?.[i]?.farm_id,
        avgTemp: avgData?.[i]?.avg_atmospheric_temp,
        avgRelHum: avgData?.[i]?.avg_humidity,
        avgSoilTemp: avgData?.[i]?.avg_soil_temp,
        avgRain: avgData?.[i]?.avg_rainfall,
        createdAt: convertToIst(avgData?.[i]?.createdAt),
      });
    }
    // console.log(data)
    // setCsvData(data)
    setCsvLinkData({ filename: "New Average Sensor Data sheet ", headers: headers, data: data });
    // console.log({filename: 'file.csv', headers: headers, data: data})
  }, [avgData]);
  const [navExpanded, setNavExpanded] = useState(false);
  const enterHandler = (e) => {
    setNavExpanded(true);
  };
  const leaveHandler = (e) => {
    setNavExpanded(false);
  };
  return (
    <>
    <Topbar routeTitle={"New Average Data sheet"} no_content />
    {loading?<h1>data is loading, please wait</h1>:
    <div className="RTWS_main">
      <Navbar
          expand={navExpanded}
          enterHandler={enterHandler}
          leaveHandler={leaveHandler}/>
          <div style={{display:"flex", flexDirection:"column"}}>
      <Paper sx={{ width: "90vw", overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: 550 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.Width, color: "blue" }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {avgData?.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
              ).map((item,index) => {
                const row = createData(
                  item?.farm_id,
                  item?.avg_atmospheric_temp.substring(0, 5),
                  item?.avg_humidity.substring(0, 5),
                  item?.avg_soil_temp.substring(0, 5),
                  item?.avg_rainfall.substring(0, 5),
                  convertToIst(item?.createdAt),
                );
                console.log(row);
                return (
                  <TableRow  hover role="checkbox" tabIndex={-1} key={index}>
                    {
                    columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id}  align={column.align}>
                          {column.format
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[1000, 1200, 1400]}
          count={avgData?.length || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <form onSubmit={rtwsFormSubmitHandler} className="RTWS_filter_form">
        <div>
          <input
            ref={farmID}
            type="text"
            placeholder="Enter the farmID"
            title="Enter the farmID"
          />
        </div>
        <div>
          <input
            ref={startDate}
            type="date"
            placeholder="Date From"
            title="Date from"
          />
        </div>
        <div>
          <input
            ref={endDate}
            type="date"
            placeholder="Date upto"
            title="Date upto"
          />
        </div>
        <button type="submit">Submit</button>
      </form>
      {csvLinkData?.data?.length ? <CSVLink {...csvLinkData} > Download </CSVLink> : <>Choose farm to download</>}
      </div>
      {/* <h4>{startDate}<span>to</span> {endDate}</h4> */}
    </div>
 }
    </>
  );
}



