import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from 'react-chartjs-2';
import "./AgroChart.css";
import ChartRange from './ChartRange';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const apiUrl = 'https://tonwv78p3g.execute-api.us-west-2.amazonaws.com/test1/fmcc';

const AgroChart = ({ farmID, farmName, iot, chartType, setCsvLinkData }) => {
  console.log(farmID);
  ChartJS.defaults.font.size = 10;
  const [data, setData] = useState(null);
  const [index, setIndex] = useState('NDVI');

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get(apiUrl, {
        params: { farmID, farmName, index: index },
      });
      setData(response.data);
      console.log("AVG INDEX VALUES -----  ", response.data);
    };
    fetchData();
  }, [farmID, farmName, index]);

  if (!data) return <div>Loading...</div>;

  //   const { Dates, Avg_index_values } = data;
  const Dates = data.dates;
  const numbers = data.avg_index_values;


  const Avg_index_values = numbers.map(myFunction);
  function myFunction(value) {
    return value[0];
  }


  const options = {
    tension: 0.3,
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "bottom",
        labels: {
          font: {
            size: 10,
          }
        }
      },
      scales: {
        y: {
          ticks: {
            font: {
              size: 10,
            }
          }
        }
      },
      title: {
        display: false,
        text: "Chart.js Line Chart",
      },
    },
  };
  const chartData = {
    labels: Dates,
    datasets: [
      {
        label: `Avg ${index} Values vs Time`,
        data: Avg_index_values,
        fill: false,
        borderColor: index === 'NDVI' ? 'rgb(75, 192, 192)' : 'rgb(192, 75, 75)',
        tension: 0.1,
      },
    ],
  };


  const handleIndexChange = (e) => {
    setIndex(e.target.value);
  };

  return (
    <div className='agro-chart-div'>
      {chartType === "AGRO" ?
        <>
          <div>
            <label htmlFor="index-select">Index:</label>
            <select id="index-select" value={index} onChange={handleIndexChange}>
              <option value="NDVI">NDVI</option>
              <option value="NDMI">NDMI</option>

            </select>
          </div>
          {console.log("yes2 -----  ", Dates, Avg_index_values)}
          <div className='chart__wrapper'>
            <Line data={chartData} options={options} ></Line>
          </div>
        </>
        :
        <>
          <div className="iot__chart__container"> <ChartRange iot={iot} farmId={farmID} setCsvLinkData={setCsvLinkData} /> </div>
        </>
      }
    </div>
  );
};

export default AgroChart;
