import Card from '../../components/card/Card'
import './compare.css'
import SearchIcon from '../../assets/search icon.svg'
import axios from 'axios'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Topbar from '../../components/topbar/Topbar'
import { UserContext } from '../../context/UserContext'
import MultiChart from "../../components/MultiChart/MultiChart.tsx";
import ChartRange from '../../components/Chart/ChartRange'
import CombinedChart from '../../components/combinedChart/CombinedChart'
import CombinedMultichart from '../../components/combinedChart/CombinedMultichart.tsx'


const Compare = () => {
    const { authToken } = useContext(UserContext)
    const navigate = useNavigate()
    const token = authToken;
    // check for token -> send to login if not found
    useEffect(() => {
        token ?? (alert('Login first') && navigate('/login'))
    }, [])

    const [isFetching0, setIsFetching0] = useState(false)
    const [isFetching1, setIsFetching1] = useState(false)
    const [leftFarm, setLeftFarm] = useState('')
    const [rightFarm, setRightFarm] = useState('')
    const [noSensors0, setNoSensors0] = useState(false)
    const [noSensors1, setNoSensors1] = useState(false)
    const leftRef = useRef()
    const rightRef = useRef()
    const fetchFarm = async (id, lr) => {
        if (id) {
            try {
                lr === 1 ? setIsFetching1(true) : setIsFetching0(true)
                const res = await axios.get(`https://fmserver.escorts.co.in/admin/farmer/farm/${id}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
                lr === 1 ? setIsFetching1(false) : setIsFetching0(false)
                console.log(res.data)
                if (res.data.success) {
                    lr === 1 ? setRightFarm(res.data) : setLeftFarm(res.data)
                }
                else {
                    lr === 1 ? setRightFarm('') : setLeftFarm('')
                    lr === 1 ? setNoSensors1(true) : setNoSensors0(true)
                }

            } catch (error) {
                lr === 1 ? setIsFetching1(false) : setIsFetching0(false)
                console.log(error)
            }
        }
    }
    return (
        <div>
            <Topbar routeTitle={'Compare Farms'} no_content />
            <div className='compare__main'>
                <div className='iot__left'>
                    <Card>
                        <form className='compare__searchbox' onSubmit={e => { e.preventDefault(); fetchFarm(leftRef.current.value, 0) }}>
                            <input ref={leftRef} type="text" className="search" placeholder='Enter farm ID to search' />
                            <img className='search-icon' onClick={e => { fetchFarm(leftRef?.current?.value, 0) }} src={SearchIcon} alt='search Img' />
                        </form>
                        <div className='iot__chart'>
                            {isFetching0 ? <div className='compare__chart__nodata__title'> Fetching data... </div> : (
                                <>
                                    {noSensors0 && <div className='compare__chart__nodata__title'> No data from sensors </div>}
                                    {leftFarm ? <div className='iot__chart__container'><ChartRange iot={leftFarm} farmId={3} /><MultiChart iotData={leftFarm} farmId={leftRef?.current?.value} /></div> : <div className='compare__chart__nodata__title'>Search a farm to get data</div>}
                                </>
                            )}
                        </div>
                    </Card>
                </div>
                <div className='iot__right'>
                    <Card>
                        <form className='compare__searchbox' onSubmit={e => { e.preventDefault(); fetchFarm(rightRef?.current?.value, 1) }}>
                            <input ref={rightRef} type="text" className='search' placeholder='Enter farm ID to search' />
                            <img className='search-icon' onClick={e => { fetchFarm(rightRef?.current?.value, 1) }} src={SearchIcon} alt='search Img' />
                        </form>
                        <div className='iot__chart'>
                            {isFetching1 ? <div className='compare__chart__nodata__title'> Fetching data... </div> : (
                                <>
                                    {noSensors1 && <div className='compare__chart__nodata__title'> No data from sensors </div>}
                                    {rightFarm ? <div className="iot__chart__container"><ChartRange iot={rightFarm} /><MultiChart iotData={rightFarm} farmId={rightRef?.current?.value} /></div> :
                                        <div className='compare__chart__nodata__title'>Search a farm to get data</div>}
                                </>
                            )}
                        </div>

                    </Card>
                </div>
            </div>
            <div className='combined__graphs'>
                <Card>
                    <div className='combined__title'>Combined Graph</div>
                    <div className='combined__graphsContainer'>

                        {isFetching1 ? <div className='compare__chart__nodata__title'> Fetching data... </div> : (
                            <>
                                {noSensors1 && <div className='compare__chart__nodata__title'> No data from sensors </div>}
                                {leftFarm && rightFarm ?
                                    <div className="iot__chart__container">
                                        <CombinedChart iot1={leftFarm} iot2={rightFarm} farmId1={leftRef.current.value} farmId2={rightRef.current.value} />
                                        <CombinedMultichart iot1={leftFarm} iot2={rightFarm} farmId1={leftRef.current.value} farmId2={rightRef.current.value} /></div>
                                    :
                                    <div className='compare__chart__nodata__title'>Search a farm to get data</div>}
                            </>
                        )}
                    </div>
                </Card>
            </div>
        </div>
    )
}

export default Compare