import Card from "../card/Card";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../context/UserContext";
import axios from "axios";
import "./navbar.css";
// import SettingsIcon from "/assets/Settings.svg";
import OverviewIcon from "../../assets/Overview Icon.svg";
// import FarmerIcon from "/assets/Farmer.svg";
// import IotIcon from "/assets/Iot Icon.svg";
// import RemoteSensingIcon from "/assets/Remote Sensing.svg";
import CompareIcon from "../../assets/Compare.svg";
// import PicturesIcon from "/assets/pictures icon.svg";
import { useLocation, useNavigate } from "react-router-dom";
import QueryIcon from "../../assets/query.png";
import DownloadIcon from "../../assets/download.png";
import farmerDirectory from "../../assets/farmer_directory.png";
import farmDirectory from "../../assets/farms.png";
import iotIcon from "../../assets/iot.png";
import agroIcon from "../../assets/agro.png";
import { CSVLink } from "react-csv";
import sensor from "../../assets/sensor.png";
import irrigation from "../../assets/irrigation.png";
import ActivateWeatherStation from "../ActivateWeatherStation/ActivateWeatherStation";
const Navbar = ({ expand, enterHandler, leaveHandler, compareRoute }) => {
  const headers = [
    { label: "id", key: "id" },
    { label: "farmId", key: "farmId" },
    { label: "NDVI", key: "NDVI" },
    { label: "avgTemp", key: "avgTemp" },
    { label: "avgRelHum", key: "avgRelHum" },
    { label: "avgSoilTemp", key: "avgSoilTemp" },
    { label: "avgRain", key: "avgRain" },
    { label: "disease", key: "disease" },
    { label: "tempMin", key: "tempMin" },
    { label: "tempMax", key: "tempMax" },
    { label: "relHumMin", key: "relHumMin" },
    { label: "relHumMax", key: "relHumMax" },
    { label: "soilTempMin", key: "soilTempMin" },
    { label: "soilTempMax", key: "soilTempMax" },
    { label: "rainFallMin", key: "rainFallMin" },
    { label: "rainFallMax", key: "rainFallMax" },
    { label: "alert", key: "alert" },
    { label: "createdAt", key: "createdAt" },
    { label: "updatedAt", key: "updatedAt" },
    { label: "avg_NDVI", key: "avg_NDVI" },
    { label: "min_NDVI", key: "min_NDVI" },
    { label: "max_NDVI", key: "max_NDVI" },
    { label: "total_pixel", key: "total_pixel" },
    { label: "pixel_above_threshold", key: "pixel_above_threshold" },
    { label: "pixel_below_threshold", key: "pixel_below_threshold" },
    { label: "pixel_between_threshold", key: "pixel_between_threshold" },
    { label: "date_of_NDVI_data", key: "date_of_NDVI_data" },
  ];
  const { state } = useContext(UserContext);
  const authToken = state.authToken;
  const [csvLinkData, setCsvLinkData] = useState("");
  const [RTWS, setRTWS] = useState([]);
  const options = [
    { text: "IoT Control", icon: iotIcon, route: "/iot", pages: ["/overview","/farmer_directory","/rtws","/device_directory","/average_sensor_data","/average_sensor_data_new","/irrigation_notifications"] },
    {
      text: "Agro Control",
      icon: agroIcon,
      route: "/agro",
      pages: ["/overview","/farmer_directory","/rtws","/device_directory","/average_sensor_data","/average_sensor_data_new","/irrigation_notifications","/remote_sensing"],
    },
    {
      text: "Overview",
      icon: OverviewIcon,
      route: "/overview",
      pages: ["/iot", "/agro","/farmer_directory","/rtws",,"/device_directory","/average_sensor_data","/average_sensor_data_new","/irrigation_notifications","/remote_sensing"],
    },
    {
      text: "Compare",
      icon: CompareIcon,
      route: compareRoute ?? "/compare",
      pages: ["/iot", "/agro","/farmer_directory","/rtws","/device_directory","/average_sensor_data","/average_sensor_data_new","/irrigation_notifications"],
    },
    {
      text: "Query",
      icon: QueryIcon,
      route: "/query",
      pages: ["/iot", "/agro","/farmer_directory","/rtws","/device_directory","/average_sensor_data","/average_sensor_data_new","/irrigation_notifications"],
    },
    {
      text: "RTWS Sheet",
      icon: DownloadIcon,
      route: "/rtws",
      pages: ["/agro","/device_directory","/farmer_directory","/rtws","/device_directory","/average_sensor_data","/average_sensor_data_new","/irrigation_notifications"],
    },
    {
      text: "Farmer Directory",
      icon: farmerDirectory,
      route: "/farmer_directory",
      pages: ["/overview","/rtws","/agro","/device_directory","/farmer_directory","/rtws","/device_directory","/average_sensor_data","/average_sensor_data_new","/irrigation_notifications","/remote_sensing"],
    },
    {
      text: "Device Directory",
      icon: farmDirectory,
      route: "/device_directory",
      pages: ["/iot","/agro","/farmer_directory","/rtws","/device_directory","/average_sensor_data","/average_sensor_data_new","/irrigation_notifications","/remote_sensing"],
    },
    {
      text: "Average Sensor Data",
      icon: sensor,
      route: "/average_sensor_data",
      pages: ["/farmer_directory","/rtws","/device_directory","/average_sensor_data","/average_sensor_data_new","/irrigation_notifications"],
    },
    {
      text: "Average Sensor Data New",
      icon: sensor,
      route: "/average_sensor_data_new",
      pages: ["/agro","/farmer_directory","/rtws","/device_directory","/average_sensor_data","/average_sensor_data_new","/irrigation_notifications","/remote_sensing"],
    },
    {
      text: "Irrigation Notifications",
      icon: irrigation,
      route: "/irrigation_notifications",
      pages: ["/agro","/farmer_directory","/rtws","/device_directory","/average_sensor_data","/average_sensor_data_new","/irrigation_notifications"],
    },
    {
      text: "Register Weather Station",
      icon: sensor,
      route: "register",
      pages: ["/iot"],
    },
    {
      text: "Remote Sensing",
      icon: sensor,
      route: "/remote_sensing",
      pages: ["/agro"],
    }
  ];
  const navigate = useNavigate();
  let location = useLocation();

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios
        .get("https://fmserver.escorts.co.in/admin/agrodata", {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((response) => {
          console.log(response.data.data);
          setRTWS(response.data.data);
          console.log(RTWS);
        })
        .catch((error) => {
          console.log(error);
        });
    };
    fetchData();
  }, []);
  useEffect(() => {
    // const timestamps = iot?.readings?.rain_guage_readings?.map(item => item?.timestamp)
    // console.log(timestamps)
    console.log(RTWS.length);
    const data = [];
    for (let i = 0; i < RTWS.length; i++) {
      data.push({
        // timestamp: timestamps[i],
        id: RTWS?.[i]?.id,
        farmId: RTWS?.[i]?.farmId,
        NDVI: RTWS?.[i]?.NDVI,
        avgTemp: RTWS?.[i]?.avgTemp,
        avgRelHum: RTWS?.[i]?.avgRelHum,
        avgSoilTemp: RTWS?.[i]?.avgSoilTemp,
        avgRain: RTWS?.[i]?.avgRain,
        disease: RTWS?.[i]?.disease,
        tempMin: RTWS?.[i]?.tempMin,
        tempMax: RTWS?.[i]?.tempMax,
        relHumMin: RTWS?.[i]?.relHumMin,
        relHumMax: RTWS?.[i]?.relHumMax,
        soilTempMin: RTWS?.[i]?.soilTempMin,
        soilTempMax: RTWS?.[i]?.soilTempMax,
        rainFallMin: RTWS?.[i]?.rainFallMin,
        rainFallMax: RTWS?.[i]?.rainFallMax,
        alert: RTWS?.[i]?.alert,
        createdAt: RTWS?.[i]?.createdAt,
        updatedAt: RTWS?.[i]?.updatedAt,
        avg_NDVI: RTWS?.[i]?.avg_NDVI,
        min_NDVI: RTWS?.[i]?.min_NDVI,
        max_NDVI: RTWS?.[i]?.max_NDVI,
        total_pixel: RTWS?.[i]?.total_pixel,
        pixel_above_threshold: RTWS?.[i]?.pixel_above_threshold,
        pixel_below_threshold: RTWS?.[i]?.pixel_below_threshold,
        pixel_between_threshold: RTWS?.[i]?.pixel_between_threshold,
        date_of_NDVI_data: RTWS?.[i]?.date_of_NDVI_data,
      });
    }
    // console.log(data)
    // setCsvData(data)
    setCsvLinkData({ filename: "RTWS sheet", headers: headers, data: data });
    // console.log({filename: 'file.csv', headers: headers, data: data})
  }, [RTWS]);
  return (
    <Card
      enterHandler={enterHandler}
      leaveHandler={leaveHandler}
      otherClass="navbar-card"
    >
      {options.map((option, index) => {
        console.log("All",option.pages)
        if (option.pages.find(page => page === location.pathname))
        {
         if(option.route=="register")
         return (
          <div
            key={`nav-option-${index}`}
            className="nav-option"
            onClick={(e) => {
              ActivateWeatherStation(authToken);
            }}
          >
            {option?.icon && !expand && (
              <img className="nav-icon" src={option.icon} alt="icon" />
            )}
            {expand && <span className="nav-option-text">{option.text}</span>}
          </div>
        );
        else
          return (
            <div
              key={`nav-option-${index}`}
              className="nav-option"
              onClick={(e) => {
                option?.route && navigate(option.route);
              }}
            >
              {option?.icon && !expand && (
                <img className="nav-icon" src={option.icon} alt="icon" />
              )}
              {expand && <span className="nav-option-text">{option.text}</span>}
            </div>
          );
              }
      })}
    </Card>
  );
};

export default Navbar;
