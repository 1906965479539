export const INITIAL_STATE = {
    loading: false,
    error: false,
    statsData: {
        totalFarmers: null,
        totalActive: null,
        totalInactive: null,
        totalAcerage: null,
    },
    cropsList: [],
    states: [],
    districts: [],
    currentDistrict: '',
    currentState: '',
    distLoading: false,
    distError: false,
    distStatsData: {
        totalFarmers: null,
        totalActive: null,
        totalInactive: null,
        totalAcerage: null,
    },
    distCropsList: [],
}

export const overviewReducer = (state, action) => {
    switch (action.type) {
        case "FETCH_START":
            return { ...state, loading: true, error: false }
        case "FETCH_SUCCESS":
            return {
                ...state, loading: false, error: false,
                statsData: {
                    totalFarmers: action.payload.farmers,
                    totalActive: action.payload.active, totalInactive: action.payload.inactive,
                    totalAcerage: action.payload.area.total_area
                },
                cropsList: action.payload.cropFarmMappings,
                states: action.payload.states,
            }
        case "FETCH_ERROR":
            return { ...state, loading: false, error: true }
        case "DIST_FETCH_START":
            return { ...state, distLoading: true, distError: false, currentDistrict: '' }
        case "DIST_ERROR":
            return { ...state, distLoading: false, distError: true }
        case "DIST_SUCCESS":
            return { ...state, distLoading: false, distError: false, districts: action.payload.states }
        case "SET_STATE":
            return {...state, currentState: action.payload }
        case "SET_DIST": 
            return {...state, currentDistrict: action.payload.district, 
                    distStatsData: {
                        totalFarmers: action.payload.farmers, totalInactive: action.payload.inactive,
                    totalActive: action.payload.active, totalAcerage: action.payload.area.total_area},
                    distCropsList: action.payload.cropFarmMappings
            }
        default:
            return state
    }
}