import axios from "axios"
import { useRef, useContext, useEffect, useState } from "react"
import Card from "../../components/card/Card"
import "./login.css"
import EscortsLogo from '../../assets/Escort_kubota_limited.png'
import { useNavigate } from "react-router-dom"
import { UserContext } from "../../context/UserContext"
import { CircularProgress } from "@mui/material"


const Login = () => {
    const [isFetching, setIsFetching] = useState(false);
    // const dept = useRef() // dept. = Department
    const email = useRef()
    const password = useRef()
    console.log(email,password);
    const navigate = useNavigate()
    const { state, dispatch } = useContext(UserContext)
    useEffect(() => {
        console.log("i am running");
        if (state.authToken) {
            console.log(state)
            alert("Already logged in")
            navigate("/overview")
        }
        // if (iotToken)
        //     navigate("/iot")
        // else if (agroToken)
        //     navigate("/agro")
    }, [])


    const submitHandler = async (e) => {
        e.preventDefault();
        setIsFetching(true);
        try {
            const emailValue = email.current.value.toLowerCase(); // Convert email to lowercase
            const res = await axios.post("https://fmserver.escorts.co.in/admin/authenticate/login", { email: emailValue, password: password.current.value })
            if (res.data?.success === true) {
                // if (dept.current.value === 'iot') {
                // }
                // else if (dept.current.value === 'agro') {
                //     sessionStorage.setItem("agroToken", res.data.token)
                //     dispatch({ type: 'STORE_AGRO_TOKEN', payload: res.data.token })
                //     setIsFetching(false)
                //     navigate("/agro")  // -> Implement it later

                // }
                console.log("signedin")
                localStorage.setItem("authToken", res.data.token)
                localStorage.setItem("department", res.data.department)
                dispatch({ type: 'STORE_AUTH_TOKEN', payload: {authToken:res.data.token,department:res.data.department} })
                // dispatch({ type: 'STORE_DEPARTMENT', payload: res.data.department })
                navigate("/overview")
            }
            else {
                setIsFetching(false)
                alert('Login failed')
            }
        }
        catch (err) {
            alert(err?.message)
        }

    }
    const gotoSignup = () => {
        navigate("/signup")
    }
    return (
        <div>
            <div className='escorts-logo'>
                <img src={EscortsLogo} alt='logo' />
                <h2>Login to Escorts</h2>
            </div>
            <div className="login-form-container">
                <Card>
                    <form onSubmit={submitHandler} className="login-form">
                        {/* <select defaultValue='' ref={dept} className="department-select" required>
                            <option disabled value=''>Select department</option>
                            <option value='agro'>Agro</option>
                            <option value='iot'>IOT</option>
                        </select> */}
                        <input ref={email} type='email' placeholder="Email" required />
                        <input ref={password} type='password' placeholder="Password" required />
                        <button type="submit">{isFetching ? <CircularProgress /> : 'Login'}</button>
                    </form>
                    <div className="goto-signUp-container">
                        <span className="goto-comment">Don't have account?</span>
                        <button onClick={gotoSignup} className="goto-signUp-btn">Sign Up</button>
                    </div>
                </Card>
            </div>
        </div>
    )
}

export default Login