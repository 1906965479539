import React, { useEffect, useState ,useRef} from 'react'
import "./mapComponent.css"
import "leaflet/dist/leaflet.css"
import { MapContainer, TileLayer, Marker, useMap, Popup, LayersControl, ImageOverlay,Polygon, GeoJSON } from 'react-leaflet'
import markerLink from "leaflet/dist/images/marker-icon.png"
import L from 'leaflet'
import axios from 'axios'
import  Histogram  from '../Chart/Histogram.tsx'
import { useLocation } from 'react-router-dom'
import Compass from '../Compass/Compass'
import { useMapEvents } from 'react-leaflet/hooks'
import {ndvi_data} from "./ndvi.js";

const MyIcon = L.icon({
    iconUrl: markerLink,
})


function ChangeView({ center, zoom }) {
    const map = useMap();
    map.setView(center, zoom);
    return null;
}
const MapComponent = ({ mapCenter, setMapCenter, locationsArray, currentFarm,iot }) => {
    const location = useLocation();
    console.log(location.pathname)
    console.log(mapCenter)
    console.log(locationsArray)
    console.log(currentFarm)

    const [centerMap, setCenterMap] = useState(mapCenter)
    console.log(centerMap)
    const [bounds, setBounds] = useState([])
    const [NDVI, setNDVI] = useState('')
    // const [IOT, setIot] = useState(iot);
    const [NDMI, setNDMI] = useState('')

    const fetchCoordinateDetails = async () => {
        const res = await axios.get(`https://tonwv78p3g.execute-api.us-west-2.amazonaws.com/test1/metadata?farmID=${currentFarm?.id}&farmName=${currentFarm?.farm_name}`)
        setBounds(res?.data?.imageoverlaycoords)
        const data = res?.data?.fieldboundary
        const regex = /\d+\.\d+/g; // matches all numbers in the string
        const numbers = data.match(regex).map(Number); // extracts and converts numbers to numbers

        const arrays = [];
        for (let i = 0; i < numbers.length; i += 2) {
            arrays.push([numbers[i + 1], numbers[i]]); // reverse the numbers within each subarray
        }

        console.log(arrays);
        setBounds(arrays);
        // setMapCenter(res?.data?.imageoverlaycoords[0])
        // setMapCenter([res?.data?.imageoverlaycoords[0][1],res?.data?.imageoverlaycoords[0][0]])
        setCenterMap([res?.data?.imageoverlaycoords[0][1], res?.data?.imageoverlaycoords[0][0]]) // we have added the 0.0023 & 0.001 value just to get the bounds within the limit of mao container view, its just a hit and trail value and its done for temporay purpose, there has to be a good way to do it.
        console.log([res?.data?.imageoverlaycoords[0][1], res?.data?.imageoverlaycoords[0][0]])
        console.log(res.data)
    }
    console.log(bounds)
     // const boundses = new LatLngBounds(bounds)
     let yourDate = new Date();
     yourDate=yourDate.toISOString().split('T')[0]
     function formatDate(yourDate) {
       var d = new Date(yourDate),
           month = '' + (d.getMonth() + 1),
           day = '' + d.getDate(),
           year = d.getFullYear();
 
       if (month.length < 2) 
           month = '0' + month;
       if (day.length < 2) 
           day = '0' + day;
 
       return [year, month, day].join('-');
     }
     const fDate=formatDate(yourDate);
     console.log(fDate)
     const [index,setIndex]=useState("")
     // const [date,setDate] =useState("");
     const [date, setDate] = useState(fDate);
     const [coordinates, setCoordinates] = useState([]);
    const fetchImageOverlay = async () => {
      // axios.get(
      //   `https://4cs5gari41.execute-api.us-west-2.amazonaws.com/v1/realtime?farmID=236&farmName=Tajinder Singh cotton bighar&date=2023-10-03`,
      //   {
      //     headers: {
      //       'Content-Type': 'application/json',
      //       'Access-Control-Allow-Origin': '*',
      //     },
      //   }
      // )
      //   .then(response => {
      //     console.log(response);
      //     setCoordinates(response.ndvi);
      //   })
      //   .catch(error => {
      //     // Handle any errors here
      //     console.log("ERROR");
      //     console.error('Error fetching the image:', error);
      //   });
        // const res = await axios.get(`https://tonwv78p3g.execute-api.us-west-2.amazonaws.com/test1/getdata?farmID=${currentFarm.id}&farmName=${currentFarm.farm_name}&index=${'NDMI'}&date=${date}&zoom=${20}`,
        //     {
        //         responseType: 'blob'
        //     })
            
        // const imageBlob = new Blob([res.data], { type: 'image/png' })
        // setNDMI(URL.createObjectURL(imageBlob))
        // console.log(URL.createObjectURL(imageBlob))
        // const res2 = await axios.get(`https://tonwv78p3g.execute-api.us-west-2.amazonaws.com/test1/getdata?farmID=${currentFarm?.id}&farmName=${currentFarm?.farm_name}&index=${'NDVI'}&date=${date}&zoom=${20}`,
        //     {
        //         responseType: 'arraybuffer'
        //     })
        // const imageBlob2 = new Blob([res2.data], { type: 'image/png' })
        // setNDVI(URL.createObjectURL(imageBlob2))
        // console.log(URL.createObjectURL(imageBlob2))

        axios.get(`https://tonwv78p3g.execute-api.us-west-2.amazonaws.com/test1/getdata?farmID=${currentFarm.id}&farmName=${currentFarm.farm_name}&index=${'NDMI'}&date=${date}&zoom=${20}`, { responseType: 'blob' })  // 'blob' because both PNG and SVG can be treated as binary data
  .then(response => {
    // Assuming 'response' is the response object from axios
    const contentType = response.headers['content-type'];
    console.log(contentType);
    if (contentType === 'image/png') {
      // Process PNG image
      const imageBlob = new Blob([response.data], { type: 'image/png' });
      setNDMI(URL.createObjectURL(imageBlob))
        console.log(URL.createObjectURL(imageBlob))
      // Do something with the PNG URL, like set it to an image src
    } else if (contentType === 'image/svg+xml') {
      // Process SVG image
      const svgBlob = new Blob([response.data], { type: 'image/svg+xml' });
      setNDMI(URL.createObjectURL(svgBlob))
      console.log(URL.createObjectURL(svgBlob))
      // Do something with the SVG URL
    } else {
      // Handle other content types or throw an error
      console.error('Unsupported content type:', contentType);
    }
  })
  .catch(error => {
    // Handle any errors here
    console.error('Error fetching the image:', error);
  });

  axios.get(`https://tonwv78p3g.execute-api.us-west-2.amazonaws.com/test1/getdata?farmID=${currentFarm.id}&farmName=${currentFarm.farm_name}&index=${'NDVI'}&date=${date}&zoom=${20}`, { responseType: 'blob' })  // 'blob' because both PNG and SVG can be treated as binary data
  .then(response => {
    // Assuming 'response' is the response object from axios
    const contentType = response.headers['content-type'];
    
    if (contentType === 'image/png') {
      // Process PNG image
      const imageBlob = new Blob([response.data], { type: 'image/png' });
      setNDVI(URL.createObjectURL(imageBlob))
        console.log(URL.createObjectURL(imageBlob))
      // Do something with the PNG URL, like set it to an image src
    } else if (contentType === 'image/svg+xml') {
      // Process SVG image
      const svgBlob = new Blob([response.data], { type: 'image/svg+xml' });
      setNDVI(URL.createObjectURL(svgBlob))
      console.log(URL.createObjectURL(svgBlob))
      // Do something with the SVG URL
    } else {
      // Handle other content types or throw an error
      console.error('Unsupported content type:', contentType);
    }
  })
  .catch(error => {
    // Handle any errors here
    console.error('Error fetching the image:', error);
  });
    }
    useEffect(() => {
        if (currentFarm?.id) {
            fetchCoordinateDetails()
            fetchImageOverlay()
            setIndex(""); // whenever the new location of farm is selected, the historam should dissappear and it should wait for the again selection of NDMI and NDVI values
        }
    }, [currentFarm])
    useEffect(() => {
        if (currentFarm?.id) {
            fetchCoordinateDetails()
            fetchImageOverlay()
        }
    }, [date])

    var greenIcon = L.icon({
        iconUrl:    markerLink,
        iconSize:     [30, 40], // size of the icon
        iconAnchor:   [15, 40], // point of the icon which will correspond to marker's location. [x,y] x=iconSize[0]/2;        popupAnchor:  [-3, -76] // point from which the popup should open relative to the iconAnchor
    });
    // console.log(typeof(iot?.readings?.wind_direction_readings?.at(-1)?.reading));
    console.log(iot);


    const [hoveredMarker, setHoveredMarker] = useState(null);

    const handleMouseOver = (index) => {
      console.log("INDEX",index);
      setHoveredMarker(index);
    };
  
    const handleMouseOut = () => {
      setHoveredMarker(null);
    };

    console.log(ndvi_data.ndvi)
    return (
      
        <div className={`map__component_${currentFarm?"addedClass":""}`}>
            {(location.pathname==="/agro"&&currentFarm)?<input onChange={event => setDate(event.target.value)} value={date} type="date" />:<></>}  
            {/* condition is added just to make sure date selection option must not appear on overview page, if map is selected i.e IOT and AGRO page the only date option must appear. */}
            {centerMap ?? mapCenter ?
                <MapContainer key={centerMap?.toString()} center={centerMap ?? mapCenter} zoom={5} scrollWheelZoom={false}>
                    <ChangeView center={centerMap ?? mapCenter} zoom={currentFarm?.id ? 20 : 5} />
                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                    />
                            

                    {locationsArray ?
                        locationsArray?.map((locObj, index) => {
                            return (
                                <Marker key={index + locObj} position={locObj?.geocode} icon={greenIcon}  >
                                    <Popup>
                                        {locObj?.popup}
                                    </Popup>
                                </Marker>
                            )
                        })
                        :
                        <></>
                    }
                    { typeof(iot?.readings?.wind_direction_readings?.at(-1)?.reading) == "string"?<Compass iot={iot}/>:<></>}
                    <Polygon pathOptions={{color:"yellow",fill:false}} positions={bounds} />
                    {(location.pathname==="/agro")?<LayersControl >
                        <LayersControl.Overlay name="NDMI">
                            <ImageOverlay
                            eventHandlers={{
                                add: (e) => {
                                    if(index==="" || index==="NDMI")
                                    setIndex("NDMI")
                                    else
                                    setIndex("both")
                                }
                                ,
                                remove: (e) => {
                                  if(index==="both")
                                  setIndex("NDVI")
                                  else
                                  setIndex("")
                                }
                              }}
                                url={NDMI}
                                bounds={bounds}
                                zIndex={10}
                            />
                        </LayersControl.Overlay>
                        <LayersControl.Overlay name="NDVI">
                            <ImageOverlay
                            eventHandlers={{
                                add: (e) => {
                                    if(index==="" || index==="NDVI")
                                    setIndex("NDVI")
                                    else
                                    setIndex("both")
                                }
                                ,
                                remove: (e) => {
                                    if(index==="both")
                                    setIndex("NDMI")
                                    else
                                    setIndex("")
                                }
                              }}
                                url={NDVI}
                                bounds={bounds}
                                zIndex={10}
                            />
                        </LayersControl.Overlay>
                    </LayersControl>:""}
                </MapContainer>
                :
                <></>
            }
            {
            (location.pathname=="/agro"&&(index=="NDVI"|| index=="NDMI" || index=="both"))?
            <Histogram className="histogram" farmID={currentFarm?.id} farmName={currentFarm?.farm_name} index={index} date={date} />:<></>
            }
        </div>
    )
}

export default MapComponent;