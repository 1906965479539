import "./overviewDataContent.css"

const OverviewDataContent = ({ crops, active, farmers, inactive, area, }) => {
    return (
        <div className="overview__left_content">
            <div className="overview__left_content__stats">
                <div className="left_content__stats_item">
                    <div className="stats_item_key">Total No. of Farmers</div>
                    <div className="stats_item_value">{farmers || 'NA'}</div>
                </div>
                <div className="left_content__stats_item">
                    <div className="stats_item_key">Total No. of Active WS</div>
                    <div className="stats_item_value">{active || 'NA'}</div>
                </div>
                <div className="left_content__stats_item">
                    <div className="stats_item_key">Total No. of inactive WS</div>
                    <div className="stats_item_value">{inactive || 'NA'}</div>
                </div>
                <div className="left_content__stats_item">
                    <div className="stats_item_key">Total Acreage</div>
                    <div className="stats_item_value">{area?.toFixed(2) || 'NA'}</div>
                </div>
            </div>
            <div>
                <div className="overview__left_content__table">
                    <div className="left_content__table_head">
                        <span className="left_content__table_head_key">
                            Name of Crop
                        </span>
                        <span
                            style={{
                                borderLeft: "1px solid",
                                borderRight: "1px solid",
                            }}
                            className="left_content__table_head_key"
                        >
                            No of Farms
                        </span>
                        <span className="left_content__table_head_key">
                            Total Area <span className="table_item_unit"> (acre)</span>
                        </span>
                    </div>
                    {crops.map((crop, index) => (
                        <div
                            key={crop.current_crop + index}
                            className="left_content__table_item"
                        >
                            <span className="left_content__table_item_key">
                                {crop.current_crop}
                            </span>
                            <span
                                style={{
                                    borderLeft: "1px solid",
                                    borderRight: "1px solid",
                                }}
                                className="left_content__table_item_key"
                            >
                                {crop.total_farms}
                            </span>
                            <span className="left_content__table_item_key">
                                {crop.total_area?.toFixed(2)}
                            </span>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default OverviewDataContent