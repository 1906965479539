import "./signUp.css";
import EscortsLogo from "../../assets/Escort_kubota_limited.png";
import Card from "../../components/card/Card";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const SignUp = () => {
  const dept = useRef();
  const username = useRef();
  const empId = useRef();
  const email = useRef();
  const password = useRef();
  const cnfPassword = useRef();
  const navigate = useNavigate();

  const submitHandler = async (e) => {
    e.preventDefault();
    if (password.current.value === cnfPassword.current.value)
      try {
        const res = await axios.post(
          "https://fmserver.escorts.co.in/admin/authenticate/register",
          {
            email: email.current.value,
            eid: empId.current.value,
            department: dept.current.value,
            name: username.current.value,
            password: password.current.value,
          }
        );
        console.log(res);
        if (res.data?.success === true) {
          navigate("/login");
        } else {
          console.log("Sign Up failed");
        }
      } catch (err) {
        console.log(err);
      }
  };
  const gotoLogin = () => {
    navigate("/login");
  };
  return (
    <div>
      <div className="escorts-logo">
        <img src={EscortsLogo} alt="logo" />
        <h2>Sign Up</h2>
      </div>
      <div className="login-form-container">
        <Card>
          <form onSubmit={submitHandler} className="login-form">
            <select
              defaultValue=""
              ref={dept}
              className="department-select"
              required
            >
              <option disabled value="">
                Select department
              </option>
              <option value="AGRO">Agro</option>
              <option value="IOT">IOT</option>
            </select>
            <input ref={username} type="text" placeholder="Username" required />
            <input
              ref={empId}
              type="number"
              placeholder="Employee Id"
              required
            />
            <input ref={email} type="email" placeholder="Email" required />
            <input
              ref={password}
              type="password"
              placeholder="Password"
              required
            />
            <input
              ref={cnfPassword}
              type="password"
              placeholder="Confirm Password"
              required
            />
            <button type="submit">Sign Up</button>
          </form>
          <div className="goto-signUp-container">
            <span className="goto-comment">Already have account?</span>
            <button onClick={gotoLogin} className="goto-signUp-btn">
              Log In
            </button>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default SignUp;
