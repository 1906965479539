import React, { useState, useEffect } from 'react'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import axios from 'axios';
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);
const options = {
    responsive: true,
    interaction: {
        mode: 'index' as const,
        intersect: false,
    },
    maintainAspectRatio: false,
    tension: 0.4,
    stacked: true,
    plugins: {
        legend: {
            position: 'bottom' as const,
            labels: {
                font: {
                    size: 10,
                },
            },
        },
        title: {
            display: true,
            text: 'Multi Axis Line Chart',
        },
    },
    scales: {
        y: {
            type: 'linear' as const,
            display: true,
            position: 'left' as const,
        },
        y1: {
            type: 'linear' as const,
            display: true,
            position: 'right' as const,
            // grid: {
            //   drawOnChartArea: false,
            // },
        },
    },
};
const fieldNames = {
    first: [
        { api_name: 'atmospheric_tempratrature_readings', label_name: 'Air Temperature', borderColor: 'rgba(243, 58, 29, 0.4)', backgroundColor: 'rgba(243, 58, 29, 0.3)' },
        { api_name: 'battery_voltage_readings', label_name: 'Battery Voltage', borderColor: 'rgba(1, 163, 170, 0.4)', backgroundColor: 'rgba(1, 163, 170, 0.3)' },
        { api_name: 'atmospheric_pressure_redings', label_name: 'Air Pressure', borderColor: 'rgba(30, 179, 55, 0.4)', backgroundColor: 'rgba(30, 179, 55, 0.3)' },
        { api_name: 'atmospheric_humidity_readings', label_name: 'Air Humidity', borderColor: 'rgba(202, 76, 207, 0.4)', backgroundColor: 'rgba(202, 76, 207, 0.3)' },
        { api_name: 'leaf_wetness_readings', label_name: 'Leaf Wetness', borderColor: 'rgba(222, 217, 89, 0.4)', backgroundColor: 'rgba(222, 217, 89, 0.3)' },
        { api_name: 'canopy_temparture_readings', label_name: 'Canopy Temperature', borderColor: 'rgba(156, 155, 152, 0.4)', backgroundColor: 'rgba(156, 155, 152, 0.3)' },
        { api_name: 'soil_moisture_reading_1', label_name: 'Soil Moisture_1', borderColor: 'rgba(243, 141, 46, 0.4)', backgroundColor: 'rgba(243, 141, 46, 0.3)' },
        { api_name: 'rain_guage_readings', label_name: 'Precipitation', borderColor: 'rgba(53, 122, 212, 0.4)', backgroundColor: 'rgba(53, 122, 212, 0.3)' },
    ],
    second: [
        { api_name: 'atmospheric_tempratrature_readings', label_name: 'Air Temperature 02', borderColor: 'rgba(243, 58, 29,0.7)', backgroundColor: 'rgba(243, 58, 29,0.8)' },
        { api_name: 'battery_voltage_readings', label_name: 'Battery Voltage 02', borderColor: 'rgba(1, 163, 170, 0.7)', backgroundColor: 'rgba(1, 163, 170, 0.8)' },
        { api_name: 'atmospheric_pressure_redings', label_name: 'Air Pressure 02', borderColor: 'rgba(30, 179, 55,0.7)', backgroundColor: 'rgba(30, 179, 55,0.8)' },
        { api_name: 'atmospheric_humidity_readings', label_name: 'Air Humidity 02', borderColor: 'rgba(202, 76, 207,0.7)', backgroundColor: 'rgba(202, 76, 207,0.8)' },
        { api_name: 'leaf_wetness_readings', label_name: 'Leaf Wetness 02', borderColor: 'rgba(222, 217, 89,0.7)', backgroundColor: 'rgba(222, 217, 89,0.8)' },
        { api_name: 'canopy_temparture_readings', label_name: 'Canopy Temperature 02', borderColor: 'rgba(156, 155, 152,0.7)', backgroundColor: 'rgba(156, 155, 152,0.8)' },
        { api_name: 'soil_moisture_reading_1', label_name: 'Soil Moisture_1 02', borderColor: 'rgba(243, 141, 46,0.7)', backgroundColor: 'rgba(243, 141, 46,0.8)' },
        { api_name: 'rain_guage_readings', label_name: 'Precipitation 02', borderColor: 'rgba(53, 122, 212,0.7)', backgroundColor: 'rgba(53, 122, 212,0.8)' },

    ]
}
const CombinedMultichart = ({ iot1, iot2, farmId1, farmId2 }) => {
    ChartJS.defaults.font.size = 10;
    const iot = iot1;
    const initailLabels = iot?.readings?.atmospheric_humidity_readings?.map(
        (element) => element.timestamp.toString().split(', ')[1]
    );
    const [multiChartLabels, setMultiChartLabels] = useState(initailLabels?.filter((label: any, index: number) => (index % 4) === 0))

    const iotDataSet = [
        {
            label: "Air Temperature",
            data: iot1?.readings?.atmospheric_tempratrature_readings?.map(
                (data) => data.reading
            ).filter((item, index) => ((index % 4) === 0)),
            borderColor: "rgba(243, 58, 29, 0.4)",
            backgroundColor: "rgba(243, 58, 29,0.3)",
        },
        {
            label: "Battery Voltage",
            data: iot1?.readings?.battery_voltage_readings?.map(
                (data) => data.reading
            ).filter((item, index) => ((index % 4) === 0)),
            borderColor: "rgba(1, 163, 170, 0.4)",
            backgroundColor: "rgba(1, 163, 170, 0.3)",
        },
        {
            label: "Air Pressure",
            data: iot1?.readings?.atmospheric_pressure_redings?.map(
                (data) => data.reading
            ).filter((item, index) => ((index % 4) === 0)),
            borderColor: "rgba(30, 179, 55, 0.4)",
            backgroundColor: "rgba(30, 179, 55,0.3)",
        },
        {
            label: "Air Humidity",
            data: iot1?.readings?.atmospheric_humidity_readings?.map(
                (data) => data.reading
            ).filter((item, index) => ((index % 4) === 0)),
            borderColor: "rgba(202, 76, 207, 0.4)",
            backgroundColor: "rgba(202, 76, 207,0.3)",
        },
        {
            label: "Leaf Wetness",
            data: iot1?.readings?.leaf_wetness_readings?.map((data) => data.reading.leaf_temp).filter((item, index) => ((index % 4) === 0)),
            borderColor: "rgba(222, 217, 89, 0.4)",
            backgroundColor: "rgba(222, 217, 89,0.3)",
        },
        // {
        //   label: "Canopy Temperature",
        //   data: iot1?.readings?.canopy_temparture_readings?.map(
        //     (data) => data.reading
        //   ).filter((item, index) => ((index % 4) === 0)),
        //   borderColor: "rgb(156, 155, 152)",
        //   backgroundColor: "rgba(156, 155, 152,0.5)",
        // },
        {
            label: "Soil Moisture_1",
            data: iot1?.readings?.soil_moisture_reading_1?.map((data) => data.reading).filter((item, index) => ((index % 4) === 0)),
            borderColor: "rgba(243, 141, 46, 0.4)",
            backgroundColor: "rgba(243, 141, 46,0.3)",
        },
        {
            label: "Precipitation",
            data: iot1?.readings?.rain_guage_readings?.map((data) => data.reading).filter((item, index) => ((index % 4) === 0)),
            borderColor: "rgba(53, 122, 212,0.4)",
            backgroundColor: "rgba(53, 122, 212,0.3)",
        },
        {
            label: "Air Temperature 02",
            data: iot2?.readings?.atmospheric_tempratrature_readings?.map(
                (data) => data.reading
            ).filter((item, index) => ((index % 4) === 0)),
            borderColor: "rgb(243, 58, 50)",
            backgroundColor: "rgba(243, 58, 29,0.8)",
        },
        {
            label: "Battery Voltage 02",
            data: iot2?.readings?.battery_voltage_readings?.map(
                (data) => data.reading
            ).filter((item, index) => ((index % 4) === 0)),
            borderColor: "rgb(1, 163, 170)",
            backgroundColor: "rgba(1, 163, 170, 0.8)",
        },
        {
            label: "Air Pressure 02",
            data: iot2?.readings?.atmospheric_pressure_redings?.map(
                (data) => data.reading
            ).filter((item, index) => ((index % 4) === 0)),
            borderColor: "rgb(30, 179, 55)",
            backgroundColor: "rgba(30, 179, 55,0.8)",
        },
        {
            label: "Air Humidity 02",
            data: iot2?.readings?.atmospheric_humidity_readings?.map(
                (data) => data.reading
            ).filter((item, index) => ((index % 4) === 0)),
            borderColor: "rgb(202, 76, 207)",
            backgroundColor: "rgba(202, 76, 207,0.8)",
        },
        {
            label: "Leaf Wetness 02",
            data: iot?.readings?.leaf_wetness_readings?.map((data) => data.reading.leaf_temp).filter((item, index) => ((index % 4) === 0)),
            borderColor: "rgba(222, 217, 89,1)",
            backgroundColor: "rgba(222, 217, 89,0.8)",
        },
        // {
        //   label: "Canopy Temperature 02",
        //   data: [...iot1?.readings?.atmospheric_humidity_readings?.map(
        //     (data) => NaN
        //   )?.filter((item, index) => ((index % 4) === 0)),
        //   ...iot2?.readings?.canopy_temparture_readings?.map(
        //     (data) => data.reading
        //   )?.filter((item, index) => ((index % 4) === 0))],
        //   borderColor: "rgb(156, 155, 152)",
        //   backgroundColor: "rgba(156, 155, 152,0.5)",
        // },
        {
            label: "Soil Moisture_1 02",
            data: iot2?.readings?.soil_moisture_reading_1?.map((data) => data.reading).filter((item, index) => ((index % 4) === 0)),
            borderColor: "rgba(243, 141, 46,1)",
            backgroundColor: "rgba(243, 141, 46,0.8)",
        },
        {
            label: "Precipitation 02",
            data: iot2?.readings?.rain_guage_readings?.map((data) => data.reading).filter((item, index) => ((index % 4) === 0)),
            borderColor: "rgba(53, 122, 212,1)",
            backgroundColor: "rgba(53, 122, 212,0.8)",
        },
    ];
    const [selectedOption1, setSelectedOption1] = useState({ ...iotDataSet[0], yAxisID: 'y' })
    const [selectedOption2, setSelectedOption2] = useState({ ...iotDataSet[2], yAxisID: 'y1' })
    const [data, setData] = useState({
        labels: multiChartLabels,
        datasets: [selectedOption1, selectedOption2]
    })
    console.log(data)
    useEffect(() => {
        setData({ labels: multiChartLabels, datasets: [selectedOption1, selectedOption2] })
    }, [multiChartLabels, selectedOption1, selectedOption2])

    const [isDateEnabled, setIsDateEnabled] = useState(false);
    const [iotDatasetRange, setIotDatasetRange] = useState<{ label: string; data: any; borderColor: string; backgroundColor: string; }[]>([]);

    const leftSelectHandler = (selectedLabel) => {
        console.log(selectedLabel)
        // console.log(selectedIndex)
        if (isDateEnabled) {
            const selectedIndex = iotDatasetRange.findIndex(dataset => dataset?.label === selectedLabel)
            console.log(iotDatasetRange)
            console.log(data)
            console.log(multiChartLabels)
            setSelectedOption1({ ...iotDatasetRange[selectedIndex], yAxisID: 'y' })
        }
        else {
            const selectedIndex = iotDataSet.findIndex(dataset => dataset?.label === selectedLabel)
            setSelectedOption1({ ...iotDataSet[selectedIndex], yAxisID: 'y' })
        }
    }
    const rightSelectHandler = (selectedLabel) => {
        // console.log(selectedIndex)
        if (isDateEnabled) {
            const selectedIndex = iotDatasetRange.findIndex(dataset => dataset?.label === selectedLabel)
            setSelectedOption2({ ...iotDatasetRange[selectedIndex], yAxisID: 'y1' })
        }
        else {
            const selectedIndex = iotDataSet.findIndex(dataset => dataset?.label === selectedLabel)
            setSelectedOption2({ ...iotDataSet[selectedIndex], yAxisID: 'y1' })
        }
    }


    // useEffect(()=>{
    //   setData({
    //       labels: multiChartLabels,
    //       datasets : isDateEnabled ? iotDatasetRange : [selectedOption1, selectedOption2]
    //   })
    //   console.log(iotDatasetRange)
    // },[multiChartLabels, selectedOption1, selectedOption2])

    const fetchAllRanges = async () => {
        setIotDatasetRange([])
        fieldNames.first.forEach(async (item, index) => {
            let res = await axios.get(`https://fmserver.escorts.co.in/admin/farmer/readings?parameter=${item?.api_name}&start_date=${startDate?.current?.value}&end_date=${endDate?.current?.value}&farm_id=${farmId1 ?? 5}`)
            if (index === 0) { console.log("RES FIRST", res) }
            index === 0 && setMultiChartLabels(res?.data?.result?.map(
                (element) => element?.obj?.timestamp.toString().split(', ')[1]
            )?.filter((item, index) => ((index % 8) === 0)))
            setIotDatasetRange(prevArray => [...prevArray, {
                label: item.label_name,
                data: item.label_name === 'Leaf Wetness' ? res?.data?.result?.map(val => val?.obj?.reading.leaf_temp).filter((item, index) => ((index % 8) === 0)) : res?.data?.result?.map(val => val?.obj?.reading).filter((item, index) => ((index % 8) === 0)),
                borderColor: item?.borderColor,
                backgroundColor: item?.backgroundColor
            }])
        })
        fieldNames.second.forEach(async (item, index) => {
            let res = await axios.get(`https://fmserver.escorts.co.in/admin/farmer/readings?parameter=${item?.api_name}&start_date=${startDate?.current?.value}&end_date=${endDate?.current?.value}&farm_id=${farmId2 ?? 5}`)
            if (index === 0) {
                console.log("RES SECOND", res)
            }
            index === 0 && setMultiChartLabels(res?.data?.result?.map(
                (element) => element?.obj?.timestamp.toString().split(', ')[1]
            )?.filter((item, index) => ((index % 8) === 0)))
            setIotDatasetRange(prevArray => [...prevArray, {
                label: item.label_name,
                data: item.label_name === 'Leaf Wetness 02' ? res?.data?.result?.map(val => val?.obj?.reading.leaf_temp).filter((item, index) => ((index % 8) === 0)) : res?.data?.result?.map(val => val?.obj?.reading).filter((item, index) => ((index % 8) === 0)),
                borderColor: item?.borderColor,
                backgroundColor: item?.backgroundColor
            }])
        })
        console.log(iotDatasetRange)
    }
    const startDate = React.useRef<HTMLInputElement>(null)
    const endDate = React.useRef<HTMLInputElement>(null)
    const dateRangeHanlder = (e) => {
        e.preventDefault()
        setIsDateEnabled(true)
        // selectedField.current.value = 'all'
        console.log(startDate.current?.value)
        console.log(endDate.current?.value)
        if (!startDate.current?.value || !endDate.current?.value) {
            alert("Please select both the dates")
        } else {
            const fetchRangeData = async () => {
                await fetchAllRanges()
                console.log(iotDatasetRange)
            }
            fetchRangeData()
        }
    }
    return (
        <>
        <div className='chart__wrapper'>
            <Line options={options} data={data} className='chart_chart'/></div>
            <div className='multichart__options'
                style={{ display: 'flex', justifyContent: 'space-evenly', margin: '1rem 0' }}>
                <select value={selectedOption1.label} onChange={(e) => leftSelectHandler(e.target.value)} className='chart__select'>
                    {isDateEnabled ?
                        iotDatasetRange.filter(dataset => (!dataset?.label.endsWith("02"))).map(dataset => (
                            <option key={dataset?.label} value={dataset?.label}>{dataset.label}</option>
                        ))
                        :
                        iotDataSet.filter(dataset => (!dataset?.label.endsWith("02"))).map(dataset => (
                            <option key={dataset?.label} value={dataset?.label}>{dataset.label}</option>
                        ))
                    }
                </select>
                <select value={selectedOption2?.label} onChange={e => rightSelectHandler(e.target.value)} className='chart__select'>
                    {isDateEnabled ?
                        iotDatasetRange.filter(dataset => (dataset?.label.endsWith("02"))).map(dataset => (
                            <option key={dataset?.label} value={dataset?.label}>{dataset.label}</option>
                        ))
                        :
                        iotDataSet.filter(dataset => (dataset?.label.endsWith("02"))).map(dataset => (
                            <option key={dataset?.label} value={dataset?.label}>{dataset.label}</option>
                        ))
                    }
                </select>
            </div>
            <form className="dateRange__form" style={{ width: 'fit-content', margin: 'auto' }} onSubmit={dateRangeHanlder}>
                <div className="chart__daterange__input">
                    <input ref={startDate} type="date" placeholder="Date From" title="Date from" />
                </div>
                <div className="chart__daterange__input">
                    <input ref={endDate} type="date" placeholder="Date upto" title="Date upto" />
                </div>
                <button type="submit">Submit</button>
            </form>
        </>
    )
}

export default CombinedMultichart