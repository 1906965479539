import React, { useEffect } from 'react'
import { useContext } from 'react'
import { UserContext } from '../../context/UserContext'
import { useNavigate } from 'react-router-dom'
const Home = () => {
    const { authToken } = useContext(UserContext)
    const navigate = useNavigate()
    useEffect(() => {
        authToken ?? navigate("/login")
    }, [])
    return (
        <div>
            Home</div>
        // test comment for dummy commit.
    )
}

export default Home