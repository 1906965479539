import React, { useState, useEffect } from 'react';
import zoomPlugin from 'chartjs-plugin-zoom';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import axios from 'axios';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  zoomPlugin
);
const options = 

{
  responsive: true,
  interaction: {
    mode: 'index' as const,
    intersect: false,
  },
  maintainAspectRatio: false,
  tension: 0.4,
  stacked: true,
  plugins: {
    legend: {
      position: 'bottom' as const,
      labels: {
        font: {
          size: 10,
        },
      },
    },
    title: {
      display: true,
      text: 'Multi Axis Line Chart',
    },
    // zoom: {
    //   zoom: {
    //     // animation: {
    //     //   duration: 1000,
    //     //   easing: 'easeOutCubic'
    //     // },
    //     wheel: {
    //       enabled: true,
    //       // speed: 0.00000005,
    //     },
    //     pinch: {
    //       enabled: false,
    //     },
    //     mode: 'xy',
    //     // rangeMin: {
    //     //   x: null,
    //     //   y: null,
    //     // },
    //     // rangeMax: {
    //     //   x: null,
    //     //   y: null,
    //     // },
    //   },
    //   pan: {
    //     enabled: true,
    //     mode: 'xy',
    //   },
    // },
    
  },
  
};


const fieldNames = [
  { api_name: 'atmospheric_tempratrature_readings', label_name: 'Air Temperature', borderColor: 'rgb(243, 58, 29)', backgroundColor: 'rgba(243, 58, 29,0.5)' },
  { api_name: 'atmospheric_pressure_redings', label_name: 'Air Pressure', borderColor: 'rgb(30, 179, 55)', backgroundColor: 'rgba(30, 179, 55,0.5)' },
  { api_name: 'atmospheric_humidity_readings', label_name: 'Air Humidity', borderColor: 'rgb(202, 76, 207)', backgroundColor: 'rgba(202, 76, 207,0.5)' },
  { api_name: 'wind_speed_readings', label_name: 'Wind Speed', borderColor: '#B2ABF2', backgroundColor: '#B2ABF2' },
  { api_name: 'wind_direction_readings', label_name: 'Wind Direction', borderColor: '#D7C9AA', backgroundColor: '#D7C9AA' },
  { api_name: 'canopy_temparture_readings', label_name: 'Canopy Temperature', borderColor: 'rgb(156, 155, 152)', backgroundColor: 'rgba(156, 155, 152,0.5)' },
  { api_name: 'leaf_wetness_readings', label_name: 'Leaf Wetness', borderColor: 'rgb(222, 217, 89)', backgroundColor: 'rgba(222, 217, 89,0.5)' },
  { api_name: 'solar_voltage_readings', label_name: 'Solar Voltage (V)', borderColor: '#F3A712', backgroundColor: '#F3A712' },
  { api_name: 'lux_readings', label_name: 'Lux Readings', borderColor: '#29335C', backgroundColor: '#29335C' },
  { api_name: 'npk_readings', label_name: 'N Readings', borderColor: '#29335C', backgroundColor: '#29335C' },
  { api_name: 'npk_readings', label_name: 'P Readings', borderColor: '#29335C', backgroundColor: '#29335C' },
  { api_name: 'npk_readings', label_name: 'K Readings', borderColor: '#29335C', backgroundColor: '#29335C' },
  { api_name: 'soil_moisture_reading_1', label_name: 'Soil Moisture_1', borderColor: 'rgb(243, 141, 46)', backgroundColor: 'rgba(243, 141, 46,0.5)' },
  { api_name: 'soil_humidity_readings', label_name: 'Soil Humidity', borderColor: '#6C91C2', backgroundColor: '#6C91C2' },
  { api_name: 'co2_level_readings', label_name: 'CO2 Readings', borderColor: '#534D41', backgroundColor: '#534D41' },
  { api_name: 'rain_guage_readings', label_name: 'Precipitation', borderColor: 'rgb(53, 122, 212)', backgroundColor: 'rgba(53, 122, 212,0.5)' },
  { api_name: 'battery_voltage_readings', label_name: 'Battery Voltage', borderColor: 'rgb(1, 163, 170)', backgroundColor: 'rgba(1, 163, 170, 0.5)' },
]
const MultiChart = ({ iotData, farmId }) => {
  ChartJS.defaults.font.size = 10;
  const iot = iotData;
  const initailLabels = iot?.readings?.atmospheric_humidity_readings?.map(
    (element) => element.timestamp.toString().split(', ')[1]
  );
  const [multiChartLabels, setMultiChartLabels] = useState(initailLabels?.filter((label: any, index: number) => (index % 4) === 0))

  const iotDataSet = [
    {
      label: "Air Temperature",
      data: iot?.readings?.atmospheric_tempratrature_readings?.map(
        (data) => data.reading
      ).filter((item, index) => ((index % 4) === 0)),
      borderColor: "rgb(243, 58, 29)",
      backgroundColor: "rgba(243, 58, 29,0.5)",
    },
    {
      label: "Air Pressure",
      data: iot?.readings?.atmospheric_pressure_redings?.map(
        (data) => data.reading
      ).filter((item, index) => ((index % 4) === 0)),
      borderColor: "rgb(30, 179, 55)",
      backgroundColor: "rgba(30, 179, 55,0.5)",
    },
    {
      label: "Air Humidity",
      data: iot?.readings?.atmospheric_humidity_readings?.map(
        (data) => data.reading
      ).filter((item, index) => ((index % 4) === 0)),
      borderColor: "rgb(202, 76, 207)",
      backgroundColor: "rgba(202, 76, 207,0.5)",
    },
    {
      label: "Wind Speed",
      data: iot?.readings?.wind_speed_readings?.map(
        (data) => data.reading
      ).filter((item, index) => ((index % 4) === 0)),
      borderColor: "#B2ABF2",
      backgroundColor: "#B2ABF2",
    },
    {
      label: "Wind Direction",
      data: iot?.readings?.wind_direction_readings?.map(
        (data) => data.reading
      ).filter((item, index) => ((index % 4) === 0)),
      borderColor: "#D7C9AA",
      backgroundColor: "#D7C9AA",
    },
    {
      label: "Canopy Temperature (C)",
      data: iot?.readings?.leaf_wetness_readings?.map(
        (data) => data.reading.leaf_temp
      ).filter((item, index) => ((index % 4) === 0)),
      borderColor: "rgb(156, 155, 152)",
      backgroundColor: "rgba(156, 155, 152,0.5)",
    },
    {
      label: "Leaf Wetness",
      data: iot?.readings?.leaf_wetness_readings?.map((data) => data.reading.leaf_humi).filter((item, index) => ((index % 4) === 0)),
      borderColor: "rgb(222, 217, 89)",
      backgroundColor: "rgba(222, 217, 89,0.5)",
    },
    {
      label: "Solar Voltage (V)",
      data: iot?.readings?.solar_voltage_readings?.map(
        (data) => data.reading
      ).filter((item, index) => ((index % 4) === 0)),
      borderColor: "#F3A712",
      backgroundColor: "#F3A712",
    },
    {
      label: "Lux Readings",
      data: iot?.readings?.lux_readings?.map(
        (data) => data.reading
      ).filter((item, index) => ((index % 4) === 0)),
      borderColor: "#29335C",
      backgroundColor: "#29335C",
    },
    {
      label: "N Readings",
      data: iot?.readings?.npk_readings?.map(
        (data) => data?.reading?.N
      ).filter((item, index) => ((index % 4) === 0)),
      borderColor: "#EFBCD5",
      backgroundColor: "#EFBCD5",
    },
    {
      label: "P Readings",
      data: iot?.readings?.npk_readings?.map(
        (data) => data?.reading?.P
      ).filter((item, index) => ((index % 4) === 0)),
      borderColor: "#BE97C6",
      backgroundColor: "#BE97C6",
    },
    {
      label: "K Readings",
      data: iot?.readings?.npk_readings?.map(
        (data) => data?.reading?.K
      ).filter((item, index) => ((index % 4) === 0)),
      borderColor: "#DD6E42",
      backgroundColor: "#DD6E42",
    },
    {
      label: "Soil Moisture_1",
      data: iot?.readings?.soil_moisture_reading_1?.map((data) => data.reading).filter((item, index) => ((index % 4) === 0)),
      borderColor: "rgb(243, 141, 46)",
      backgroundColor: "rgba(243, 141, 46,0.5)",
    },
    {
      label: "Soil Humidity",
      data: iot?.readings?.soil_humidity_readings?.map((data) => data.reading).filter((item, index) => ((index % 4) === 0)),
      borderColor: "#6C91C2",
      backgroundColor: "#6C91C2",
    },
    {
      label: "CO2 Readings",
      data: iot?.readings?.co2_level_readings?.map((data) => data.reading).filter((item, index) => ((index % 4) === 0)),
      borderColor: "#534D41",
      backgroundColor: "#534D41",
    },
    {
      label: "Precipitation",
      data: iot?.readings?.rain_guage_readings?.map((data) => data.reading).filter((item, index) => ((index % 4) === 0)),
      borderColor: "rgb(53, 122, 212)",
      backgroundColor: "rgba(53, 122, 212,0.5)",
    },
    {
      label: "Battery Voltage",
      data: iot?.readings?.battery_voltage_readings?.map(
        (data) => data.reading
      ).filter((item, index) => ((index % 4) === 0)),
      borderColor: "rgb(1, 163, 170)",
      backgroundColor: "rgba(1, 163, 170, 0.5)",
    },
  ];

  const [selectedOption1, setSelectedOption1] = useState({ ...iotDataSet[0], yAxisID: 'y' })
  const [selectedOption2, setSelectedOption2] = useState({ ...iotDataSet[2], yAxisID: 'y1' })
  const [data, setData] = useState({
    labels: multiChartLabels,
    datasets: [selectedOption1, selectedOption2]
  })
  console.log(data)
  useEffect(() => {
    setData({ labels: multiChartLabels, datasets: [selectedOption1, selectedOption2] })
  }, [multiChartLabels, selectedOption1, selectedOption2])

  const [isDateEnabled, setIsDateEnabled] = useState(false);
  const [iotDatasetRange,setIotDatasetRange] = useState<{ label: string; data: any; borderColor: string; backgroundColor: string; }[]>([]);

  const leftSelectHandler = (selectedLabel) => {
    // const selectedIndex = iotDataSet.findIndex(dataset => dataset?.label === selectedLabel)
    if(isDateEnabled){
      const selectedIndex = iotDatasetRange.findIndex(dataset => dataset?.label === selectedLabel)
      console.log(selectedIndex)
      console.log(iotDatasetRange)
      console.log(data)
      console.log(multiChartLabels)
      setSelectedOption1({ ...iotDatasetRange[selectedIndex] , yAxisID: 'y' })
    }
    else {
      const selectedIndex = iotDataSet.findIndex(dataset => dataset?.label === selectedLabel)
      setSelectedOption1({ ...iotDataSet[selectedIndex], yAxisID: 'y' })
    }
  }
  const rightSelectHandler = (selectedLabel) => {
    // console.log(selectedIndex)
    if(isDateEnabled) {
      const selectedIndex = iotDatasetRange.findIndex(dataset => dataset?.label === selectedLabel)
      setSelectedOption2({ ...iotDatasetRange[selectedIndex], yAxisID: 'y1' })
    }
    else {
      const selectedIndex = iotDataSet.findIndex(dataset => dataset?.label === selectedLabel)
      setSelectedOption2({ ...iotDataSet[selectedIndex], yAxisID: 'y1' })
    }
  }
  

  // useEffect(()=>{
  //   setData({
  //       labels: multiChartLabels,
  //       datasets : isDateEnabled ? iotDatasetRange : [selectedOption1, selectedOption2]
  //   })
  //   console.log(iotDatasetRange)
  // },[multiChartLabels, selectedOption1, selectedOption2])

  const fetchAllRanges = async ()=> {
    setIotDatasetRange([])
    fieldNames.forEach(async (item, index) => {
        let res = await axios.get(`https://fmserver.escorts.co.in/admin/farmer/readings?parameter=${item?.api_name}&start_date=${startDate?.current?.value}&end_date=${endDate?.current?.value}&farm_id=${farmId ?? 5}`)
        index === 0 && setMultiChartLabels(res?.data?.result?.map(
            (element) => element?.obj?.timestamp.toString().split(', ')[1]
          )?.filter((item, index) => ((index % 8) === 0)))
        setIotDatasetRange(prevArray => [...prevArray, {
            label: item.label_name,
            data : item.label_name === 'Leaf Wetness' ? res?.data?.result?.map(val => val?.obj?.reading.leaf_temp).filter((item, index) => ((index % 8) === 0)) :  res?.data?.result?.map(val => val?.obj?.reading).filter((item, index) => ((index % 8) === 0)),
            borderColor: item?.borderColor,
            backgroundColor: item?.backgroundColor
        }])
    })
  }
  const startDate = React.useRef<HTMLInputElement>(null)
  const endDate = React.useRef<HTMLInputElement>(null)
  const dateRangeHanlder = (e) => {
    e.preventDefault()
    setIsDateEnabled(true)
    // selectedField.current.value = 'all'
    console.log(startDate.current?.value)
    console.log(endDate.current?.value)
    if(!startDate.current?.value || !endDate.current?.value) {
      alert("Please select both the dates")
    } else {
      const fetchRangeData = async () => {
        await fetchAllRanges()
        console.log(iotDatasetRange)
      }
    fetchRangeData()
    }
  }
  return (
    <>
    <div className='chart__wrapper'>
      <Line options={options} data={data} className='chart_chart' height={'250px'}/></div>
      <div className='multichart__options'
        style={{ display: 'flex', justifyContent: 'space-evenly', margin: '1rem 0' }}>
        <select value={selectedOption1.label} onChange={(e) => leftSelectHandler(e.target.value)} className='chart__select'>
          {isDateEnabled ? 
          iotDatasetRange.map(dataset => (
            <option key={dataset?.label} value={dataset?.label}>{dataset.label}</option>
          )) 
          :
          iotDataSet.map(dataset => (
            <option key={dataset?.label} value={dataset?.label}>{dataset.label}</option>
          ))
          }
        </select>
        <select value={selectedOption2?.label} onChange={e => rightSelectHandler(e.target.value)} className='chart__select'>
          {isDateEnabled ?
          iotDatasetRange.map(dataset => (
            <option key={dataset?.label} value={dataset?.label}>{dataset.label}</option>
          )) 
          :
          iotDataSet.map(dataset => (
            <option key={dataset?.label} value={dataset?.label}>{dataset.label}</option>
          ))
          }
        </select>
      </div>
      <form className="dateRange__form" style={{width: 'fit-content', margin: 'auto'}} onSubmit={dateRangeHanlder}>
        <div className="chart__daterange__input">
          <input ref={startDate} type="date" placeholder="Date From" title="Date from" />
        </div>
        <div className="chart__daterange__input">
          <input ref={endDate} type="date" placeholder="Date upto" title="Date upto" />
        </div>
        <button type="submit">Submit</button>
      </form>
    </>
  )
}

export default MultiChart