import React from 'react';
import "./Compass.css";
const WindCompass = ({ windDirection }) => {
  const directionToDegrees=(val)=>{
    if(val==="S")        return 180;
    else if(val==="NNE") return 22.5;
    else if(val==="NE")return  45;
    else if(val==="ENE")return 67.5;
    else if(val==="E") return 90;
    else if(val==="ESE")return 112.5;
    else if(val==="SE")return 135;
    else if(val==="SSE") return 157.5;
    else if(val==="N")return  0;
    else if(val=="SWS")return 202.5;
    else if(val==="SW")return 225;
    else if(val==="WSW")return  247.5 ;
    else if(val==="W")return 270;
    else if (val==="WNW")return 292.5;
    else if(val==="NW")return 315;
    else if(val==="NNW")return 337.5;
  }
  // Calculate the rotation degree based on the wind direction
  const rotationDegree = directionToDegrees(windDirection); // Adjust for CSS rotation

  return (
    <div className="wind-compass">
      <div
        className="compass-needle"
        style={{ transform: `rotate(${rotationDegree}deg)` }}
      ></div>
      <div className="compass-direction">{windDirection}&deg;</div>
    </div>
  );
};

export default WindCompass;
