import React, { useState, useEffect, useRef } from "react";
import zoomPlugin from "chartjs-plugin-zoom";
import "./Chart.css";
import 'chartjs-adapter-moment';
import Card from "../card/Card";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale
} from "chart.js";

import { Line } from "react-chartjs-2";

import axios from "axios";

ChartJS.register(
  zoomPlugin,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale
);

const headers = [
  { label: "Timestamp", key: "timestamp" },
  { label: "Atmospheric Temprature", key: "atmosphericTemprature" },
  { label: "Atmospheric Pressure", key: "atmosphericPressure" },
  { label: "Atmospheric Humidity", key: "atmosphericHumidity" },
  { label: "Wind Speed", key: "windSpeed" },
  { label: "Wind Direction", key: "windDirection" },
  { label: "Canopy Temprature", key: "canopyTemprature" },
  { label: "Leaf Wetness", key: "leafWetness" },
  { label: "Solar Voltage", key: "solarVoltage" },
  { label: "Lux Readings", key: "luxReadings" },
  { label: "N Reading", key: "nReadings" },
  { label: "P Reading", key: "pReadings" },
  { label: "K Reading", key: "kReadings" },
  { label: "Soil Moisture", key: "soilMoisture1" },
  { label: "Soil Humidity", key: "soilHumidity" },
  { label: "CO2 Readings", key: "co2Readings" },
  { label: "Precipitation", key: "precipitation" },
  { label: "Battery Voltage", key: "batteryVoltage" },
];

const fieldNames = [
  {
    api_name: "atmospheric_tempratrature_readings",
    label_name: "Air Temperature (°C)",
    borderColor: "rgb(243, 58, 29)",
    backgroundColor: "rgba(243, 58, 29,0.5)",
  },
  {
    api_name: "atmospheric_pressure_redings",
    label_name: "Air Pressure (hPa)",
    borderColor: "rgb(30, 179, 55)",
    backgroundColor: "rgba(30, 179, 55,0.5)",
  },
  {
    api_name: "atmospheric_humidity_readings",
    label_name: "Air Humidity ( %RH)",
    borderColor: "rgb(202, 76, 207)",
    backgroundColor: "rgba(202, 76, 207,0.5)",
  },
  {
    api_name: "wind_speed_readings",
    label_name: "Wind Speed (km/h)",
    borderColor: "#B2ABF2",
    backgroundColor: "#B2ABF2",
  },
  {
    api_name: "wind_direction_readings",
    label_name: "Wind Direction (°N)",
    borderColor: "#D7C9AA",
    backgroundColor: "#D7C9AA",
  },
  {
    api_name: "canopy_temparture_readings",
    label_name: "Canopy Temperature (°C) ",
    borderColor: "rgb(156, 155, 152)",
    backgroundColor: "rgba(156, 155, 152,0.5)",
  },
  {
    api_name: "leaf_wetness_readings",
    label_name: "Leaf Wetness (%RH)",
    borderColor: "rgb(222, 217, 89)",
    backgroundColor: "rgba(222, 217, 89,0.5)",
  },
  {
    api_name: "solar_voltage_readings",
    label_name: "Solar Voltage (V)",
    borderColor: "#F3A712",
    backgroundColor: "#F3A712",
  },
  {
    api_name: "lux_readings",
    label_name: "Lux Readings (w/m2)",
    borderColor: "#29335C",
    backgroundColor: "#29335C",
  },
  {
    api_name: "npk_readings",
    label_name: "N Readings (mg/kg)",
    borderColor: "#29335C",
    backgroundColor: "#29335C",
  },
  {
    api_name: "npk_readings",
    label_name: "P Readings (mg/kg)",
    borderColor: "#29335C",
    backgroundColor: "#29335C",
  },
  {
    api_name: "npk_readings",
    label_name: "K Readings (mg/kg)",
    borderColor: "#29335C",
    backgroundColor: "#29335C",
  },
  {
    api_name: "soil_moisture_reading_1",
    label_name: "Soil Moisture_1 (cb)",
    borderColor: "rgb(243, 141, 46)",
    backgroundColor: "rgba(243, 141, 46,0.5)",
  },
  {
    api_name: "soil_humidity_readings",
    label_name: "Soil Humidity (%RH)",
    borderColor: "#6C91C2",
    backgroundColor: "#6C91C2",
  },
  {
    api_name: "co2_level_readings",
    label_name: "CO2 Readings",
    borderColor: "#534D41",
    backgroundColor: "#534D41",
  },
  {
    api_name: "rain_guage_readings",
    label_name: "Precipitation (mm)",
    borderColor: "rgb(53, 122, 212)",
    backgroundColor: "rgba(53, 122, 212,0.5)",
  },
  {
    api_name: "battery_voltage_readings",
    label_name: "Battery Voltage (V)",
    borderColor: "rgb(1, 163, 170)",
    backgroundColor: "rgba(1, 163, 170, 0.5)",
  },
  {
    api_name: "soil_temprature_readings",
    label_name: "Soil Temperature (°C)",
    borderColor: "rgb(1, 163, 170)",
    backgroundColor: "rgba(1, 163, 170, 0.5)",
  },
];

export default function ChartRange({ iot, farmId, setCsvLinkData }) {
  console.log(iot,farmId);
// console.log(ChartJS.getChart('6'));
// const chartRef = useRef(null);
  let getCurrentDateTime = () => {
    let currentDate = new Date();
    
    // Get the individual date and time components
    let year = currentDate.getFullYear();
    let month = String(currentDate.getMonth() + 1).padStart(2, '0');
    let day = String(currentDate.getDate()).padStart(2, '0');

    let hours = String(currentDate.getHours()).padStart(2, '0');
    if(hours<12)
    day--;
    // let minutes = String(currentDate.getMinutes()).padStart(2, '0');
    // let seconds = String(currentDate.getSeconds()).padStart(2, '0');
    
    // Format the components into the desired format
    let formattedDateTime = `${year}-${month}-${day} 12:00:00`;
    
    return formattedDateTime;
  };
  let currentDateTime=getCurrentDateTime();
  console.log(currentDateTime);
  ChartJS.defaults.font.size = 10;
  // console.log("hi iot data", iot);
  const initailLabel = iot?.readings?.atmospheric_humidity_readings?.map(
    (element) => element?.timestamp?.toString()
  );
  const [labels, setLabels] = useState(
    initailLabel
  );

  const iotDataSet = [
    {
      label: "Air Temperature (°C)",
      data: iot?.readings?.atmospheric_tempratrature_readings
        ?.map((data) => data.reading)
        ,
      borderColor: "rgb(243, 58, 29)",
      backgroundColor: "rgba(243, 58, 29,0.5)",
    },
    {
      label: "Air Pressure (hPa)",
      data: iot?.readings?.atmospheric_pressure_redings
        ?.map((data) => data.reading)
        ,
      borderColor: "rgb(30, 179, 55)",
      backgroundColor: "rgba(30, 179, 55,0.5)",
    },
    {
      label: "Air Humidity (%RH)",
      data: iot?.readings?.atmospheric_humidity_readings
        ?.map((data) => data.reading)
        ,
      borderColor: "rgb(202, 76, 207)",
      backgroundColor: "rgba(202, 76, 207,0.5)",
    },
    {
      label: "Wind Speed (km/h) ",
      data: iot?.readings?.wind_speed_readings
        ?.map((data) => data.reading)
        ,
      borderColor: "#B2ABF2",
      backgroundColor: "#B2ABF2",
    },
    {
      label: "Wind Direction(°C) ",
      data: iot?.readings?.wind_direction_readings
        ?.map((data) => data.reading)
        ,
      borderColor: "#D7C9AA",
      backgroundColor: "#D7C9AA",
    },
    {
      label: "Canopy Temperature (°C)",
      data: iot?.readings?.leaf_wetness_readings
        ?.map((data) => data.reading.leaf_temp)
        ,
      borderColor: "rgb(156, 155, 152)",
      backgroundColor: "rgba(156, 155, 152,0.5)",
    },
    {
      label: "Leaf Wetness (%RH)",
      data: iot?.readings?.leaf_wetness_readings
        ?.map((data) => data.reading.leaf_humi)
        ,
      borderColor: "rgb(222, 217, 89)",
      backgroundColor: "rgba(222, 217, 89,0.5)",
    },
    {
      label: "Solar Voltage (V)",
      data: iot?.readings?.solar_voltage_readings
        ?.map((data) => data.reading)
        ,
      borderColor: "#F3A712",
      backgroundColor: "#F3A712",
    },
    {
      label: "Lux Readings (w/m2)",
      data: iot?.readings?.lux_readings
        ?.map((data) => data.reading)
        ,
      borderColor: "#29335C",
      backgroundColor: "#29335C",
    },
    {
      label: "N Readings (mg/kg)",
      data: iot?.readings?.npk_readings
        ?.map((data) => data?.reading?.N)
        ,
      borderColor: "#EFBCD5",
      backgroundColor: "#EFBCD5",
    },
    {
      label: "P Readings (mg/kg)",
      data: iot?.readings?.npk_readings
        ?.map((data) => data?.reading?.P)
        ,
      borderColor: "#BE97C6",
      backgroundColor: "#BE97C6",
    },
    {
      label: "K Readings (mg/kg)",
      data: iot?.readings?.npk_readings
        ?.map((data) => data?.reading?.K)
        ,
      borderColor: "#DD6E42",
      backgroundColor: "#DD6E42",
    },
    {
      label: "Soil Moisture_1 (cb)",
      data: iot?.readings?.soil_moisture_reading_1
        ?.map((data) => data.reading)
        ,
      borderColor: "rgb(243, 141, 46)",
      backgroundColor: "rgba(243, 141, 46,0.5)",
    },
    {
      label: "Soil Humidity (%RH)",
      data: iot?.readings?.soil_humidity_readings
        ?.map((data) => data.reading)
        ,
      borderColor: "#6C91C2",
      backgroundColor: "#6C91C2",
    },
    {
      label: "CO2 Readings",
      data: iot?.readings?.co2_level_readings
        ?.map((data) => data.reading)
        ,
      borderColor: "#534D41",
      backgroundColor: "#534D41",
    },
    {
      label: "Precipitation (mm)",
      data: iot?.readings?.rain_guage_readings
        ?.map((data) => data.reading)
        ,
      borderColor: "rgb(53, 122, 212)",
      backgroundColor: "rgba(53, 122, 212,0.5)",
    },
    {
      label: "Battery Voltage (V)",
      data: iot?.readings?.battery_voltage_readings
        ?.map((data) => data.reading)
        ,
      borderColor: "rgb(1, 163, 170)",
      backgroundColor: "rgba(1, 163, 170, 0.5)",
    },
    {
      label: "Soil Temperature (°C)",
      data: iot?.readings?.soil_temprature_readings
        ?.map((data) => data.reading)
        ,
      borderColor: "rgb(150, 75, 0)",
      backgroundColor: "rgba(1, 163, 170, 0.5)",
    },
  ];

  const [selectedOption, setSelectedOption] = useState(iotDataSet);
  const startDate = useRef();
  const endDate = useRef();
  // const selectedField = useRef();
  const [data, setData] = useState({
    labels,
    datasets: selectedOption,
  });
  const [isDateEnabled, setIsDateEnabled] = useState(false);

  // useEffect(()=> {

  // },[farmId])

  const handleSelectChange = (e) => {
    const selectedValue = e.target.value;
    // console.log(selectedValue)
    // console.log(selectedField.current.value)
    console.log(iotDatasetRange);
    const selectedObject = isDateEnabled
      ? iotDatasetRange.find((dataSet) => dataSet?.label === selectedValue)
      : iotDataSet.find((dataSet) => dataSet?.label === selectedValue);
    // console.log("%chey buddy", "color: green; font-size: larger", selectedObject);
    if (selectedValue === "all") {
      setSelectedOption(isDateEnabled ? iotDatasetRange : iotDataSet);
      setData({
        labels,
        datasets: isDateEnabled ? iotDatasetRange : iotDataSet,
      });
    } else {
      setSelectedOption(selectedObject);
      setData({
        labels,
        datasets: [selectedObject],
      });
    }
  };

  // console.log("hey option", selectedOption);

  // console.log(data);
  const options = {
    scales: {
      x: {
        ticks: {
          font: {
              size: 14,
          }
      },
     
        // max: currentTime,
        // min: currentDateTime, // this is not current date time, but it has logic whether to output tomorrows 12pm or todays.
        // alignToPixels:true,
        type:'time',
          title: {
              display:true,
              text:"timestamps"
          },
      },
      y: {
        ticks: {
          font: {
              size: 14,
          }
      }},
      x1: {
        ticks: {
          font: {
              size: 12,
          }
        },
        type: 'time',
        time: {
          unit: 'day',
          // displayFormats: {
          //   month: 'DD'
          // }
        },
        // position: 'bottom',
      }
  },
    tension: 0.3,
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "bottom",
        labels: {
          font: {
            size: 15,
          },
        },
      },
      
      title: {
        display: false,
        text: "Chart.js Line Chart",
      },
      zoom: {
        zoom: {
          // overScaleMode:{
          //   enabled:true,
          //   mode:'x'
          // },
          wheel: {
            enabled: false,
            speed:0.1
          },
          drag:{
            enabled:true,
            mode:"x",
            modifierKey:'shift',
            backgroundColor:'rgba(0,0,0,0.3)'
          },
          mode: "x",
          // speed: 100
        },
        pan: {
          enabled: true,
          mode: "x",
          // speed: 0.5
        },
        limits: {
          // y: {min: 0, max:100},
          // x: { min: 1680516080000,
          //   max: 1680616080000,}
        },
      }
    },
  };
  const [iotDatasetRange, setIotDatasetRange] = useState([]);
  useEffect(() => {
    setData({
      labels,
      datasets: isDateEnabled ? iotDatasetRange : iotDataSet,
    });
    // console.log(iotDatasetRange)
  }, [labels,iot]);

  const [isRangeCsvData, setIsRangeCsvData] = useState(false);
  const [fullRangeData, setFullRangeData] = useState([]);

  const [fullLabels, setFullLabels] = useState(null);

  const fetchAllRanges = async () => {
    console.log("fetch all ranges")
    setIotDatasetRange([]);
    fieldNames.forEach(async (item) => {
      let res = await axios.get(
        `https://fmserver.escorts.co.in/admin/farmer/readings?parameter=${item?.api_name}&start_date=${startDate?.current?.value}&end_date=${endDate?.current?.value}&farm_id=${farmId}`
      );
      console.log(res);
      setLabels(
        res?.data?.result
          ?.map((element) => element?.obj?.timestamp.toString())
  
      );
      // console.log(labels);
      setFullLabels(res?.data?.result?.map((el) => el?.obj?.timestamp.toString()));
      setIotDatasetRange((prevArray) => [
        ...prevArray,
        {
          label: item.label_name,
          data:
            item.label_name === "Leaf Wetness"
              ? res?.data?.result
                  ?.map((val) => val?.obj?.reading?.leaf_humi)
                  
              : item.label_name === "N Readings"
              ? res?.data?.result
                  ?.map((val) => val?.obj?.reading?.N)
                  
              : item.label_name === "P Readings"
              ? res?.data?.result
                  ?.map((val) => val?.obj?.reading?.P)
                  
              : item.label_name === "K Readings"
              ? res?.data?.result
                  ?.map((val) => val?.obj?.reading?.K)
                  
              : res?.data?.result
                  ?.map((val) => val?.obj?.reading)
                  ,
          borderColor: item?.borderColor,
          backgroundColor: item?.backgroundColor,
        },
      ]);
      setFullRangeData((prev) => [
        ...prev,
        {
          [item?.api_name]:
              item.label_name === "Leaf Wetness"
              ? res?.data?.result?.map((val) => val?.obj?.reading?.leaf_humi)
              : item.label_name === "N Readings"
              ? res?.data?.result?.map((val) => val?.obj?.reading?.N)
              : item.label_name === "P Readings"
              ? res?.data?.result?.map((val) => val?.obj?.reading?.P)
              : item.label_name === "K Readings"
              ? res?.data?.result?.map((val) => val?.obj?.reading?.K)
              : res?.data?.result?.map((val) => val?.obj?.reading),
          name: item?.api_name,
          label_name: item?.label_name,
        },
      ]);
    });
    console.log(iotDatasetRange);
  };

  const dateRangeHanlder = (e) => {
    console.log("date handler")
    e.preventDefault();
    setIsDateEnabled(true);
    // selectedField.current.value = "all";
    // console.log(startDate.current.value)
    // console.log(endDate.current.value)
    if (!startDate.current.value || !endDate.current.value) {
      alert("Please select both the dates");
    } else {
      const fetchRangeData = async () => {
        await fetchAllRanges();
        setIsRangeCsvData(true);
        setSelectedOption(iotDatasetRange);
      };
      fetchRangeData();
    }
    console.log(iotDatasetRange);
  };

  useEffect(() => {
    if (iotDatasetRange?.length === fieldNames.length) {
      console.log("%cIotDatasetRange", "color: green");
      console.log(iotDatasetRange);
      console.log(fullLabels);
      console.log("%cfullRangeData", "color: green; font-size: larger");
      console.log(fullRangeData);
      const downloadData = [];
      for (let i = 0; i < fullLabels.length; i++) {
        downloadData.push({
          timestamp: fullLabels[i],
          atmosphericTemprature:
            fullRangeData[
              fullRangeData.findIndex(
                (item) => item.name === "atmospheric_tempratrature_readings"
              )
            ]?.atmospheric_tempratrature_readings[i],
          atmosphericPressure:
            fullRangeData[
              fullRangeData.findIndex(
                (item) => item.name === "atmospheric_pressure_redings"
              )
            ]?.atmospheric_pressure_redings[i],
          atmosphericHumidity:
            fullRangeData[
              fullRangeData.findIndex(
                (item) => item.name === "atmospheric_humidity_readings"
              )
            ]?.atmospheric_humidity_readings[i],
          windSpeed:
            fullRangeData[
              fullRangeData.findIndex(
                (item) => item.name === "wind_speed_readings"
              )
            ]?.wind_speed_readings[i],
          windDirection:
            fullRangeData[
              fullRangeData.findIndex(
                (item) => item.name === "wind_direction_readings"
              )
            ]?.wind_direction_readings[i],
          canopyTemprature:
            fullRangeData[
              fullRangeData.findIndex(
                (item) => item.name === "canopy_temparture_readings"
              )
            ]?.canopy_temparture_readings[i],
          leafWetness:
            fullRangeData[
              fullRangeData.findIndex(
                (item) => item.name === "leaf_wetness_readings"
              )
            ]?.leaf_wetness_readings[i],
          solarVoltage:
            fullRangeData[
              fullRangeData.findIndex(
                (item) => item.name === "solar_voltage_readings"
              )
            ]?.solar_voltage_readings[i],
          luxReadings:
            fullRangeData[
              fullRangeData.findIndex((item) => item.name === "lux_readings")
            ]?.lux_readings[i],
          nReadings:
            fullRangeData[
              fullRangeData.findIndex(
                (item) => item.label_name === "N Readings"
              )
            ]?.npk_readings[i],
          pReadings:
            fullRangeData[
              fullRangeData.findIndex(
                (item) => item.label_name === "P Readings"
              )
            ]?.npk_readings[i],
          kReadings:
            fullRangeData[
              fullRangeData.findIndex(
                (item) => item.label_name === "K Readings"
              )
            ]?.npk_readings[i],
          soilMoisture1:
            fullRangeData[
              fullRangeData.findIndex(
                (item) => item.name === "soil_moisture_reading_1"
              )
            ]?.soil_moisture_reading_1[i],
          soilHumidity:
            fullRangeData[
              fullRangeData.findIndex(
                (item) => item.name === "soil_humidity_readings"
              )
            ]?.soil_humidity_readings[i],
          co2Readings:
            fullRangeData[
              fullRangeData.findIndex(
                (item) => item.name === "co2_level_readings"
              )
            ]?.co2_level_readings[i],
          precipitation:
            fullRangeData[
              fullRangeData.findIndex(
                (item) => item.name === "rain_guage_readings"
              )
            ]?.rain_guage_readings[i],
          batteryVoltage:
            fullRangeData[
              fullRangeData.findIndex(
                (item) => item.name === "battery_voltage_readings"
              )
            ]?.battery_voltage_readings[i],
          soilTemperature:
            fullRangeData[
              fullRangeData.findIndex(
                (item) => item.name === "soil_temperature_readings"
              )
            ]?.soil_temperature_readings[i],
        });
      }

      setCsvLinkData({
        filename: "iot_data.csv",
        headers: headers,
        data: downloadData,
      });
    }
  }, [iotDatasetRange]);
  // const handleZoomClick = () => {
  //   if (chartRef.current) {
  //     const chartInstance = ChartJS.instances[2];
  //     chartInstance.zoom({mode:'x',amount:-0.5})

  //     chartInstance.update() // Update the chart
  //   }
  // };
  console.log((isDateEnabled?iotDataSet:iotDatasetRange));
  return (
    <>
      {iot ? (
        <>
          <div className="chart__wrapper">
            {
              (!isDateEnabled?iotDataSet:iotDatasetRange).map((value,index)=>{
                return (   
              <Card>
              <Line
                key={index}
                options={options}
                data={{labels,datasets:[value]}}
                className="chart_chart"
              />
              </Card>                  
              )})
            }
          </div>
          <div className="chart__controller__inputs">
            {/* <div
              className="chart__dropdown"
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                margin: "1rem 0",
              }}
            >
              <select
                ref={selectedField}
                onChange={handleSelectChange}
                className="chart__select"
              >
                <option key="all" value="all">
                  All Values
                </option>
                {iotDataSet.map((dataSet) => (
                  <option key={dataSet?.label} value={dataSet?.label}>
                    {dataSet?.label}
                  </option>
                ))}
              </select>
            </div> */}
            {
              <form className="dateRange__form" onSubmit={dateRangeHanlder}>
                <div className="chart__daterange__input">
                  <input
                    ref={startDate}
                    type="date"
                    placeholder="Date From"
                    title="Date from"
                  />
                </div>
                <div className="chart__daterange__input">
                  <input
                    ref={endDate}
                    type="date"
                    placeholder="Date upto"
                    title="Date upto"
                  />
                </div>
                <button type="submit">Submit</button>
              </form>
            }
          </div>
        </>
      ) : (
        <h2>Select farm to show data chart</h2>
      )}
    </>
  );
}
