import { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./android_download.css";
import logo from "../../assets/Escort_kubota_limited.png"
export default function Android_download() {
  const [latestData, setLatestData] = useState(null);
  const [allData, setAllData] = useState([]);
  const [activeButton, setActiveButton] = useState(null);
  const navigate=useNavigate();
  const getLatest = () => {
    fetch("https://3lsgrc5zl5nwmha5nhl624zugu0hnhmm.lambda-url.us-west-2.on.aws/latest")
      .then((response) => response.json())
      .then((data) => {
        setAllData([]);
        setLatestData(data);
        setActiveButton("latest");
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const getAll = () => {
    fetch("https://3lsgrc5zl5nwmha5nhl624zugu0hnhmm.lambda-url.us-west-2.on.aws/all")
      .then((response) => response.json())
      .then((data) => {
        setLatestData(null);
        setAllData(data?.all);
        setActiveButton("all");
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <main className="main">
      <img onClick={()=>{navigate("/overview")}} src={logo} style={{display:"block",marginRight:"0"}} />
      <div className="download_window_main">
        <div className="pseudo_main">
          <h1>APK Downloader</h1>
          <div className="download_first">
            <button
              className={`get_latest_button ${activeButton === "latest" ? "active" : ""}`}
              onClick={getLatest}
            >
              Latest
            </button>
            <button
              className={`get_all_button ${activeButton === "all" ? "active" : ""}`}
              onClick={getAll}
            >
              All
            </button>
          </div>
          <div className="list_data">
            {(activeButton === "latest" && latestData) && (
              <div className="mb-2">
                <p>Latest APK version:</p>
                <h2>Date Added: {Object.keys(latestData)[0]}</h2>
                <button className="download_button" href={Object.values(latestData)[0]} download>
                  Download
                </button>
              </div>
            )}
            {(activeButton === "all" && allData.length > 0) && (
              <div>
                <h2 className="all_apk_versions">All APK versions:</h2>
                {allData.map((timestampObj) => {
                  const [timestamp, link] = Object.entries(timestampObj)[0];
                  return (
                    <div key={timestamp} className="mb-2">
                      <p>Date Added: {timestamp}</p>
                      <button className="download_button"  href={link} download>
                        Download
                      </button>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </div>
    </main>
  );
}
