import React, { useState, useEffect, useRef } from "react";
import zoomPlugin from "chartjs-plugin-zoom";
import "./Chart.css";
import 'chartjs-adapter-moment';
import Card from "../card/Card";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale
} from "chart.js";

import { Line } from "react-chartjs-2";

import axios from "axios";

ChartJS.register(
  zoomPlugin,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale
);

export default function ChartSeperated({fieldValue,iot,farmId,setCsvLinkData}){
    console.log(farmId)
    const startDate = useRef();
    const endDate = useRef();
    const [isDateEnabled,setIsDateEnabled]=useState(false);
    const [labels, setLabels] = useState([]);
    const [Data,setData] =useState([])
    // useeffect for updating the graph data once the farm is changed.
    useEffect(() => {
      switch (fieldValue.label_name) {
        case "Leaf Wetness (%RH)":
          setData(iot?.readings[fieldValue.api_name]?.map((data) => data.reading.leaf_humi));
          break;
        case "Canopy Temperature (°C)":
          setData(iot?.readings[fieldValue.api_name]?.map((data) => data.reading.leaf_temp));
          break;
        case "N Readings (mg/kg)":
          setData(iot?.readings[fieldValue.api_name]?.map((data) => data.reading.N));
          break;
        case "P Readings (mg/kg)":
          setData(iot?.readings[fieldValue.api_name]?.map((data) => data.reading.P));
          break;
        case "K Readings (mg/kg)":
          setData(iot?.readings[fieldValue.api_name]?.map((data) => data.reading.K));
          break;
        default:
          setData(iot?.readings[fieldValue.api_name]?.map((data) => data.reading));
          break;
      }
      setLabels((iot?.readings[fieldValue.api_name])?.map((element) => element?.timestamp?.toString()))
    }, [iot]);
    //function to set data on graph for 1 day 
    function oneDay()
    {
      setLabels((iot?.readings[fieldValue.api_name])?.map((element) => element?.timestamp?.toString()))
      setData(iot?.readings[fieldValue.api_name]
        ?.map((data) => data.reading))
    }
    //function to set data on graph for 1 week 
    async function oneWeek()
    {
      let todayDate = new Date().toISOString().split('T')[0]
      let now =new Date();
      let oneWeekBeforeDate=new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000).toISOString().split('T')[0];
      console.log(todayDate);
      console.log(oneWeekBeforeDate);
      let res = await axios.get(
        `https://fmserver.escorts.co.in/admin/farmer/readings?parameter=${fieldValue.api_name}&start_date=${oneWeekBeforeDate}&end_date=${todayDate}&farm_id=${farmId}`
      );
      console.log(res);
      setLabels(
        res?.data?.result
          ?.map((element) => element?.obj?.timestamp.toString())
      );
      switch (fieldValue.label_name) {
        case "Leaf Wetness (%RH)":
          setData(res?.data?.result?.map((val) => val?.obj?.reading.leaf_humi));
          break;
        case "Canopy Temperature (°C)":
          setData(res?.data?.result?.map((val) => val?.obj?.reading.leaf_temp));
          break;
        case "N Readings (mg/kg)":
          setData(res?.data?.result?.map((val) => val?.obj?.reading.N));
          break;
        case "P Readings (mg/kg)":
          setData(res?.data?.result?.map((val) => val?.obj?.reading.P));
          break;
        case "K Readings (mg/kg)":
          setData(res?.data?.result?.map((val) => val?.obj?.reading.K));
          break;
        default:
          setData(res?.data?.result?.map((val) => val?.obj?.reading));
          break;
      }
    }
    const options = {
        scales: {
          x: {
            ticks: {
              font: {
                  size: 14,
              }
          },
         
            // max: currentTime,
            // min: currentDateTime, // this is not current date time, but it has logic whether to output tomorrows 12pm or todays.
            // alignToPixels:true,
            type:'time',
              title: {
                  display:true,
                  text:"timestamps"
              },
          },
          y: {
            ticks: {
              font: {
                  size: 14,
              }
          }},
          x1: {
            ticks: {
              font: {
                  size: 12,
              }
            },
            type: 'time',
            time: {
              unit: 'day',
              // displayFormats: {
              //   month: 'DD'
              // }
            },
            // position: 'bottom',
          }
      },
        tension: 0.3,
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          tooltips: {
            mode: 'index',
            intersect: false,
        },
          legend: {
            position: "bottom",
            labels: {
              font: {
                size: 18,
              },
            },
          },
          
          title: {
            display: false,
            text: "Chart.js Line Chart",
          },
          zoom: {
            zoom: {
              // overScaleMode:{
              //   enabled:true,
              //   mode:'x'
              // },
              wheel: {
                enabled: false,
                speed:0.1
              },
              drag:{
                enabled:true,
                mode:"x",
                modifierKey:'shift',
                backgroundColor:'rgba(0,0,0,0.3)'
              },
              mode: "x",
              // speed: 100
            },
            pan: {
              enabled: true,
              mode: "x",
              // speed: 0.5
            },
            limits: {
              // y: {min: 0, max:100},
              // x: { min: 1680516080000,
              //   max: 1680616080000,}
            },
          }
        },
      };
      function showdateselector()
      {
        setIsDateEnabled(true);
      }
      // function to set data on graph for custom date range
      const customDate = async () => {
        console.log("fetch all ranges")
          let res = await axios.get(
            `https://fmserver.escorts.co.in/admin/farmer/readings?parameter=${fieldValue.api_name}&start_date=${startDate?.current?.value}&end_date=${endDate?.current?.value}&farm_id=${farmId}`
          );
          console.log(res);
          setLabels(
            res?.data?.result
              ?.map((element) => element?.obj?.timestamp.toString())
      
          );
          switch (fieldValue.label_name) {
            case "Leaf Wetness (%RH)":
              setData(res?.data?.result?.map((val) => val?.obj?.reading.leaf_humi));
              break;
            case "Canopy Temperature (°C)":
              setData(res?.data?.result?.map((val) => val?.obj?.reading.leaf_temp));
              break;
            case "N Readings (mg/kg)":
              setData(res?.data?.result?.map((val) => val?.obj?.reading.N));
              break;
            case "P Readings (mg/kg)":
              setData(res?.data?.result?.map((val) => val?.obj?.reading.P));
              break;
            case "K Readings (mg/kg)":
              setData(res?.data?.result?.map((val) => val?.obj?.reading.K));
              break;
            default:
              setData(res?.data?.result?.map((val) => val?.obj?.reading));
              break;
          }
        // console.log(iotDatasetRange);
      };
      const dateRangeHanlder = (e) => {
        console.log("date handler")
        console.log(startDate)
        console.log(endDate)
        e.preventDefault();
        // setIsDateEnabled(true);
        // selectedField.current.value = "all";
        // console.log(startDate.current.value)
        // console.log(endDate.current.value)
        if (!startDate.current.value || !endDate.current.value) {
          alert("Please select both the dates");
        } else {
          const fetchRangeData = async () => {
            await customDate();
            // setIsRangeCsvData(true);
            // setSelectedOption(iotDatasetRange);
          };
          fetchRangeData();
        }
        // console.log(iotDatasetRange);
      };
    return (
        <>
        {
          Data?
        <div className="chart__wrapper">
        <Card>
              <Line
                options={options}
                data={{labels,datasets:[
                    {
                    label: fieldValue.label_name,
                    data: Data,
                    borderColor: fieldValue.borderColor,
                    backgroundColor: fieldValue.backgroundColor}
                  ]}}
                  className="chart_chart"
              />
              <div className="chart__controller__inputs">
            {isDateEnabled?
              <form className="dateRange__form" onSubmit={dateRangeHanlder}>
                <div className="chart__daterange__input">
                  <input
                    ref={startDate}
                    type="date"
                    placeholder="Date From"
                    title="Date from"
                  />
                </div>
                <div className="chart__daterange__input">
                  <input
                    ref={endDate}
                    type="date"
                    placeholder="Date upto"
                    title="Date upto"
                  />
                </div>
                <button type="submit">Submit</button>
              </form>  
            :<button onClick={showdateselector}>custom</button>}
            <button onClick={oneDay}>1 day</button>
            <button onClick={oneWeek}>1 Week</button>
          </div>
        </Card> 
        </div>:<></>
}
        </>
    )
}