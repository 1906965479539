import React from "react";
import '../../pages/agro/agroControl.css'

const CropTable = ({ farmInfo }) => {
    const farm = farmInfo;
    console.log("FARMINFO ------ ", farm)
    return (
    <div className="agro__table__main">
        <div className="agro-crop_table_grid">
        <div className="agro-table_data_item">
            <span className="table_item_key">Crop Name</span>
            <span>{farm?.current_crop}</span>
        </div>

        <div className="agro-table_data_item">
            <span className="table_item_key">Variety</span>
            <span>{''}</span>
        </div>

        <div className="agro-table_data_item">
            <span className="table_item_key">Spacing</span>
            <span>{''}</span>
        </div>

        <div className="agro-table_data_item">
            <span className="table_item_key">Sowing Date</span>
            <span>{farm?.sowing_date}</span>
        </div>
        </div>
    </div>
  );
};

export default CropTable;
