function CropCalendar({ farmInfo, iot }) {
  console.log(farmInfo);
  console.log(iot);
  function weeksPassedFromDateToDate(startDate) {
    const oneDay = 24 * 60 * 60 * 1000; // One day in milliseconds
    const currentDate = new Date(); // Current date
    const timeDiff = Math.abs(currentDate - startDate); // Time difference in milliseconds
    const numberOfWeeks = Math.floor(timeDiff / (7 * oneDay)); // Number of full weeks passed
    const numberOfPartialWeeks = Math.ceil((timeDiff % (7 * oneDay)) / oneDay); // Number of days in the partial week

    return numberOfWeeks + numberOfPartialWeeks / 7; // Add partial weeks to the total
  }
  function monthsPassedFromPruningDate() {
    const monthsPassed = new Date().getMonth() - farmInfo.pruningDate + 2;
    return monthsPassed;
  }
  const startDate = new Date(farmInfo.sowing_date); // Replace this with your desired start date
  const weeksPassed = weeksPassedFromDateToDate(startDate);
  // setRoundedOfWeekPasses(weeksPassed);
  console.log(
    `Weeks passed since ${startDate.toDateString()}: ${weeksPassed} weeks`
  );
  function checkForColor(item) {
    if (farmInfo.crop_type === "ST") {
      if (
        weeksPassedFromDateToDate(startDate) >= item?.week?.[0] &&
        weeksPassedFromDateToDate(startDate) <=
          item?.week?.[item?.week.length - 1]
      )
        return "rgb(230,230,105)";
      else if (weeksPassedFromDateToDate(startDate) <= item?.week?.[0])
        return "rgb(230,159,105)";
      else return "rgb(146,240,146)";
    } else {
      if (
        monthsPassedFromPruningDate() >= item?.months?.[0] &&
        monthsPassedFromPruningDate() <= item?.months?.[item?.months.length - 1]
      )
        return "rgb(230,230,105)";
      else if (monthsPassedFromPruningDate() <= item?.months?.[0])
        return "rgb(230,159,105)";
      else return "rgb(146,240,146)";
    }
  }
  console.log(monthsPassedFromPruningDate());
  // console.log(`Months passed since ${farmInfo.pruningDate});

  return (
    <>
      <div className="agro__right__tables__container">
        <div className="agro__right__table__title">Crop Calendar</div>
        <div className="crop_calendar_heading">
          <div>Current Crop:{farmInfo.current_crop}</div>
          {/* <div>Stage:{console.log(checkForStage())}</div> */}
          {/* <div>Week:{roundedOfWeekPassed}</div> */}
        </div>
        <table className="agro__right__table">
          <thead className="agro__right__thead">
            <tr className="agro__right__table__row">
              <th
                align="center"
                width="20%"
                className="agro__right__table__head__item"
              >
                Week
              </th>
              <th className="agro__right__table__head__item">
                Current Crop Stage
              </th>
              <th className="agro__right__table__head__item">Task</th>
              {/* <th className="agro__right__table__head__item">Status</th>
                            <th className="agro__right__table__head__item">Remarks</th> */}
            </tr>
          </thead>
          {iot?.cropCalendar ? (
            <tbody className="agro__right__table__body">
              {iot?.cropCalendar?.map((item, index) => {
                return (
                  <tr
                    key={`${item}-${index}`}
                    className="agro__right__table__row"
                    style={{
                      backgroundColor: checkForColor(item),
                    }}
                  >
                    {farmInfo?.crop_type === "LT" ? (
                      <td align="center" width="20%">
                        {item?.months?.length == 1
                          ? item?.months?.[0]
                          : `${item?.months[0]} - ${
                              item?.months[item?.months?.length - 1]
                            }`}
                      </td>
                    ) : (
                      <td align="center" width="20%">
                        {item?.week?.length == 1
                          ? item?.week?.[0]
                          : `${item?.week[0]} - ${
                              item?.week[item?.week?.length - 1]
                            }`}
                      </td>
                    )}
                    <td align="center">{item?.stage}</td>
                    <tr
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      {item?.tasks?.map((index) => {
                        return (
                          <td>
                            <li>{index.name}</li>
                          </td>
                        );
                      })}
                    </tr>
                  </tr>
                );
              })}
               <div style={{display:"flex",justifyContent:"space-around",flexDirection:"row"}}>
                <p>Completed <span style={{display:"inline-block",width:"22px",height:"10px",backgroundColor:"rgb(146,240,146)"}}></span></p>
                <p>Ongoing <span style={{display:"inline-block",width:"22px",height:"10px",backgroundColor:"rgb(230,230,105)"}}></span></p>
                <p>Upcoming <span style={{display:"inline-block",width:"22px",height:"10px",backgroundColor: "rgb(230,159,105)"}}></span></p>
              </div>
            </tbody>
          ) : (
            <>Crop calendar is not present </>
          )}
        </table>
      </div>
    </>
  );
}
export default CropCalendar;
