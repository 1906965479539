import { createContext, useReducer } from "react";
import UserReducer from "./UserReducer";

// let currIotToken = null
// let currAgroToken = null
// if (sessionStorage.getItem('iotToken') || sessionStorage.getItem("agroToken")) {
//     const setToken = async () => {
//         currIotToken = sessionStorage.getItem('iotToken')
//         currAgroToken = sessionStorage.getItem('agroToken')
//         console.log('Fetched from session storage')
//     }
//     setToken()
// }

let currAuthToken = null
let department=null;
const INITIAL_STATE = {
    // iotToken: currIotToken,
    // agroToken: currAgroToken,
    authToken: currAuthToken,
    department: department
}
if (localStorage.getItem('authToken')) {
    const tokenSet = async () => {
        const setToken = async () => {
            currAuthToken = localStorage.getItem('authToken')
            
            console.log('Fetched from session storage')
        }
        await setToken()
        INITIAL_STATE.authToken = currAuthToken
    }
    tokenSet()
}
if (localStorage.getItem('department')) {
    const departmentSet = async () => {
        const setDepartment = async () => {
            department = localStorage.getItem('department')
            console.log('Fetched from session storage')
        }
        await setDepartment()
        INITIAL_STATE.department = department;
    }
    departmentSet()
}




export const UserContext = createContext()

export const UserContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(UserReducer, INITIAL_STATE)
    return (
        <UserContext.Provider value={{ state, dispatch }}>
            {children}
        </UserContext.Provider>
    )
}