import Login from "./pages/login/Login";
import SignUp from "./pages/signup/SignUp";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import IotControl from "./pages/iot/IotControl";
import AgroControl from "./pages/agro/agroControl";
import Overview from "./pages/overview/Overview";
import Home from "./pages/home/Home";
import Compare from "./pages/compare/Compare";
// import Agro2 from "./pages/agro2/Agro2";
import Query from "./pages/Query/Query";
import RTWS_SHEET from "./pages/rtwsSheet/RTWS_SHEET";
import Android_download from "./pages/android_download/Android_download";
import FarmerDirectory from "./pages/FarmerDirectory/FarmerDirectory";
import DeviceDirectory from "./pages/DeviceDirectory/DeviceDirectory";
import AverageSensorData from "./pages/averageSensorData/AverageSensorData";
import AverageNew from "./pages/averageNew/AverageNew";
import IrrigationNotifications from "./pages/Notifications/IrrigationNotifications";
import RemoteSensing from "./pages/RemoteSensing/RemoteSensing";


function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/iot" element={<IotControl />} />
          <Route path="/agro" element={<AgroControl />} />
          {/* <Route path="/agro2" element={<Agro2 />} /> */}
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/overview" element={<Overview />} />
          <Route path="/compare" element={<Compare />} />
          <Route path="/query" element={<Query />} />
          <Route path="/rtws" element={<RTWS_SHEET/>} />
          <Route path="/android_download" element={<Android_download/>} />
          <Route path="/farmer_directory" element={<FarmerDirectory/>} />
          <Route path="/device_directory" element={<DeviceDirectory/>} />
          <Route path="/average_sensor_data" element={<AverageSensorData/>}/>
          <Route path="/average_sensor_data_new" element={<AverageNew/>}/>
          <Route path="/irrigation_notifications" element={<IrrigationNotifications/>}/>
          <Route path="/remote_sensing" element={<RemoteSensing/>}/>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
