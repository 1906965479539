import "./Query.css";
import Card from "../../components/card/Card";
import Topbar from "../../components/topbar/Topbar";
import QueryCard from "../../components/QueryCard/QueryCard";
import axios from "axios";
import { useRef, useState, useContext } from "react";
import { UserContext } from "../../context/UserContext";
import { useEffect } from "react";
const Query = () => {
  const { state } = useContext(UserContext); // auth token
  const authToken = state.authToken;
  const department = state.department;
  const [allQueries, setAllQueries] = useState([]); // allQueries has the data of all the querries in the selectedOption (Unresolved,replied).
  const [query, setQuery] = useState([]); // this has the data of single query that has to be displayed on the right side of the layout
  const responseMessage = useRef(); // it will have the text that has been added by the user on the right side of the screen on input dialogue box.
  const [selectedCard, setSelectedCard] = useState(null); // this will have the id of the card clicked on the left panel.
  const [selectedOption, setSelectedOption] = useState("Unresolved"); // this will hava the value of the option selected from the dropdown menu.
  const [selectedDepartmentOption, setSelectedDepartmentOption] =
    useState("Agro"); // this will hava the value of the option selected from the dropdown menu.
  const [previousData, setPreviousData] = useState(null); // this is made for notification purpose, it contains data of previous allQuerries, it compares it with newData based.
  const handleOptionChange = (event) => {
    // this function sets the value of options from dropdown menu ie. unresolved and replied.
    setSelectedOption(event.target.value);
    setQuery([]); // on the selection of value from dropdown it resets the right side.
  };
  const handleDepartmentChange = (event) => {
    console.log(event.target.value);
    setSelectedDepartmentOption(event.target.value);
    setQuery([]);
  };

  useEffect(() => {
    // this useEffect will fetch all the querries of the selected option from dropdown once the value is changed.
    getAllQueries(selectedOption, selectedDepartmentOption);
  }, [selectedOption, selectedDepartmentOption]);

  // this is used for triggering notification once the new data is added to unresolved querries. If previous data is changed this function will be triggered
  const fetchData = async () => {
    try {
      const response = await axios.get(
        `https://fmserver.escorts.co.in/admin/queries/status/Unresolved`,
        {
          // we have checked only for Unresolved because notification has to be generated if new entries are made in Unresolved category.
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      const newData = response.data.queries;
      if (
        previousData !== null &&
        JSON.stringify(newData) !== JSON.stringify(previousData)
      ) {
        // check if newData is equals to previous one or not
        // New data added, trigger alert or notification
        console.log("prevData", previousData);
        console.log("newData", newData);
        alert("New data added!");
      }

      setPreviousData(newData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // const interval = setInterval(fetchData, 5000); // after every 5 seconds the API will be hit if the data is added or not.

  function postResponseHandler(event, id) {
    // this function is responsibe for hitting the API to post the response message against a query.
    event.preventDefault();
    axios
      .post(
        `https://fmserver.escorts.co.in/admin/queries/resolve/${id}`,
        { resolution: responseMessage.current.value },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      )
      .then(function (response) {
        console.log(response);
        setQuery([]); // this will remove the query data from right panel after the send button is hit, because it is not present in rep
        getAllQueries(selectedOption); // this will be called to set new list of querries that now has one query less thn the previous list, because one has been resolved.
      });
    console.log(responseMessage.current.value);
    responseMessage.current.value = "";
  }
  function getAllQueries(selectedOption, selectedDepartmentOption) {
    axios
      .get(
        `https://fmserver.escorts.co.in/admin/queries/status/${selectedOption}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      )
      .then((response) => {
        console.log(response.data.queries);
        if (
          selectedDepartmentOption == "AGRO" ||
          selectedDepartmentOption == "IOT"
        ) {
          const filteredQueries = response.data.queries.filter(
            (query) => query.concerned_team === selectedDepartmentOption
          );
          console.log(filteredQueries);
          setAllQueries(filteredQueries);
        } else {
          setAllQueries(response.data.queries);
        }
        // console.log(allQueries);
      })
      .catch((error) => console.log(error));
  }
  function getQuery(id) {
    axios
      .get(`https://fmserver.escorts.co.in/admin/queries/id/${id}`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then((response) => {
        console.log(response.data);
        setQuery(response.data.query[0]);
        console.log(query);
      })
      .catch((error) => console.log(error));
  }
  function handleQueryCardClick(id) {
    setSelectedCard(id);
    console.log("i am clicked");
    getQuery(id);
  }
  function changeDateFormat(val) {
    const date = new Date(val);
    const new_date = date.toUTCString();
    return new_date;
  }
  const [showFullImage, setShowFullImage] = useState(false);

  const handleImageClick = () => {
    setShowFullImage(true);
  };

  const handleCloseClick = () => {
    setShowFullImage(false);
  };
  return (
    <>
      <Topbar routeTitle={"Queries"} no_content />
      <div className="query_main">
        <div className="query_left_panel">
          <Card>
            <button onClick={fetchData}>Refresh </button>
            {/* <label htmlFor="dropdown">Select an option:</label> */}
            <select
              id="dropdown"
              value={selectedOption}
              onChange={handleOptionChange}
            >
              <option value="Unresolved">Unresolved</option>
              {/* <option value="resolved">Resolved</option> */}
              <option value="Replied">Replied</option>
            </select>
            <select
              id="dropdown"
              value={selectedDepartmentOption}
              onChange={handleDepartmentChange}
            >
              <option value="All">All</option>
              {/* <option value="resolved">Resolved</option> */}
              <option value="AGRO">Agro</option>
              <option value="IOT">IoT</option>
            </select>
            {/* <p>Selected option: {selectedOption}</p> */}
            {allQueries
              .slice()
              .reverse()
              .map(function (el) {
                return (
                  <QueryCard
                    key={el.id}
                    qid={el.id}
                    prompt={el.query}
                    date={el.date}
                    team={el.concerned_team}
                    createdAt={changeDateFormat(el.createdAt)}
                    onClick={() => handleQueryCardClick(el.id)}
                    selected={selectedCard === el.id}
                  />
                );
              })}
          </Card>
        </div>
        <div className="query_right_panel">
          {query.length != 0 ? (
            <Card>
              <div className="query_right_panel_top">
                <div className="farmer_details">
                  <p> Farmer Name: {query?.user_name}</p>
                  <p> Farmer ID: {query?.user_id}</p>
                  <p> Query Id: {query?.id.substring(0, 3)}</p>
                  <p> Query: {query?.query}</p>
                  {selectedOption == "Replied" ? (
                    <p> Resolution: {query?.resolution}</p>
                  ) : (
                    <p></p>
                  )}
                </div>
                <div className="queryImage">
                  <img
                    src={query?.media?.[0]?.url}
                    alt="Query Image"
                    onClick={handleImageClick}
                  ></img>
                  {showFullImage && (
                    <div className="fullImageOverlay">
                      <img
                        src={query?.media?.[0]?.url}
                        alt="Full-size Query Image"
                        className="fullImage"
                      ></img>
                      <span className="closeButton" onClick={handleCloseClick}>
                        X
                      </span>
                    </div>
                  )}
                </div>
              </div>
              {(department == query.concerned_team && selectedOption==="Unresolved") ? (
                <div className="query_right_panel_bottom">
                  <form
                    className="query_submission"
                    onSubmit={(event) => postResponseHandler(event, query?.id)}
                  >
                    <input
                      type="text"
                      ref={responseMessage}
                      className="response_box"
                      placeholder={"Enter your response here"}
                    ></input>
                    <button className="submit_response_button" type="submit">
                      Send
                    </button>
                  </form>
                </div>
              ) : (
                <></>
              )}
            </Card>
          ) : (
            <h1>Select a query to show its full view</h1>
          )}
        </div>
      </div>
    </>
  );
};
export default Query;

/// added comment to chack working on mac
