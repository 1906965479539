import "./notificationCenter.css";
const NotificationBar=({farm_id,farmer_id,notification_body,notification_title})=>{
    return (
        <>
        <div className="notificationBar">
        <h3>{notification_title}</h3>
        <p>{notification_body}</p>
        </div>
        </>
    )
}
export default NotificationBar;