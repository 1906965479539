import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../context/UserContext";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./UserProfile.css";
const UserProfile=()=>{
    const { state,dispatch } = useContext(UserContext);
    const navigate = useNavigate();
    const [user,setUser] =useState([]);
    const authToken = state.authToken;
    async function fetchUser(){
    let res = await axios.get(
        `https://fmserver.escorts.co.in/admin/authenticate/${authToken}`
      );
      console.log(res?.data?.data[0]);
    setUser(res?.data?.data[0]);
    }
    useEffect(()=>{
          fetchUser();
    },[])
    function logout() {
        if (window.confirm("Do you want to log out?")) {
          dispatch({
            type: "STORE_AUTH_TOKEN",
            payload: { authToken: null, department: null },
          });
          localStorage.clear();
          navigate("/login");
          alert("Logged out successfully!");
        }
      }
    return (
        <>
        <div className="main">
            <div className="UserProfile">
                <div className="Name">
                    <p>Name: {user.name}</p>
                </div>
                <div className="email">
                    <p>Email: {user.email}</p>
                </div>
                <div className="employeeID">
                    <p>Eid: {user.eid}</p>
                </div>
                <div className="department">
                    <p>Department: {user.department}</p>
                </div>
            </div>
            <div className="Logout">
                <button onClick={logout}>Logout</button>
            </div>
        </div>
        </>
    )
}
export default UserProfile;