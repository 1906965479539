export let ndvi_data={
    "ndvi": [
      [
        "75.4716423084338",
        "29.436810832525143",
        "0.7473002"
      ],
      [
        "75.47173963810081",
        "29.436810832525143",
        "0.7123016"
      ],
      [
        "75.47183696776783",
        "29.436810832525143",
        "0.7246377"
      ],
      [
        "75.47193429743484",
        "29.436810832525143",
        "0.7062557"
      ],
      [
        "75.47203162710186",
        "29.436810832525143",
        "0.6828479"
      ],
      [
        "75.47212895676887",
        "29.436810832525143",
        "0.6507642"
      ],
      [
        "75.4722262864359",
        "29.436810832525143",
        "0.659164"
      ],
      [
        "75.47232361610291",
        "29.436810832525143",
        "0.659164"
      ],
      [
        "75.47242094576993",
        "29.436810832525143",
        "0.64270663"
      ],
      [
        "75.47251827543694",
        "29.436810832525143",
        "0.625239"
      ],
      [
        "75.47261560510395",
        "29.436810832525143",
        "0.6267943"
      ],
      [
        "75.47271293477097",
        "29.436810832525143",
        "0.6229349"
      ],
      [
        "75.472810264438",
        "29.436810832525143",
        "0.5948995"
      ],
      [
        "75.47290759410501",
        "29.436810832525143",
        "0.60515237"
      ],
      [
        "75.47300492377202",
        "29.436810832525143",
        "0.622335"
      ],
      [
        "75.47310225343904",
        "29.436810832525143",
        "0.6468214"
      ],
      [
        "75.47319958310605",
        "29.436810832525143",
        "0.62729126"
      ],
      [
        "75.47329691277307",
        "29.436810832525143",
        "0.6302187"
      ],
      [
        "75.4733942424401",
        "29.436810832525143",
        "0.63306963"
      ],
      [
        "75.47349157210711",
        "29.436810832525143",
        "0.6356192"
      ],
      [
        "75.47358890177412",
        "29.436810832525143",
        "0.62145436"
      ],
      [
        "75.47368623144114",
        "29.436810832525143",
        "0.5983895"
      ],
      [
        "75.47378356110815",
        "29.436810832525143",
        "0.60193706"
      ],
      [
        "75.47388089077516",
        "29.436810832525143",
        "0.5859782"
      ],
      [
        "75.47397822044218",
        "29.436810832525143",
        "0.52979213"
      ],
      [
        "75.4740755501092",
        "29.436810832525143",
        "0.52979213"
      ],
      [
        "75.47417287977622",
        "29.436810832525143",
        "0.45256916"
      ],
      [
        "75.47427020944323",
        "29.436810832525143",
        "0.5560254"
      ],
      [
        "75.47436753911025",
        "29.436810832525143",
        "0.42816502"
      ],
      [
        "75.47173963810081",
        "29.436713502858126",
        "0.69981915"
      ],
      [
        "75.47183696776783",
        "29.436713502858126",
        "0.77438694"
      ],
      [
        "75.47193429743484",
        "29.436713502858126",
        "0.7719219"
      ],
      [
        "75.47203162710186",
        "29.436713502858126",
        "0.74783796"
      ],
      [
        "75.47212895676887",
        "29.436713502858126",
        "0.72756267"
      ],
      [
        "75.4722262864359",
        "29.436713502858126",
        "0.7569839"
      ],
      [
        "75.47232361610291",
        "29.436713502858126",
        "0.7569839"
      ],
      [
        "75.47242094576993",
        "29.436713502858126",
        "0.74545455"
      ],
      [
        "75.47251827543694",
        "29.436713502858126",
        "0.72422004"
      ],
      [
        "75.47261560510395",
        "29.436713502858126",
        "0.7079731"
      ],
      [
        "75.47271293477097",
        "29.436713502858126",
        "0.6989568"
      ],
      [
        "75.472810264438",
        "29.436713502858126",
        "0.6944444"
      ],
      [
        "75.47290759410501",
        "29.436713502858126",
        "0.69843185"
      ],
      [
        "75.47300492377202",
        "29.436713502858126",
        "0.71163696"
      ],
      [
        "75.47310225343904",
        "29.436713502858126",
        "0.71372354"
      ],
      [
        "75.47319958310605",
        "29.436713502858126",
        "0.71147215"
      ],
      [
        "75.47329691277307",
        "29.436713502858126",
        "0.70719606"
      ],
      [
        "75.4733942424401",
        "29.436713502858126",
        "0.6996997"
      ],
      [
        "75.47349157210711",
        "29.436713502858126",
        "0.6914628"
      ],
      [
        "75.47358890177412",
        "29.436713502858126",
        "0.69965535"
      ],
      [
        "75.47368623144114",
        "29.436713502858126",
        "0.7171864"
      ],
      [
        "75.47378356110815",
        "29.436713502858126",
        "0.7159533"
      ],
      [
        "75.47388089077516",
        "29.436713502858126",
        "0.7190854"
      ],
      [
        "75.47397822044218",
        "29.436713502858126",
        "0.70548254"
      ],
      [
        "75.4740755501092",
        "29.436713502858126",
        "0.70548254"
      ],
      [
        "75.47417287977622",
        "29.436713502858126",
        "0.6431227"
      ],
      [
        "75.47427020944323",
        "29.436713502858126",
        "0.5583483"
      ],
      [
        "75.47436753911025",
        "29.436713502858126",
        "0.4904402"
      ],
      [
        "75.47173963810081",
        "29.43661617319111",
        "0.7976412"
      ],
      [
        "75.47183696776783",
        "29.43661617319111",
        "0.79945797"
      ],
      [
        "75.47193429743484",
        "29.43661617319111",
        "0.79303944"
      ],
      [
        "75.47203162710186",
        "29.43661617319111",
        "0.76346064"
      ],
      [
        "75.47212895676887",
        "29.43661617319111",
        "0.74575496"
      ],
      [
        "75.4722262864359",
        "29.43661617319111",
        "0.740389"
      ],
      [
        "75.47232361610291",
        "29.43661617319111",
        "0.740389"
      ],
      [
        "75.47242094576993",
        "29.43661617319111",
        "0.709434"
      ],
      [
        "75.47251827543694",
        "29.43661617319111",
        "0.7003891"
      ],
      [
        "75.47261560510395",
        "29.43661617319111",
        "0.69902915"
      ],
      [
        "75.47271293477097",
        "29.43661617319111",
        "0.68505114"
      ],
      [
        "75.472810264438",
        "29.43661617319111",
        "0.6962925"
      ],
      [
        "75.47290759410501",
        "29.43661617319111",
        "0.69702876"
      ],
      [
        "75.47300492377202",
        "29.43661617319111",
        "0.716236"
      ],
      [
        "75.47310225343904",
        "29.43661617319111",
        "0.7275618"
      ],
      [
        "75.47319958310605",
        "29.43661617319111",
        "0.72338307"
      ],
      [
        "75.47329691277307",
        "29.43661617319111",
        "0.7103728"
      ],
      [
        "75.4733942424401",
        "29.43661617319111",
        "0.71794873"
      ],
      [
        "75.47349157210711",
        "29.43661617319111",
        "0.7026696"
      ],
      [
        "75.47358890177412",
        "29.43661617319111",
        "0.71146244"
      ],
      [
        "75.47368623144114",
        "29.43661617319111",
        "0.714002"
      ],
      [
        "75.47378356110815",
        "29.43661617319111",
        "0.72247875"
      ],
      [
        "75.47388089077516",
        "29.43661617319111",
        "0.7449168"
      ],
      [
        "75.47397822044218",
        "29.43661617319111",
        "0.75113535"
      ],
      [
        "75.4740755501092",
        "29.43661617319111",
        "0.75113535"
      ],
      [
        "75.47417287977622",
        "29.43661617319111",
        "0.76256114"
      ],
      [
        "75.47427020944323",
        "29.43661617319111",
        "0.7216717"
      ],
      [
        "75.47436753911025",
        "29.43661617319111",
        "0.664994"
      ],
      [
        "75.47183696776783",
        "29.436518843524095",
        "0.8130424"
      ],
      [
        "75.47193429743484",
        "29.436518843524095",
        "0.7848906"
      ],
      [
        "75.47203162710186",
        "29.436518843524095",
        "0.7498846"
      ],
      [
        "75.47212895676887",
        "29.436518843524095",
        "0.7404932"
      ],
      [
        "75.4722262864359",
        "29.436518843524095",
        "0.72436196"
      ],
      [
        "75.47232361610291",
        "29.436518843524095",
        "0.72436196"
      ],
      [
        "75.47242094576993",
        "29.436518843524095",
        "0.6980769"
      ],
      [
        "75.47251827543694",
        "29.436518843524095",
        "0.69721115"
      ],
      [
        "75.47261560510395",
        "29.436518843524095",
        "0.6869653"
      ],
      [
        "75.47271293477097",
        "29.436518843524095",
        "0.6970312"
      ],
      [
        "75.472810264438",
        "29.436518843524095",
        "0.7082146"
      ],
      [
        "75.47290759410501",
        "29.436518843524095",
        "0.70452857"
      ],
      [
        "75.47300492377202",
        "29.436518843524095",
        "0.7089109"
      ],
      [
        "75.47310225343904",
        "29.436518843524095",
        "0.72637796"
      ],
      [
        "75.47319958310605",
        "29.436518843524095",
        "0.73496383"
      ],
      [
        "75.47329691277307",
        "29.436518843524095",
        "0.7234149"
      ],
      [
        "75.4733942424401",
        "29.436518843524095",
        "0.72583824"
      ],
      [
        "75.47349157210711",
        "29.436518843524095",
        "0.72825027"
      ],
      [
        "75.47358890177412",
        "29.436518843524095",
        "0.72758794"
      ],
      [
        "75.47368623144114",
        "29.436518843524095",
        "0.7298938"
      ],
      [
        "75.47378356110815",
        "29.436518843524095",
        "0.73861384"
      ],
      [
        "75.47388089077516",
        "29.436518843524095",
        "0.7388863"
      ],
      [
        "75.47397822044218",
        "29.436518843524095",
        "0.7210179"
      ],
      [
        "75.4740755501092",
        "29.436518843524095",
        "0.7210179"
      ],
      [
        "75.47417287977622",
        "29.436518843524095",
        "0.7717746"
      ],
      [
        "75.47427020944323",
        "29.436518843524095",
        "0.7607573"
      ],
      [
        "75.47436753911025",
        "29.436518843524095",
        "0.70691246"
      ],
      [
        "75.47183696776783",
        "29.436421513857077",
        "0.7916861"
      ],
      [
        "75.47193429743484",
        "29.436421513857077",
        "0.7511155"
      ],
      [
        "75.47203162710186",
        "29.436421513857077",
        "0.73870814"
      ],
      [
        "75.47212895676887",
        "29.436421513857077",
        "0.7604406"
      ],
      [
        "75.4722262864359",
        "29.436421513857077",
        "0.7492326"
      ],
      [
        "75.47232361610291",
        "29.436421513857077",
        "0.7492326"
      ],
      [
        "75.47242094576993",
        "29.436421513857077",
        "0.7179236"
      ],
      [
        "75.47251827543694",
        "29.436421513857077",
        "0.70579493"
      ],
      [
        "75.47261560510395",
        "29.436421513857077",
        "0.70251256"
      ],
      [
        "75.47271293477097",
        "29.436421513857077",
        "0.70394903"
      ],
      [
        "75.472810264438",
        "29.436421513857077",
        "0.69940704"
      ],
      [
        "75.47290759410501",
        "29.436421513857077",
        "0.68853277"
      ],
      [
        "75.47300492377202",
        "29.436421513857077",
        "0.6944724"
      ],
      [
        "75.47310225343904",
        "29.436421513857077",
        "0.7157157"
      ],
      [
        "75.47319958310605",
        "29.436421513857077",
        "0.7209068"
      ],
      [
        "75.47329691277307",
        "29.436421513857077",
        "0.71986973"
      ],
      [
        "75.4733942424401",
        "29.436421513857077",
        "0.73536646"
      ],
      [
        "75.47349157210711",
        "29.436421513857077",
        "0.7284933"
      ],
      [
        "75.47358890177412",
        "29.436421513857077",
        "0.7124216"
      ],
      [
        "75.47368623144114",
        "29.436421513857077",
        "0.7222645"
      ],
      [
        "75.47378356110815",
        "29.436421513857077",
        "0.71697646"
      ],
      [
        "75.47388089077516",
        "29.436421513857077",
        "0.72057414"
      ],
      [
        "75.47397822044218",
        "29.436421513857077",
        "0.7113109"
      ],
      [
        "75.4740755501092",
        "29.436421513857077",
        "0.7113109"
      ],
      [
        "75.47417287977622",
        "29.436421513857077",
        "0.752809"
      ],
      [
        "75.47427020944323",
        "29.436421513857077",
        "0.7359125"
      ],
      [
        "75.47436753911025",
        "29.436421513857077",
        "0.69578385"
      ],
      [
        "75.47193429743484",
        "29.43632418419006",
        "0.7051705"
      ],
      [
        "75.47203162710186",
        "29.43632418419006",
        "0.74420947"
      ],
      [
        "75.47212895676887",
        "29.43632418419006",
        "0.75879866"
      ],
      [
        "75.4722262864359",
        "29.43632418419006",
        "0.7370669"
      ],
      [
        "75.47232361610291",
        "29.43632418419006",
        "0.7228974"
      ],
      [
        "75.47242094576993",
        "29.43632418419006",
        "0.7228974"
      ],
      [
        "75.47251827543694",
        "29.43632418419006",
        "0.71814674"
      ],
      [
        "75.47261560510395",
        "29.43632418419006",
        "0.7022938"
      ],
      [
        "75.47271293477097",
        "29.43632418419006",
        "0.6909272"
      ],
      [
        "75.472810264438",
        "29.43632418419006",
        "0.67851776"
      ],
      [
        "75.47290759410501",
        "29.43632418419006",
        "0.67516243"
      ],
      [
        "75.47300492377202",
        "29.43632418419006",
        "0.6933868"
      ],
      [
        "75.47310225343904",
        "29.43632418419006",
        "0.6905358"
      ],
      [
        "75.47319958310605",
        "29.43632418419006",
        "0.6965483"
      ],
      [
        "75.47329691277307",
        "29.43632418419006",
        "0.71526927"
      ],
      [
        "75.4733942424401",
        "29.43632418419006",
        "0.71635664"
      ],
      [
        "75.47349157210711",
        "29.43632418419006",
        "0.6988417"
      ],
      [
        "75.47358890177412",
        "29.43632418419006",
        "0.69849247"
      ],
      [
        "75.47368623144114",
        "29.43632418419006",
        "0.7045908"
      ],
      [
        "75.47378356110815",
        "29.43632418419006",
        "0.66486484"
      ],
      [
        "75.47388089077516",
        "29.43632418419006",
        "0.65234375"
      ],
      [
        "75.47397822044218",
        "29.43632418419006",
        "0.67141503"
      ],
      [
        "75.4740755501092",
        "29.43632418419006",
        "0.67141503"
      ],
      [
        "75.47417287977622",
        "29.43632418419006",
        "0.7297297"
      ],
      [
        "75.47427020944323",
        "29.43632418419006",
        "0.7130397"
      ],
      [
        "75.47436753911025",
        "29.43632418419006",
        "0.6435401"
      ],
      [
        "75.47203162710186",
        "29.436226854523046",
        "0.7173851"
      ],
      [
        "75.47212895676887",
        "29.436226854523046",
        "0.6998972"
      ],
      [
        "75.4722262864359",
        "29.436226854523046",
        "0.65236366"
      ],
      [
        "75.47232361610291",
        "29.436226854523046",
        "0.62959355"
      ],
      [
        "75.47242094576993",
        "29.436226854523046",
        "0.62959355"
      ],
      [
        "75.47251827543694",
        "29.436226854523046",
        "0.60794044"
      ],
      [
        "75.47261560510395",
        "29.436226854523046",
        "0.59683794"
      ],
      [
        "75.47271293477097",
        "29.436226854523046",
        "0.62830096"
      ],
      [
        "75.472810264438",
        "29.436226854523046",
        "0.622"
      ],
      [
        "75.47290759410501",
        "29.436226854523046",
        "0.6124498"
      ],
      [
        "75.47300492377202",
        "29.436226854523046",
        "0.6209797"
      ],
      [
        "75.47310225343904",
        "29.436226854523046",
        "0.6426083"
      ],
      [
        "75.47319958310605",
        "29.436226854523046",
        "0.6573392"
      ],
      [
        "75.47329691277307",
        "29.436226854523046",
        "0.6818837"
      ],
      [
        "75.4733942424401",
        "29.436226854523046",
        "0.69114876"
      ],
      [
        "75.47349157210711",
        "29.436226854523046",
        "0.67774934"
      ],
      [
        "75.47358890177412",
        "29.436226854523046",
        "0.65303653"
      ],
      [
        "75.47368623144114",
        "29.436226854523046",
        "0.642615"
      ],
      [
        "75.47378356110815",
        "29.436226854523046",
        "0.61714566"
      ],
      [
        "75.47388089077516",
        "29.436226854523046",
        "0.6325345"
      ],
      [
        "75.47397822044218",
        "29.436226854523046",
        "0.6506426"
      ],
      [
        "75.4740755501092",
        "29.436226854523046",
        "0.6506426"
      ],
      [
        "75.47417287977622",
        "29.436226854523046",
        "0.686636"
      ],
      [
        "75.47427020944323",
        "29.436226854523046",
        "0.66424"
      ],
      [
        "75.47436753911025",
        "29.436226854523046",
        "0.5813733"
      ],
      [
        "75.47212895676887",
        "29.436129524856028",
        "0.78095764"
      ],
      [
        "75.4722262864359",
        "29.436129524856028",
        "0.6726"
      ],
      [
        "75.47232361610291",
        "29.436129524856028",
        "0.5910042"
      ],
      [
        "75.47242094576993",
        "29.436129524856028",
        "0.5910042"
      ],
      [
        "75.47251827543694",
        "29.436129524856028",
        "0.54338586"
      ],
      [
        "75.47261560510395",
        "29.436129524856028",
        "0.5052579"
      ],
      [
        "75.47271293477097",
        "29.436129524856028",
        "0.510427"
      ],
      [
        "75.472810264438",
        "29.436129524856028",
        "0.54923856"
      ],
      [
        "75.47290759410501",
        "29.436129524856028",
        "0.57831323"
      ],
      [
        "75.47300492377202",
        "29.436129524856028",
        "0.6031825"
      ],
      [
        "75.47310225343904",
        "29.436129524856028",
        "0.6115538"
      ],
      [
        "75.47319958310605",
        "29.436129524856028",
        "0.6430708"
      ],
      [
        "75.47329691277307",
        "29.436129524856028",
        "0.68039215"
      ],
      [
        "75.4733942424401",
        "29.436129524856028",
        "0.70446736"
      ],
      [
        "75.47349157210711",
        "29.436129524856028",
        "0.7083135"
      ],
      [
        "75.47358890177412",
        "29.436129524856028",
        "0.6913401"
      ],
      [
        "75.47368623144114",
        "29.436129524856028",
        "0.6815287"
      ],
      [
        "75.47378356110815",
        "29.436129524856028",
        "0.68"
      ],
      [
        "75.47388089077516",
        "29.436129524856028",
        "0.704825"
      ],
      [
        "75.47397822044218",
        "29.436129524856028",
        "0.73259056"
      ],
      [
        "75.4740755501092",
        "29.436129524856028",
        "0.7222222"
      ],
      [
        "75.47417287977622",
        "29.436129524856028",
        "0.7222222"
      ],
      [
        "75.47427020944323",
        "29.436129524856028",
        "0.68856883"
      ],
      [
        "75.47436753911025",
        "29.436129524856028",
        "0.59129614"
      ],
      [
        "75.4722262864359",
        "29.43603219518901",
        "0.7901786"
      ],
      [
        "75.47232361610291",
        "29.43603219518901",
        "0.6819172"
      ],
      [
        "75.47242094576993",
        "29.43603219518901",
        "0.6819172"
      ],
      [
        "75.47251827543694",
        "29.43603219518901",
        "0.5793014"
      ],
      [
        "75.47261560510395",
        "29.43603219518901",
        "0.5204795"
      ],
      [
        "75.47271293477097",
        "29.43603219518901",
        "0.4902743"
      ],
      [
        "75.472810264438",
        "29.43603219518901",
        "0.5125285"
      ],
      [
        "75.47290759410501",
        "29.43603219518901",
        "0.5110887"
      ],
      [
        "75.47300492377202",
        "29.43603219518901",
        "0.51138085"
      ],
      [
        "75.47310225343904",
        "29.43603219518901",
        "0.5618951"
      ],
      [
        "75.47319958310605",
        "29.43603219518901",
        "0.6071964"
      ],
      [
        "75.47329691277307",
        "29.43603219518901",
        "0.65023303"
      ],
      [
        "75.4733942424401",
        "29.43603219518901",
        "0.66167444"
      ],
      [
        "75.47349157210711",
        "29.43603219518901",
        "0.6275184"
      ],
      [
        "75.47358890177412",
        "29.43603219518901",
        "0.6061146"
      ],
      [
        "75.47368623144114",
        "29.43603219518901",
        "0.6123876"
      ],
      [
        "75.47378356110815",
        "29.43603219518901",
        "0.635789"
      ],
      [
        "75.47388089077516",
        "29.43603219518901",
        "0.66193044"
      ],
      [
        "75.47397822044218",
        "29.43603219518901",
        "0.70735186"
      ],
      [
        "75.4740755501092",
        "29.43603219518901",
        "0.72168905"
      ],
      [
        "75.47417287977622",
        "29.43603219518901",
        "0.72168905"
      ],
      [
        "75.47427020944323",
        "29.43603219518901",
        "0.7118959"
      ],
      [
        "75.47436753911025",
        "29.43603219518901",
        "0.5805243"
      ],
      [
        "75.4722262864359",
        "29.435934865521997",
        "0.83662575"
      ],
      [
        "75.47232361610291",
        "29.435934865521997",
        "0.77203816"
      ],
      [
        "75.47242094576993",
        "29.435934865521997",
        "0.77203816"
      ],
      [
        "75.47251827543694",
        "29.435934865521997",
        "0.66823655"
      ],
      [
        "75.47261560510395",
        "29.435934865521997",
        "0.5786021"
      ],
      [
        "75.47271293477097",
        "29.435934865521997",
        "0.5"
      ],
      [
        "75.472810264438",
        "29.435934865521997",
        "0.47219414"
      ],
      [
        "75.47290759410501",
        "29.435934865521997",
        "0.46437594"
      ],
      [
        "75.47300492377202",
        "29.435934865521997",
        "0.48634985"
      ],
      [
        "75.47310225343904",
        "29.435934865521997",
        "0.5434343"
      ],
      [
        "75.47319958310605",
        "29.435934865521997",
        "0.5769813"
      ],
      [
        "75.47329691277307",
        "29.435934865521997",
        "0.59366494"
      ],
      [
        "75.4733942424401",
        "29.435934865521997",
        "0.59802955"
      ],
      [
        "75.47349157210711",
        "29.435934865521997",
        "0.58592963"
      ],
      [
        "75.47358890177412",
        "29.435934865521997",
        "0.5718593"
      ],
      [
        "75.47368623144114",
        "29.435934865521997",
        "0.5762009"
      ],
      [
        "75.47378356110815",
        "29.435934865521997",
        "0.59722567"
      ],
      [
        "75.47388089077516",
        "29.435934865521997",
        "0.62621"
      ],
      [
        "75.47397822044218",
        "29.435934865521997",
        "0.6636993"
      ],
      [
        "75.4740755501092",
        "29.435934865521997",
        "0.6833863"
      ],
      [
        "75.47417287977622",
        "29.435934865521997",
        "0.6833863"
      ],
      [
        "75.47427020944323",
        "29.435934865521997",
        "0.6649064"
      ],
      [
        "75.47436753911025",
        "29.435934865521997",
        "0.5868726"
      ],
      [
        "75.47232361610291",
        "29.43583753585498",
        "0.7943527"
      ],
      [
        "75.47242094576993",
        "29.43583753585498",
        "0.7943527"
      ],
      [
        "75.47251827543694",
        "29.43583753585498",
        "0.7547893"
      ],
      [
        "75.47261560510395",
        "29.43583753585498",
        "0.67881775"
      ],
      [
        "75.47271293477097",
        "29.43583753585498",
        "0.6073432"
      ],
      [
        "75.472810264438",
        "29.43583753585498",
        "0.5613497"
      ],
      [
        "75.47290759410501",
        "29.43583753585498",
        "0.5361951"
      ],
      [
        "75.47300492377202",
        "29.43583753585498",
        "0.548718"
      ],
      [
        "75.47310225343904",
        "29.43583753585498",
        "0.55826974"
      ],
      [
        "75.47319958310605",
        "29.43583753585498",
        "0.59795916"
      ],
      [
        "75.47329691277307",
        "29.43583753585498",
        "0.6095976"
      ],
      [
        "75.4733942424401",
        "29.43583753585498",
        "0.59289414"
      ],
      [
        "75.47349157210711",
        "29.43583753585498",
        "0.57367086"
      ],
      [
        "75.47358890177412",
        "29.43583753585498",
        "0.56934863"
      ],
      [
        "75.47368623144114",
        "29.43583753585498",
        "0.57118183"
      ],
      [
        "75.47378356110815",
        "29.43583753585498",
        "0.60678643"
      ],
      [
        "75.47388089077516",
        "29.43583753585498",
        "0.6168717"
      ],
      [
        "75.47397822044218",
        "29.43583753585498",
        "0.6450478"
      ],
      [
        "75.4740755501092",
        "29.43583753585498",
        "0.66551554"
      ],
      [
        "75.47417287977622",
        "29.43583753585498",
        "0.66551554"
      ],
      [
        "75.47427020944323",
        "29.43583753585498",
        "0.65551674"
      ],
      [
        "75.47436753911025",
        "29.43583753585498",
        "0.6295536"
      ],
      [
        "75.47232361610291",
        "29.435740206187965",
        "0.8292821"
      ],
      [
        "75.47242094576993",
        "29.435740206187965",
        "0.8292821"
      ],
      [
        "75.47251827543694",
        "29.435740206187965",
        "0.80058086"
      ],
      [
        "75.47261560510395",
        "29.435740206187965",
        "0.76656777"
      ],
      [
        "75.47271293477097",
        "29.435740206187965",
        "0.73717636"
      ],
      [
        "75.472810264438",
        "29.435740206187965",
        "0.682"
      ],
      [
        "75.47290759410501",
        "29.435740206187965",
        "0.64237076"
      ],
      [
        "75.47300492377202",
        "29.435740206187965",
        "0.665641"
      ],
      [
        "75.47310225343904",
        "29.435740206187965",
        "0.6893543"
      ],
      [
        "75.47319958310605",
        "29.435740206187965",
        "0.70469475"
      ],
      [
        "75.47329691277307",
        "29.435740206187965",
        "0.70300937"
      ],
      [
        "75.4733942424401",
        "29.435740206187965",
        "0.69816923"
      ],
      [
        "75.47349157210711",
        "29.435740206187965",
        "0.682243"
      ],
      [
        "75.47358890177412",
        "29.435740206187965",
        "0.6065406"
      ],
      [
        "75.47368623144114",
        "29.435740206187965",
        "0.57846624"
      ],
      [
        "75.47378356110815",
        "29.435740206187965",
        "0.6286079"
      ],
      [
        "75.47388089077516",
        "29.435740206187965",
        "0.66191447"
      ],
      [
        "75.47397822044218",
        "29.435740206187965",
        "0.67754894"
      ],
      [
        "75.4740755501092",
        "29.435740206187965",
        "0.67901236"
      ],
      [
        "75.47417287977622",
        "29.435740206187965",
        "0.67901236"
      ],
      [
        "75.47427020944323",
        "29.435740206187965",
        "0.6767283"
      ],
      [
        "75.47436753911025",
        "29.435740206187965",
        "0.64097464"
      ],
      [
        "75.47251827543694",
        "29.435642876520948",
        "0.81608564"
      ],
      [
        "75.47261560510395",
        "29.435642876520948",
        "0.81299335"
      ],
      [
        "75.47271293477097",
        "29.435642876520948",
        "0.7691501"
      ],
      [
        "75.472810264438",
        "29.435642876520948",
        "0.70071024"
      ],
      [
        "75.47290759410501",
        "29.435642876520948",
        "0.66004413"
      ],
      [
        "75.47300492377202",
        "29.435642876520948",
        "0.6855594"
      ],
      [
        "75.47310225343904",
        "29.435642876520948",
        "0.73093736"
      ],
      [
        "75.47319958310605",
        "29.435642876520948",
        "0.7504836"
      ],
      [
        "75.47329691277307",
        "29.435642876520948",
        "0.7419355"
      ],
      [
        "75.4733942424401",
        "29.435642876520948",
        "0.7266897"
      ],
      [
        "75.47349157210711",
        "29.435642876520948",
        "0.73346496"
      ],
      [
        "75.47358890177412",
        "29.435642876520948",
        "0.65834165"
      ],
      [
        "75.47368623144114",
        "29.435642876520948",
        "0.60121"
      ],
      [
        "75.47378356110815",
        "29.435642876520948",
        "0.6660755"
      ],
      [
        "75.47388089077516",
        "29.435642876520948",
        "0.6624685"
      ],
      [
        "75.47397822044218",
        "29.435642876520948",
        "0.68473643"
      ],
      [
        "75.4740755501092",
        "29.435642876520948",
        "0.7147125"
      ],
      [
        "75.47417287977622",
        "29.435642876520948",
        "0.7147125"
      ],
      [
        "75.47427020944323",
        "29.435642876520948",
        "0.713456"
      ],
      [
        "75.47436753911025",
        "29.435642876520948",
        "0.6263949"
      ],
      [
        "75.47261560510395",
        "29.43554554685393",
        "0.8277997"
      ],
      [
        "75.47271293477097",
        "29.43554554685393",
        "0.7997978"
      ],
      [
        "75.472810264438",
        "29.43554554685393",
        "0.7413548"
      ],
      [
        "75.47290759410501",
        "29.43554554685393",
        "0.7198825"
      ],
      [
        "75.47300492377202",
        "29.43554554685393",
        "0.7525116"
      ],
      [
        "75.47310225343904",
        "29.43554554685393",
        "0.7498213"
      ],
      [
        "75.47319958310605",
        "29.43554554685393",
        "0.75732625"
      ],
      [
        "75.47329691277307",
        "29.43554554685393",
        "0.75115657"
      ],
      [
        "75.4733942424401",
        "29.43554554685393",
        "0.72560674"
      ],
      [
        "75.47349157210711",
        "29.43554554685393",
        "0.7432662"
      ],
      [
        "75.47358890177412",
        "29.43554554685393",
        "0.69149446"
      ],
      [
        "75.47368623144114",
        "29.43554554685393",
        "0.60816324"
      ],
      [
        "75.47378356110815",
        "29.43554554685393",
        "0.653092"
      ],
      [
        "75.47388089077516",
        "29.43554554685393",
        "0.6591252"
      ],
      [
        "75.47397822044218",
        "29.43554554685393",
        "0.6802635"
      ],
      [
        "75.4740755501092",
        "29.43554554685393",
        "0.7015228"
      ],
      [
        "75.47417287977622",
        "29.43554554685393",
        "0.7015228"
      ],
      [
        "75.47427020944323",
        "29.43554554685393",
        "0.67735666"
      ],
      [
        "75.47436753911025",
        "29.43554554685393",
        "0.6360116"
      ],
      [
        "75.47261560510395",
        "29.435448217186917",
        "0.8105807"
      ],
      [
        "75.47271293477097",
        "29.435448217186917",
        "0.82051283"
      ],
      [
        "75.472810264438",
        "29.435448217186917",
        "0.77508825"
      ],
      [
        "75.47290759410501",
        "29.435448217186917",
        "0.748676"
      ],
      [
        "75.47300492377202",
        "29.435448217186917",
        "0.7714422"
      ],
      [
        "75.47310225343904",
        "29.435448217186917",
        "0.76345104"
      ],
      [
        "75.47319958310605",
        "29.435448217186917",
        "0.75788206"
      ],
      [
        "75.47329691277307",
        "29.435448217186917",
        "0.73540854"
      ],
      [
        "75.4733942424401",
        "29.435448217186917",
        "0.71159095"
      ],
      [
        "75.47349157210711",
        "29.435448217186917",
        "0.7380254"
      ],
      [
        "75.47358890177412",
        "29.435448217186917",
        "0.6973812"
      ],
      [
        "75.47368623144114",
        "29.435448217186917",
        "0.64852256"
      ],
      [
        "75.47378356110815",
        "29.435448217186917",
        "0.6864977"
      ],
      [
        "75.47388089077516",
        "29.435448217186917",
        "0.68174887"
      ],
      [
        "75.47397822044218",
        "29.435448217186917",
        "0.66700506"
      ],
      [
        "75.4740755501092",
        "29.435448217186917",
        "0.6756482"
      ],
      [
        "75.47417287977622",
        "29.435448217186917",
        "0.6756482"
      ],
      [
        "75.47427020944323",
        "29.435448217186917",
        "0.6873449"
      ],
      [
        "75.47436753911025",
        "29.435448217186917",
        "0.6514482"
      ],
      [
        "75.47271293477097",
        "29.4353508875199",
        "0.8300349"
      ],
      [
        "75.472810264438",
        "29.4353508875199",
        "0.8057083"
      ],
      [
        "75.47290759410501",
        "29.4353508875199",
        "0.7943396"
      ],
      [
        "75.47300492377202",
        "29.4353508875199",
        "0.78089887"
      ],
      [
        "75.47310225343904",
        "29.4353508875199",
        "0.7697944"
      ],
      [
        "75.47319958310605",
        "29.4353508875199",
        "0.75950277"
      ],
      [
        "75.47329691277307",
        "29.4353508875199",
        "0.72262776"
      ],
      [
        "75.4733942424401",
        "29.4353508875199",
        "0.68608975"
      ],
      [
        "75.47349157210711",
        "29.4353508875199",
        "0.7328664"
      ],
      [
        "75.47358890177412",
        "29.4353508875199",
        "0.69482976"
      ],
      [
        "75.47368623144114",
        "29.4353508875199",
        "0.6336686"
      ],
      [
        "75.47378356110815",
        "29.4353508875199",
        "0.6783505"
      ],
      [
        "75.47388089077516",
        "29.4353508875199",
        "0.70169663"
      ],
      [
        "75.47397822044218",
        "29.4353508875199",
        "0.6925822"
      ],
      [
        "75.4740755501092",
        "29.4353508875199",
        "0.70795774"
      ],
      [
        "75.47417287977622",
        "29.4353508875199",
        "0.70795774"
      ],
      [
        "75.47427020944323",
        "29.4353508875199",
        "0.6831683"
      ],
      [
        "75.47436753911025",
        "29.4353508875199",
        "0.6380579"
      ],
      [
        "75.472810264438",
        "29.43525355785288",
        "0.8148855"
      ],
      [
        "75.47290759410501",
        "29.43525355785288",
        "0.80487806"
      ],
      [
        "75.47300492377202",
        "29.43525355785288",
        "0.78751165"
      ],
      [
        "75.47310225343904",
        "29.43525355785288",
        "0.77109283"
      ],
      [
        "75.47319958310605",
        "29.43525355785288",
        "0.7567503"
      ],
      [
        "75.47329691277307",
        "29.43525355785288",
        "0.7279684"
      ],
      [
        "75.4733942424401",
        "29.43525355785288",
        "0.70294046"
      ],
      [
        "75.47349157210711",
        "29.43525355785288",
        "0.7245869"
      ],
      [
        "75.47358890177412",
        "29.43525355785288",
        "0.7000498"
      ],
      [
        "75.47368623144114",
        "29.43525355785288",
        "0.6243865"
      ],
      [
        "75.47378356110815",
        "29.43525355785288",
        "0.6594749"
      ],
      [
        "75.47388089077516",
        "29.43525355785288",
        "0.709784"
      ],
      [
        "75.47397822044218",
        "29.43525355785288",
        "0.7085427"
      ],
      [
        "75.4740755501092",
        "29.43525355785288",
        "0.7113924"
      ],
      [
        "75.47417287977622",
        "29.43525355785288",
        "0.7113924"
      ],
      [
        "75.47427020944323",
        "29.43525355785288",
        "0.7051153"
      ],
      [
        "75.47436753911025",
        "29.43525355785288",
        "0.6343874"
      ],
      [
        "75.472810264438",
        "29.435156228185868",
        "0.8203929"
      ],
      [
        "75.47290759410501",
        "29.435156228185868",
        "0.8066881"
      ],
      [
        "75.47300492377202",
        "29.435156228185868",
        "0.78318894"
      ],
      [
        "75.47310225343904",
        "29.435156228185868",
        "0.7653846"
      ],
      [
        "75.47319958310605",
        "29.435156228185868",
        "0.74951077"
      ],
      [
        "75.47329691277307",
        "29.435156228185868",
        "0.6952191"
      ],
      [
        "75.4733942424401",
        "29.435156228185868",
        "0.6860347"
      ],
      [
        "75.47349157210711",
        "29.435156228185868",
        "0.7125567"
      ],
      [
        "75.47358890177412",
        "29.435156228185868",
        "0.694"
      ],
      [
        "75.47368623144114",
        "29.435156228185868",
        "0.65203923"
      ],
      [
        "75.47378356110815",
        "29.435156228185868",
        "0.67575914"
      ],
      [
        "75.47388089077516",
        "29.435156228185868",
        "0.7060168"
      ],
      [
        "75.47397822044218",
        "29.435156228185868",
        "0.7125628"
      ],
      [
        "75.4740755501092",
        "29.435156228185868",
        "0.7173091"
      ],
      [
        "75.47417287977622",
        "29.435156228185868",
        "0.7173091"
      ],
      [
        "75.47427020944323",
        "29.435156228185868",
        "0.70081866"
      ],
      [
        "75.47436753911025",
        "29.435156228185868",
        "0.6399217"
      ],
      [
        "75.47290759410501",
        "29.43505889851885",
        "0.8132168"
      ],
      [
        "75.47300492377202",
        "29.43505889851885",
        "0.7827639"
      ],
      [
        "75.47310225343904",
        "29.43505889851885",
        "0.77309716"
      ],
      [
        "75.47319958310605",
        "29.43505889851885",
        "0.75247526"
      ],
      [
        "75.47329691277307",
        "29.43505889851885",
        "0.6967871"
      ],
      [
        "75.4733942424401",
        "29.43505889851885",
        "0.6965944"
      ],
      [
        "75.47349157210711",
        "29.43505889851885",
        "0.7273652"
      ],
      [
        "75.47358890177412",
        "29.43505889851885",
        "0.6963563"
      ],
      [
        "75.47368623144114",
        "29.43505889851885",
        "0.6652893"
      ],
      [
        "75.47378356110815",
        "29.43505889851885",
        "0.68988305"
      ],
      [
        "75.47388089077516",
        "29.43505889851885",
        "0.71197575"
      ],
      [
        "75.47397822044218",
        "29.43505889851885",
        "0.71342385"
      ],
      [
        "75.4740755501092",
        "29.43505889851885",
        "0.6959837"
      ],
      [
        "75.47417287977622",
        "29.43505889851885",
        "0.6959837"
      ],
      [
        "75.47427020944323",
        "29.43505889851885",
        "0.6886286"
      ],
      [
        "75.47436753911025",
        "29.43505889851885",
        "0.6459144"
      ],
      [
        "75.47290759410501",
        "29.434961568851833",
        "0.79092085"
      ],
      [
        "75.47300492377202",
        "29.434961568851833",
        "0.79257363"
      ],
      [
        "75.47310225343904",
        "29.434961568851833",
        "0.7718249"
      ],
      [
        "75.47319958310605",
        "29.434961568851833",
        "0.7472155"
      ],
      [
        "75.47329691277307",
        "29.434961568851833",
        "0.73981345"
      ],
      [
        "75.4733942424401",
        "29.434961568851833",
        "0.7343905"
      ],
      [
        "75.47349157210711",
        "29.434961568851833",
        "0.74384236"
      ],
      [
        "75.47358890177412",
        "29.434961568851833",
        "0.71915746"
      ],
      [
        "75.47368623144114",
        "29.434961568851833",
        "0.6782787"
      ],
      [
        "75.47378356110815",
        "29.434961568851833",
        "0.685816"
      ],
      [
        "75.47388089077516",
        "29.434961568851833",
        "0.70344484"
      ],
      [
        "75.47397822044218",
        "29.434961568851833",
        "0.69769174"
      ],
      [
        "75.4740755501092",
        "29.434961568851833",
        "0.67901236"
      ],
      [
        "75.47417287977622",
        "29.434961568851833",
        "0.6866041"
      ],
      [
        "75.47427020944323",
        "29.434961568851833",
        "0.6866041"
      ],
      [
        "75.47436753911025",
        "29.434961568851833",
        "0.6231047"
      ],
      [
        "75.47310225343904",
        "29.43486423918482",
        "0.80735105"
      ],
      [
        "75.47319958310605",
        "29.43486423918482",
        "0.762262"
      ],
      [
        "75.47329691277307",
        "29.43486423918482",
        "0.7549407"
      ],
      [
        "75.4733942424401",
        "29.43486423918482",
        "0.744243"
      ],
      [
        "75.47349157210711",
        "29.43486423918482",
        "0.74037087"
      ],
      [
        "75.47358890177412",
        "29.43486423918482",
        "0.7339139"
      ],
      [
        "75.47368623144114",
        "29.43486423918482",
        "0.69721115"
      ],
      [
        "75.47378356110815",
        "29.43486423918482",
        "0.66752976"
      ],
      [
        "75.47388089077516",
        "29.43486423918482",
        "0.70336854"
      ],
      [
        "75.47397822044218",
        "29.43486423918482",
        "0.70632154"
      ],
      [
        "75.4740755501092",
        "29.43486423918482",
        "0.7127318"
      ],
      [
        "75.47417287977622",
        "29.43486423918482",
        "0.68582374"
      ],
      [
        "75.47427020944323",
        "29.43486423918482",
        "0.68582374"
      ],
      [
        "75.47436753911025",
        "29.43486423918482",
        "0.597309"
      ],
      [
        "75.47310225343904",
        "29.4347669095178",
        "0.8214732"
      ],
      [
        "75.47319958310605",
        "29.4347669095178",
        "0.7832884"
      ],
      [
        "75.47329691277307",
        "29.4347669095178",
        "0.72526956"
      ],
      [
        "75.4733942424401",
        "29.4347669095178",
        "0.7035249"
      ],
      [
        "75.47349157210711",
        "29.4347669095178",
        "0.7019276"
      ],
      [
        "75.47358890177412",
        "29.4347669095178",
        "0.6618449"
      ],
      [
        "75.47368623144114",
        "29.4347669095178",
        "0.6270205"
      ],
      [
        "75.47378356110815",
        "29.4347669095178",
        "0.61306286"
      ],
      [
        "75.47388089077516",
        "29.4347669095178",
        "0.64181995"
      ],
      [
        "75.47397822044218",
        "29.4347669095178",
        "0.6314312"
      ],
      [
        "75.4740755501092",
        "29.4347669095178",
        "0.61048687"
      ],
      [
        "75.47417287977622",
        "29.4347669095178",
        "0.60946745"
      ],
      [
        "75.47427020944323",
        "29.4347669095178",
        "0.60946745"
      ],
      [
        "75.47436753911025",
        "29.4347669095178",
        "0.56775135"
      ],
      [
        "75.47319958310605",
        "29.434669579850784",
        "0.7822785"
      ],
      [
        "75.47329691277307",
        "29.434669579850784",
        "0.6400367"
      ],
      [
        "75.4733942424401",
        "29.434669579850784",
        "0.5777568"
      ],
      [
        "75.47349157210711",
        "29.434669579850784",
        "0.57182574"
      ],
      [
        "75.47358890177412",
        "29.434669579850784",
        "0.55919516"
      ],
      [
        "75.47368623144114",
        "29.434669579850784",
        "0.5298578"
      ],
      [
        "75.47378356110815",
        "29.434669579850784",
        "0.48141176"
      ],
      [
        "75.47388089077516",
        "29.434669579850784",
        "0.49196142"
      ],
      [
        "75.47397822044218",
        "29.434669579850784",
        "0.52416015"
      ],
      [
        "75.4740755501092",
        "29.434669579850784",
        "0.50115263"
      ],
      [
        "75.47417287977622",
        "29.434669579850784",
        "0.48731884"
      ],
      [
        "75.47427020944323",
        "29.434669579850784",
        "0.48731884"
      ],
      [
        "75.47436753911025",
        "29.434669579850784",
        "0.53064007"
      ]
    ]
  }