import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { useContext, useEffect, useState, useRef } from "react";
import { UserContext } from "../../context/UserContext";
import axios from "axios";
import "./RTWS_SHEET.css";
import { CSVLink } from "react-csv";
import Topbar from "../../components/topbar/Topbar";
import Navbar from "../../components/navbar/Navbar";

export default function RTWS_SHEET()
 {
  const { state } = useContext(UserContext);
  const authToken = state.authToken;
  const startDate = useRef();
  const endDate = useRef();
  const farmID = useRef();
  const [csvLinkData, setCsvLinkData] = useState("");
  const [RTWS, setRTWS] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(1000);
  const [loading, setLoading] = useState(true);
  
  const headers = [
    { label: "id", key: "id" },
    { label: "farmId", key: "farmId" },
    { label: "NDVI", key: "NDVI" },
    { label: "avgTemp", key: "avgTemp" },
    { label: "avgRelHum", key: "avgRelHum" },
    { label: "avgSoilTemp", key: "avgSoilTemp" },
    { label: "avgRain", key: "avgRain" },
    { label: "disease", key: "disease" },
    { label: "tempMin", key: "tempMin" },
    { label: "tempMax", key: "tempMax" },
    { label: "relHumMin", key: "relHumMin" },
    { label: "relHumMax", key: "relHumMax" },
    { label: "soilTempMin", key: "soilTempMin" },
    { label: "soilTempMax", key: "soilTempMax" },
    { label: "rainFallMin", key: "rainFallMin" },
    { label: "rainFallMax", key: "rainFallMax" },
    { label: "alert", key: "alert" },
    { label: "createdAt", key: "createdAt" },
    { label: "updatedAt", key: "updatedAt" },
    { label: "avg_NDVI", key: "avg_NDVI" },
    { label: "min_NDVI", key: "min_NDVI" },
    { label: "max_NDVI", key: "max_NDVI" },
    { label: "total_pixel", key: "total_pixel" },
    { label: "pixel_above_threshold", key: "pixel_above_threshold" },
    { label: "pixel_below_threshold", key: "pixel_below_threshold" },
    { label: "pixel_between_threshold", key: "pixel_between_threshold" },
    { label: "date_of_NDVI_data", key: "date_of_NDVI_data" },
  ];
  const columns = [
    { id: "id", label: "id", Width: 10 },
    { id: "farmId", label: "farmId", Width: 10 },
    { id: "NDVI", label: "NDVI", Width: 10 },
    { id: "avg_NDVI", label: "avg_NDVI", Width: 10 },
    { id: "min_NDVI", label: "min_NDVI", Width: 10 },
    { id: "max_NDVI", label: "max_NDVI", Width: 10 },
    { id: "total_pixel", label: "total_pixel", Width: 10 },
    { id: "pixel_above_threshold", label: "pixel_above_threshold", Width: 10 },
    { id: "pixel_below_threshold", label: "pixel_below_threshold", Width: 10 },
    {
      id: "pixel_between_threshold",
      label: "pixel_between_threshold",
      Width: 10,
    },
    { id: "date_of_NDVI_data", label: "date_of_NDVI_data", Width: 10 },
    { id: "avgTemp", label: "avgTemp", Width: 10 },
    { id: "avgRelHum", label: "avgRelHum", Width: 10 },
    { id: "avgSoilTemp", label: "avgSoilTemp", Width: 10 },
    { id: "avgRain", label: "avgRain", Width: 10 },
    { id: "disease", label: "disease", Width: 10 },
    { id: "tempMin", label: "tempMin", Width: 10 },
    { id: "tempMax", label: "tempMax", Width: 10 },
    { id: "relHumMin", label: "relHumMin", Width: 10 },
    { id: "relHumMax", label: "relHumMax", Width: 10 },
    { id: "soilTempMax", label: "soilTempMax", Width: 10 },
    { id: "rainFallMin", label: "rainFallMin", Width: 10 },
    { id: "rainFallMax", label: "rainFallMax", Width: 10 },
    { id: "alert", label: "Alert", Width: 200 },
    { id: "createdAt", label: "createdAt", Width: 200 },
    { id: "updatedAt", label: "updatedAt", Width: 200 },
  ];

  function createData(
    id,
    farmId,
    NDVI,
    avg_NDVI,
    min_NDVI,
    max_NDVI,
    total_pixel,
    pixel_above_threshold,
    pixel_below_threshold,
    pixel_between_threshold,
    date_of_NDVI_data,
    avgTemp,
    avgRelHum,
    avgSoilTemp,
    avgRain,
    disease,
    tempMin,
    tempMax,
    relHumMin,
    relHumMax,
    soilTempMax,
    rainFallMin,
    rainFallMax,
    alert,
    createdAt,
    updatedAt
  ) {
    return {
      id,
      farmId,
      NDVI,
      avg_NDVI,
      min_NDVI,
      max_NDVI,
      total_pixel,
      pixel_above_threshold,
      pixel_below_threshold,
      pixel_between_threshold,
      date_of_NDVI_data,
      avgTemp,
      avgRelHum,
      avgSoilTemp,
      avgRain,
      disease,
      tempMin,
      tempMax,
      relHumMin,
      relHumMax,
      soilTempMax,
      rainFallMin,
      rainFallMax,
      alert,
      createdAt,
      updatedAt,
    };
  }

  useEffect(() => {
    const fetchData = async () => {
       await axios
        .get("https://fmserver.escorts.co.in/admin/agrodata", {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((response) => {
          console.log(response.data.data);
          setRTWS(response.data.data);
          console.log(RTWS);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
        });
    };
    fetchData();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  function changeDateFormat(val) {
    const date = new Date(val);
    const new_date = date.toUTCString();
    return new_date;
  }
  const filterDataByDate = (data, startDate, endDate) => {
    console.log(data, startDate, endDate)
    if (!startDate || !endDate) {
      return data;
    }
  
    const filteredData = data.filter((item) => {
      const createdAt = new Date(item.createdAt);
      const formattedDate=createdAt.toISOString().split("T")[0]
      console.log(formattedDate);
      return formattedDate >= startDate && formattedDate <= endDate;
    });
  
    return filteredData;
  };
  function rtwsFormSubmitHandler(e) {
    setLoading(true);
    e.preventDefault();
  
    const fetchData = async () => {
      const startDateValue = startDate.current.value;
      const endDateValue = endDate.current.value;
  
      if (!farmID.current.value) {
        console.log("Farm ID is required");
        // return;
      }
  
      if (!startDateValue || !endDateValue) {
        console.log("Start date and end date are required");
        // return;
      }
  
      console.log(farmID.current.value, startDateValue, endDateValue);
      const farm = farmID.current.value;
  
      try {
        const response = await axios.get(
          `https://fmserver.escorts.co.in/admin/agrodata/${farm}`,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );
  
        const filteredData = filterDataByDate(
          response.data.data,
          startDateValue,
          endDateValue
        );
  
        console.log(filteredData);
        setRTWS(filteredData);
        setLoading(false);
        console.log(RTWS);
      } catch (error) {
        console.log(error);
      }
    };
  
    fetchData();
  }
  useEffect(() => {
    // const timestamps = iot?.readings?.rain_guage_readings?.map(item => item?.timestamp)
    // console.log(timestamps)
    console.log(RTWS.length);
    const data = [];
    for (let i = 0; i < RTWS.length; i++) {
      data.push({
        // timestamp: timestamps[i],
        id: RTWS?.[i]?.id,
        farmId: RTWS?.[i]?.farmId,
        NDVI: RTWS?.[i]?.NDVI,
        avgTemp: RTWS?.[i]?.avgTemp,
        avgRelHum: RTWS?.[i]?.avgRelHum,
        avgSoilTemp: RTWS?.[i]?.avgSoilTemp,
        avgRain: RTWS?.[i]?.avgRain,
        disease: RTWS?.[i]?.disease,
        tempMin: RTWS?.[i]?.tempMin,
        tempMax: RTWS?.[i]?.tempMax,
        relHumMin: RTWS?.[i]?.relHumMin,
        relHumMax: RTWS?.[i]?.relHumMax,
        soilTempMin: RTWS?.[i]?.soilTempMin,
        soilTempMax: RTWS?.[i]?.soilTempMax,
        rainFallMin: RTWS?.[i]?.rainFallMin,
        rainFallMax: RTWS?.[i]?.rainFallMax,
        alert: RTWS?.[i]?.alert,
        createdAt: RTWS?.[i]?.createdAt,
        updatedAt: RTWS?.[i]?.updatedAt,
        avg_NDVI: RTWS?.[i]?.avg_NDVI,
        min_NDVI: RTWS?.[i]?.min_NDVI,
        max_NDVI: RTWS?.[i]?.max_NDVI,
        total_pixel: RTWS?.[i]?.total_pixel,
        pixel_above_threshold: RTWS?.[i]?.pixel_above_threshold,
        pixel_below_threshold: RTWS?.[i]?.pixel_below_threshold,
        pixel_between_threshold: RTWS?.[i]?.pixel_between_threshold,
        date_of_NDVI_data: RTWS?.[i]?.date_of_NDVI_data,
      });
    }
    // console.log(data)
    // setCsvData(data)
    setCsvLinkData({ filename: "RTWS sheet", headers: headers, data: data });
    // console.log({filename: 'file.csv', headers: headers, data: data})
  }, [RTWS]);
  const [navExpanded, setNavExpanded] = useState(false);
  const enterHandler = (e) => {
    setNavExpanded(true);
  };
  const leaveHandler = (e) => {
    setNavExpanded(false);
  };
  return (
    <>
    <Topbar routeTitle={"RTWS sheet"} no_content />
    {loading?<h1>data is loading, please wait</h1>:
    <div className="RTWS_main">
      <Navbar
          expand={navExpanded}
          enterHandler={enterHandler}
          leaveHandler={leaveHandler}/>
          <div style={{display:"flex", flexDirection:"column"}}>
      <Paper sx={{ width: "90vw", overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: 550 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.Width, color: "blue" }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {RTWS.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
              ).map((item) => {
                const row = createData(
                  item?.id,
                  item?.farmId,
                  item?.NDVI,
                  item?.avg_NDVI,
                  item?.min_NDVI,
                  item?.max_NDVI,
                  item?.total_pixel,
                  item?.pixel_above_threshold,
                  item?.pixel_below_threshold,
                  item?.pixel_between_threshold,
                  item?.date_of_NDVI_data,
                  item?.avgTemp,
                  item?.avgRelHum,
                  item?.avgSoilTemp,
                  item?.avgRain,
                  item?.disease,
                  item?.tempMin,
                  item?.tempMax,
                  item?.relHumMin,
                  item?.relHumMax,
                  item?.soilTempMax,
                  item?.rainFallMin,
                  item?.rainFallMax,
                  item?.alert,
                  changeDateFormat(item?.createdAt),
                  changeDateFormat(item?.updatedAt)
                );

                return (
                  <TableRow  hover role="checkbox" tabIndex={-1} key={row.id}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id}  align={column.align}>
                          {column.format && typeof value === "number"
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[1000, 1200, 1400]}
          count={RTWS.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <form onSubmit={rtwsFormSubmitHandler} className="RTWS_filter_form">
        <div>
          <input
            ref={farmID}
            type="text"
            placeholder="Enter the farmID"
            title="Enter the farmID"
          />
        </div>
        <div>
          <input
            ref={startDate}
            type="date"
            placeholder="Date From"
            title="Date from"
          />
        </div>
        <div>
          <input
            ref={endDate}
            type="date"
            placeholder="Date upto"
            title="Date upto"
          />
        </div>
        <button type="submit">Submit</button>
      </form>
      {csvLinkData?.data?.length ? <CSVLink {...csvLinkData} > Download </CSVLink> : <>Choose farm to download</>}
      </div>
      {/* <h4>{startDate}<span>to</span> {endDate}</h4> */}
    </div>
 }
    </>
  );
}



