import "./farmerInfoCard.css";

const FarmerInfoCard = ({ farmerInfo, farmInfo }) => {
  const farmer = farmerInfo;
  console.log(farmer)
  function calculateTotalAreaCultivated()
  {
    let totalArea=0;
    farmer.farms.map((value)=>{
      totalArea=totalArea+value.area
    })
    return totalArea.toFixed(2);
  }
  return (
    <div className="table__farmer">
      <div className="table__name">Farmer Details</div>
      <div className="iot__table__main">
        <div className="iot__table__left">
          <div className="table_data_item">
            <span className="table_item_key">Name:</span>
            <span className="table_item_value">{farmer?.user?.name}</span>
          </div>
          <div className="table_data_item">
            <span className="table_item_key">Number:</span>
            <span className="table_item_value">
              {farmer?.user?.phone_number}
            </span>
          </div>
          <div className="table_data_item">
            <span className="table_item_key">Email ID:</span>
            <span className="table_item_value">{farmer?.user?.email ?? 'NA'}</span>
          </div>
          <div className="table_data_item">
            <span className="table_item_key">Address:</span>
            <span className="table_item_value">{farmer?.user?.location}</span>
          </div>
          <div className="table_data_item">
            <span className="table_item_key">Total no. of farms</span>
            <span className="table_item_value">{farmer.farms.length}</span>
          </div>
          <div className="table_data_item">
            <span className="table_item_key">Total Land Cultivated:</span>
            <span className="table_item_value">{`${calculateTotalAreaCultivated()} acres`}</span>
          </div>
        </div>
        {/* <div className="iot_table_right" style={{display: 'flex', alignItems: 'center'}}>
          {
            farmer.user?.profile_img ? 
          <Avatar alt={farmer?.user?.name[0]} src={farmer?.user?.profile_img} /> :
          <Avatar sx={{color: 'white', backgroundColor: 'tomato', width: '4rem', height: '4rem'}} >{farmer?.user?.name[0]}</Avatar>
          }
        </div> */}
      </div>
    </div>
  );
};

export default FarmerInfoCard;
