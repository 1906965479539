const UserReducer = (state, action) => {
    switch (action.type) {
        case "STORE_IOT_TOKEN":
            return {
                ...state, iotToken: action.payload
            };
        case "STORE_AGRO_TOKEN":
            return {
                ...state, agroToken: action.payload
            };
        case "STORE_AUTH_TOKEN":
            return {
                ...state, authToken: action.payload.authToken,department:action.payload.department
            }
    }
}

export default UserReducer