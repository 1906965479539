import { fontSize } from "@mui/system";
import React, {useState } from "react";

const AgroTable = ({ iot }) => {
    const [expanded, setExpanded] = useState(true);
    console.log(typeof(iot?.readings?.wind_direction_readings?.at(-1)?.reading));
    const handleClick = () => {
      setExpanded(!expanded);
    };
    function checkStatus(value,sensor_name)
  {
    if(sensor_name=="irrometer")
    {
      if(value==-1)
      return "Over Saturated"
      else if(value==-3)
      return "Not Connected/ Over Dry";
      else if(value==-4)
      return "Short Circuit";
      else return Math.round(value*100)/100;

    }
    else
    {
      if(value==-1)
      return "Inactive";
      else if(value==null)
      return "No sensor"
      else 
      {
        if(sensor_name== "wind_direction")
        return value;
        return Math.round(value*100)/100;
      }
    }
    
  }
    const HTMLcomponent=[
      <div className="agro-table_data_item">
          <span className="table_item_key">Air Temperature</span>
          <span>
          {checkStatus(iot?.readings?.atmospheric_tempratrature_readings?.at(-1)?.reading,"non_irrometer")} {typeof checkStatus(iot?.readings?.atmospheric_tempratrature_readings?.at(-1)?.reading,"non_irrometer") !== "string"? <span className="table_item_unit"><sup>o</sup>C </span>:""}
          </span>
        </div>,
        <div className="agro-table_data_item">
          <span className="table_item_key">Air Pressure</span>
          <span>
          {checkStatus(iot?.readings?.atmospheric_pressure_redings?.at(-1)?.reading,"non_irrometer")} {typeof checkStatus(iot?.readings?.atmospheric_pressure_redings?.at(-1)?.reading,"non_irrometer") !== "string"? <span className="table_item_unit">hPa</span>:""}
          </span>
        </div>,
        <div className="agro-table_data_item">
          <span className="table_item_key">Air Humidity</span>
          <span>
          {checkStatus(iot?.readings?.atmospheric_humidity_readings?.at(-1)?.reading,"non_irrometer")} {typeof checkStatus(iot?.readings?.atmospheric_humidity_readings?.at(-1)?.reading,"non_irrometer") !== "string"?<span className="table_item_unit">  %RH</span>:""}
          </span>
        </div>,
        <div className="agro-table_data_item">
          <span className="table_item_key">Wind Speed</span>
          <span>
          {checkStatus(iot?.readings?.wind_speed_readings?.at(-1)?.reading,"non_irrometer")} {typeof checkStatus(iot?.readings?.wind_speed_readings?.at(-1)?.reading,"non_irrometer") !== "string"?<span className="table_item_unit">km/h</span>:""}
          </span>
        </div>,
        <div className="agro-table_data_item">
          <span className="table_item_key">Wind Direction</span>
          <span>
          { typeof checkStatus(iot?.readings?.wind_direction_readings?.at(-1)?.reading,"wind_direction") !== "string"? `${Math.round(iot?.readings?.wind_direction_readings?.at(-1)?.reading * 100) / 100}°N`: iot?.readings?.wind_direction_readings?.at(-1)?.reading }
          </span>
        </div>,
        <div className="agro-table_data_item">
          <span className="table_item_key">Solar Panel Voltage</span>
          <span>
          {checkStatus(iot?.readings?.solar_voltage_readings?.at(-1)?.reading,"non_irrometer")} {typeof checkStatus(iot?.readings?.solar_voltage_readings?.at(-1)?.reading,"non_irrometer") !== "string"? <span className="table_item_unit">V</span>:""}
          </span>
        </div>,
        <div className="agro-table_data_item">
          <span className="table_item_key">Nitrogen Reading</span>
          <span>
          {checkStatus(iot?.readings?.npk_readings?.at(-1)?.reading?.N,"non_irrometer")} {typeof checkStatus(iot?.readings?.npk_readings?.at(-1)?.reading?.N,"non_irrometer") !== "string"? <span className="table_item_unit">mg/kg</span>:""}
          </span>
        </div>,
        <div className="agro-table_data_item">
          <span className="table_item_key">Phosphorous Reading</span>
          <span>
          {checkStatus(iot?.readings?.npk_readings?.at(-1)?.reading?.P,"non_irrometer")} {typeof checkStatus(iot?.readings?.npk_readings?.at(-1)?.reading?.P,"non_irrometer") !== "string"?<span className="table_item_unit">mg/kg</span>:""}
          </span>
        </div>,
        <div className="agro-table_data_item">
          <span className="table_item_key">Potassium Reading</span>
          <span>
          {checkStatus(iot?.readings?.npk_readings?.at(-1)?.reading?.K,"non_irrometer")} {typeof checkStatus(iot?.readings?.npk_readings?.at(-1)?.reading?.K,"non_irrometer") !== "string"? <span className="table_item_unit">mg/kg</span>:""}
          </span>
        </div>,
        <div className="agro-table_data_item">
          <span className="table_item_key">Rain Gauge</span>
          <span>{checkStatus(iot?.readings?.rain_guage_readings?.at(-1)?.reading,"non_irrometer")} {typeof checkStatus(iot?.readings?.rain_guage_readings?.at(-1)?.reading,"non_irrometer") !== "string"? <span className="table_item_unit">mm</span>:""}</span>
        </div>,
        <div className="agro-table_data_item">
          <span className="table_item_key">Lux Reading</span>
          <span>{checkStatus(iot?.readings?.lux_readings?.at(-1)?.reading,"non_irrometer" )} {typeof checkStatus(iot?.readings?.lux_readings?.at(-1)?.reading,"non_irrometer") !== "string"? <span className="table_item_unit">w/m<sup>2</sup></span> :""}</span>
        </div>,
        <div className="agro-table_data_item">
          <span className="table_item_key">Soil Moisture 1</span>
          <span>{checkStatus(iot?.readings?.soil_moisture_reading_1?.at(-1)?.reading,"irrometer" )} {typeof checkStatus(iot?.readings?.soil_moisture_reading_1?.at(-1)?.reading,"irrometer") !== "string"? <span className="table_item_unit">cb</span>:""}</span>
        </div>,
        <div className="agro-table_data_item">
        <span className="table_item_key">Soil Moisture 2</span>
        <span>{checkStatus(iot?.readings?.soil_moistre_readings_2?.at(-1)?.reading,"irrometer")} {typeof checkStatus(iot?.readings?.soil_moistre_readings_2?.at(-1)?.reading,"irrometer") !== "string"? <span className="table_item_unit">cb</span>:""}</span>
        </div>,
        <div className="agro-table_data_item">
          <span className="table_item_key">Soil Humidity</span>
          <span>{checkStatus(iot?.readings?.soil_humidity_readings?.at(-1)?.reading,"non_irrometer")} {typeof checkStatus(iot?.readings?.soil_humidity_readings?.at(-1)?.reading,"non_irrometer") !== "string"? <span className="table_item_unit">%RH</span> :""}</span>
        </div>,
        <div className="agro-table_data_item">
          <span className="table_item_key">Soil Temperature</span>
          <span>{checkStatus(iot?.readings?.soil_temprature_readings?.at(-1)?.reading,"non_irrometer")} {typeof checkStatus(iot?.readings?.soil_temprature_readings?.at(-1)?.reading,"non_irrometer") !== "string"? <span className="table_item_unit"> <sup>o</sup>C </span> :""}</span>
        </div>,
        <div className="agro-table_data_item">
          <span className="table_item_key">CO<sub>2</sub> Reading</span>
          <span>{checkStatus(iot?.readings?.co2_level_readings?.at(-1)?.reading,"non_irrometer")} {typeof checkStatus(iot?.readings?.co2_level_readings?.at(-1)?.reading,"non_irrometer") !== "string"? <span className="table_item_unit">cb</span>:""}</span>
        </div>,
        <div className="agro-table_data_item">
          <span className="table_item_key">Battery Voltage</span>
          <span>{checkStatus(iot?.readings?.battery_voltage_readings?.at(-1)?.reading,"non_irrometer" )} {typeof checkStatus(iot?.readings?.battery_voltage_readings?.at(-1)?.reading,"non_irrometer") !== "string"? <span className="table_item_unit">V</span> :""}</span>
        </div>,
        <div className="agro-table_data_item">
          <span className="table_item_key">Leaf Humidity</span>
          <span>{checkStatus(iot?.readings?.leaf_wetness_readings?.at(-1)?.reading?.leaf_humi,"non_irrometer" )} {typeof checkStatus(iot?.readings?.leaf_wetness_readings?.at(-1)?.reading?.leaf_humi,"non_irrometer") !== "string"? <span className="table_item_unit">%RH</span> :""}</span>
        </div>,
        <div className="agro-table_data_item">
          <span className="table_item_key">Canopy Temperature</span>
          <span>
            {
              checkStatus(iot?.readings?.leaf_wetness_readings?.at(-1)?.reading?.leaf_temp,"non_irrometer")
            }
            {typeof checkStatus(iot?.readings?.leaf_wetness_readings?.at(-1)?.reading?.leaf_temp,"non_irrometer") !== "string"?<span className="table_item_unit"> <sup>o</sup>C</span>:""}
          </span>
        </div>,
        <div className="agro-table_data_item">
          <span className="table_item_key">Images from Installed Camera</span>
          <span>data</span>
        </div>,
        <div className="agro-table_data_item">
          <span className="table_item_key">Last update time</span>
          <span>{new Date(iot?.readings?.rain_guage_readings?.at(-1)?.timestamp).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true, day: '2-digit', month: '2-digit', year: 'numeric' })}</span>
        </div>
    ]
  return (
    <>
    <div className="agro__table__main">
      {/* <div className="iot__table__left"> */}
      <div className="agro_table_grid">
        {
          expanded?
        (HTMLcomponent.slice(0,4).map((component)=>(
          <div key={component.key}>
            {component}
          </div>
        ))):
        (HTMLcomponent.map((component)=>(
          <div key={component.key}>
            {component}
          </div>
        ))) 
        }
      </div>
    </div>
    <button className="toggle_button" onClick={handleClick}>
    {expanded ? 'More' : 'Less'}
    </button>
    </>
  );
};

export default AgroTable;
