import React, { useEffect, useRef, useState } from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import axios from 'axios';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const fieldNames = {
  first: [
    { api_name: 'atmospheric_tempratrature_readings', label_name: 'Air Temperature', borderColor: 'rgba(243, 58, 29, 0.4)', backgroundColor: 'rgba(243, 58, 29, 0.3)' },
    { api_name: 'battery_voltage_readings', label_name: 'Battery Voltage', borderColor: 'rgba(1, 163, 170, 0.4)', backgroundColor: 'rgba(1, 163, 170, 0.3)' },
    { api_name: 'atmospheric_pressure_redings', label_name: 'Air Pressure', borderColor: 'rgba(30, 179, 55, 0.4)', backgroundColor: 'rgba(30, 179, 55, 0.3)' },
    { api_name: 'atmospheric_humidity_readings', label_name: 'Air Humidity', borderColor: 'rgba(202, 76, 207, 0.4)', backgroundColor: 'rgba(202, 76, 207, 0.3)' },
    { api_name: 'leaf_wetness_readings', label_name: 'Leaf Wetness', borderColor: 'rgba(222, 217, 89, 0.4)', backgroundColor: 'rgba(222, 217, 89, 0.3)' },
    { api_name: 'canopy_temparture_readings', label_name: 'Canopy Temperature', borderColor: 'rgba(156, 155, 152, 0.4)', backgroundColor: 'rgba(156, 155, 152, 0.3)' },
    { api_name: 'soil_moisture_reading_1', label_name: 'Soil Moisture_1', borderColor: 'rgba(243, 141, 46, 0.4)', backgroundColor: 'rgba(243, 141, 46, 0.3)' },
    { api_name: 'rain_guage_readings', label_name: 'Precipitation', borderColor: 'rgba(53, 122, 212, 0.4)', backgroundColor: 'rgba(53, 122, 212, 0.3)' },
  ],
  second: [
    { api_name: 'atmospheric_tempratrature_readings', label_name: 'Air Temperature 02', borderColor: 'rgba(243, 58, 29,0.7)', backgroundColor: 'rgba(243, 58, 29,0.8)' },
    { api_name: 'battery_voltage_readings', label_name: 'Battery Voltage 02', borderColor: 'rgba(1, 163, 170, 0.7)', backgroundColor: 'rgba(1, 163, 170, 0.8)' },
    { api_name: 'atmospheric_pressure_redings', label_name: 'Air Pressure 02', borderColor: 'rgba(30, 179, 55,0.7)', backgroundColor: 'rgba(30, 179, 55,0.8)' },
    { api_name: 'atmospheric_humidity_readings', label_name: 'Air Humidity 02', borderColor: 'rgba(202, 76, 207,0.7)', backgroundColor: 'rgba(202, 76, 207,0.8)' },
    { api_name: 'leaf_wetness_readings', label_name: 'Leaf Wetness 02', borderColor: 'rgba(222, 217, 89,0.7)', backgroundColor: 'rgba(222, 217, 89,0.8)' },
    { api_name: 'canopy_temparture_readings', label_name: 'Canopy Temperature 02', borderColor: 'rgba(156, 155, 152,0.7)', backgroundColor: 'rgba(156, 155, 152,0.8)' },
    { api_name: 'soil_moisture_reading_1', label_name: 'Soil Moisture_1 02', borderColor: 'rgba(243, 141, 46,0.7)', backgroundColor: 'rgba(243, 141, 46,0.8)' },
    { api_name: 'rain_guage_readings', label_name: 'Precipitation 02', borderColor: 'rgba(53, 122, 212,0.7)', backgroundColor: 'rgba(53, 122, 212,0.8)' },

  ]
}

const CombinedChart = ({ iot1, iot2, farmId1, farmId2 }) => {
  console.log(farmId1)
  console.log(farmId2)
  console.log("%ciot1", "color: green")
  console.log(iot1)
  console.log("%ciot2", "color: royalblue")
  console.log(iot2)
  ChartJS.defaults.font.size = 10;
  const initailLabel1 = iot1?.readings?.atmospheric_humidity_readings?.map(
    (element) => element?.timestamp?.toString().split(', ')[1]
  );
  const initailLabel2 = iot2?.readings?.atmospheric_humidity_readings?.map(
    (element) => element?.timestamp?.toString().split(', ')[1]
  );
  const [labels, setLabels] = useState(initailLabel1?.filter((item, index) => ((index % 4) === 0)))
  console.log("%cInitial Labels", "color: red")
  console.log(labels)
  const selectedField = useRef()
  const iotDataSet = [
    {
      label: "Air Temperature",
      data: iot1?.readings?.atmospheric_tempratrature_readings?.map(
        (data) => data.reading
      ).filter((item, index) => ((index % 4) === 0)),
      borderColor: "rgba(243, 58, 29, 0.4)",
      backgroundColor: "rgba(243, 58, 29,0.2)",
    },
    {
      label: "Battery Voltage",
      data: iot1?.readings?.battery_voltage_readings?.map(
        (data) => data.reading
      ).filter((item, index) => ((index % 4) === 0)),
      borderColor: "rgba(1, 163, 170, 0.4)",
      backgroundColor: "rgba(1, 163, 170, 0.3)",
    },
    {
      label: "Air Pressure",
      data: iot1?.readings?.atmospheric_pressure_redings?.map(
        (data) => data.reading
      ).filter((item, index) => ((index % 4) === 0)),
      borderColor: "rgba(30, 179, 55, 0.4)",
      backgroundColor: "rgba(30, 179, 55,0.3)",
    },
    {
      label: "Air Humidity",
      data: iot1?.readings?.atmospheric_humidity_readings?.map(
        (data) => data.reading
      ).filter((item, index) => ((index % 4) === 0)),
      borderColor: "rgba(202, 76, 207, 0.4)",
      backgroundColor: "rgba(202, 76, 207,0.3)",
    },
    {
      label: "Leaf Wetness",
      data: iot1?.readings?.leaf_wetness_readings?.map((data) => data.reading.leaf_temp).filter((item, index) => ((index % 4) === 0)),
      borderColor: "rgba(222, 217, 89, 0.4)",
      backgroundColor: "rgba(222, 217, 89,0.3)",
    },
    // {
    //   label: "Canopy Temperature",
    //   data: iot1?.readings?.canopy_temparture_readings?.map(
    //     (data) => data.reading
    //   ).filter((item, index) => ((index % 4) === 0)),
    //   borderColor: "rgb(156, 155, 152)",
    //   backgroundColor: "rgba(156, 155, 152,0.5)",
    // },
    {
      label: "Soil Moisture_1",
      data: iot1?.readings?.soil_moisture_reading_1?.map((data) => data.reading).filter((item, index) => ((index % 4) === 0)),
      borderColor: "rgba(243, 141, 46, 0.4)",
      backgroundColor: "rgba(243, 141, 46,0.3)",
    },
    {
      label: "Precipitation",
      data: iot1?.readings?.rain_guage_readings?.map((data) => data.reading).filter((item, index) => ((index % 4) === 0)),
      borderColor: "rgba(53, 122, 212,0.4)",
      backgroundColor: "rgba(53, 122, 212,0.3)",
    },
    {
      label: "Air Temperature 02",
      data: iot2?.readings?.atmospheric_tempratrature_readings?.map(
        (data) => data.reading
      ).filter((item, index) => ((index % 4) === 0)),
      borderColor: "rgb(243, 58, 50)",
      backgroundColor: "rgba(243, 58, 29,0.8)",
    },
    {
      label: "Battery Voltage 02",
      data: iot2?.readings?.battery_voltage_readings?.map(
        (data) => data.reading
      ).filter((item, index) => ((index % 4) === 0)),
      borderColor: "rgba(1, 163, 170, 0.7)",
      backgroundColor: "rgba(1, 163, 170, 0.8)",
    },
    {
      label: "Air Pressure 02",
      data: iot2?.readings?.atmospheric_pressure_redings?.map(
        (data) => data.reading
      ).filter((item, index) => ((index % 4) === 0)),
      borderColor: "rgb(30, 179, 55)",
      backgroundColor: "rgba(30, 179, 55,0.8)",
    },
    {
      label: "Air Humidity 02",
      data: iot2?.readings?.atmospheric_humidity_readings?.map(
        (data) => data.reading
      ).filter((item, index) => ((index % 4) === 0)),
      borderColor: "rgb(202, 76, 207)",
      backgroundColor: "rgba(202, 76, 207,0.8)",
    },
    {
      label: "Leaf Wetness 02",
      data: iot2?.readings?.leaf_wetness_readings?.map((data) => data.reading.leaf_temp).filter((item, index) => ((index % 4) === 0)),
      borderColor: "rgba(222, 217, 89,1)",
      backgroundColor: "rgba(222, 217, 89,0.8)",
    },
    // {
    //   label: "Canopy Temperature 02",
    //   data: [...iot1?.readings?.atmospheric_humidity_readings?.map(
    //     (data) => NaN
    //   )?.filter((item, index) => ((index % 4) === 0)),
    //   ...iot2?.readings?.canopy_temparture_readings?.map(
    //     (data) => data.reading
    //   )?.filter((item, index) => ((index % 4) === 0))],
    //   borderColor: "rgb(156, 155, 152)",
    //   backgroundColor: "rgba(156, 155, 152,0.5)",
    // },
    {
      label: "Soil Moisture_1 02",
      data: iot2?.readings?.soil_moisture_reading_1?.map((data) => data.reading).filter((item, index) => ((index % 4) === 0)),
      borderColor: "rgba(243, 141, 46,1)",
      backgroundColor: "rgba(243, 141, 46,0.8)",
    },
    {
      label: "Precipitation 02",
      data: iot2?.readings?.rain_guage_readings?.map((data) => data.reading).filter((item, index) => ((index % 4) === 0)),
      borderColor: "rgba(53, 122, 212,1)",
      backgroundColor: "rgba(53, 122, 212,0.8)",
    },
  ];
  const [selectedOption, setSelectedOption] = useState(iotDataSet);
  const [data, setData] = useState({
    labels: labels,
    datasets: selectedOption,
  });
  
  console.log(data);
  const [isDateEnabled, setIsDateEnabled] = useState(false);
  const handleSelectChange = (e) => {
    const selectedValue = e.target.value;
    console.log(selectedValue)
    console.log(selectedField.current.value)
    const selectedObject = isDateEnabled ? iotDatasetRange.find(
        (dataSet) => dataSet?.label === selectedValue
      ) : iotDataSet.find(
      (dataSet) => dataSet?.label === selectedValue
    );
    console.log("%chey buddy", "color: green; font-size: larger", selectedObject);
    if (selectedValue === "all") {
      setSelectedOption(isDateEnabled ? iotDatasetRange : iotDataSet)
      setData({
        labels,
        datasets: isDateEnabled ? iotDatasetRange : iotDataSet,
      });
    } else {
      setSelectedOption(selectedObject)
      setData({
        labels,
        datasets: [selectedObject],
      });
    }
  };

  const options = {
    tension: 0.3,
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "bottom",
        labels: {
          font: {
            size: 10,
          }
        }
      },
      scales: {
        y: {
          ticks: {
            font: {
              size: 10,
            }
          }
        }
      },
      title: {
        display: false,
        text: "Combined data",
      },
    },
  };
  const [iotDatasetRange,setIotDatasetRange] = useState([]);
  useEffect(()=>{
    setData({
        labels,
        datasets : isDateEnabled ? iotDatasetRange : iotDataSet
    })
  },[labels])
  const fetchAllRanges = async ()=> {
    setIotDatasetRange([])
    fieldNames.first.forEach(async (item, index) => {
        let res = await axios.get(`https://fmserver.escorts.co.in/admin/farmer/readings?parameter=${item?.api_name}&start_date=${startDate?.current?.value}&end_date=${endDate?.current?.value}&farm_id=${farmId1 ?? 5}`)
        if(index === 0)
        {console.log("RES FIRST", res)}
        index === 0 && setLabels(res?.data?.result?.map(
          (element) => element?.obj?.timestamp.toString().split(', ')[1]
          )?.filter((item, index) => ((index % 8) === 0)))
          setIotDatasetRange(prevArray => [...prevArray,{
            label: item.label_name,
            data : item.label_name === 'Leaf Wetness' ? res?.data?.result?.map(val => val?.obj?.reading.leaf_temp).filter((item, index) => ((index % 8) === 0)) :  res?.data?.result?.map(val => val?.obj?.reading).filter((item, index) => ((index % 8) === 0)),
            borderColor: item?.borderColor,
            backgroundColor: item?.backgroundColor
          }])
        })
        fieldNames.second.forEach(async (item, index) => {
          let res = await axios.get(`https://fmserver.escorts.co.in/admin/farmer/readings?parameter=${item?.api_name}&start_date=${startDate?.current?.value}&end_date=${endDate?.current?.value}&farm_id=${farmId2 ?? 5}`)
          if(index === 0){
            console.log("RES SECOND", res)
          }
          setLabels(res?.data?.result?.map(
          (element) => element?.obj?.timestamp.toString().split(', ')[1]
        )?.filter((item, index) => ((index % 8) === 0)))
        setIotDatasetRange(prevArray => [...prevArray,{
            label: item.label_name,
            data : item.label_name === 'Leaf Wetness 02' ? res?.data?.result?.map(val => val?.obj?.reading.leaf_temp).filter((item, index) => ((index % 8) === 0)) :  res?.data?.result?.map(val => val?.obj?.reading).filter((item, index) => ((index % 8) === 0)),
            borderColor: item?.borderColor,
            backgroundColor: item?.backgroundColor
        }])
    })
    console.log(iotDatasetRange)
  }
  const startDate = useRef()
  const endDate = useRef()
  const dateRangeHanlder = (e) => {
    e.preventDefault()
    setIsDateEnabled(true)
    selectedField.current.value = 'all'
    console.log(startDate.current.value)
    console.log(endDate.current.value)
    if(!startDate.current.value || !endDate.current.value) {
      alert("Please select both the dates")
    } else {
      const fetchRangeData = async () => {
        const res = await axios.get(`https://fmserver.escorts.co.in/admin/farmer/readings?parameter=rain_guage_readings&start_date=${startDate.current.value}&end_date=${endDate.current.value}&farm_id=${farmId1}`)
        console.log(res)
        await fetchAllRanges()
        setSelectedOption(iotDatasetRange)
      }
    fetchRangeData()
    }
  }
  return (
    <>
      {iot1 && iot2 ? <>
        <div className="chart__wrapper">
          <Line options={options} data={data} className="chart_chart" style={{ height: "250px" }} />
        </div>
        <div className="chart__controller__inputs">
          <div className="chart__dropdown" style={{ display: 'flex', justifyContent: 'space-evenly', margin: '1rem 0' }}>
            <select ref={selectedField} onChange={handleSelectChange} className="chart__select">
              <option key="all" value="all">
                All Values
              </option>
              {isDateEnabled ? 
               iotDatasetRange.map((dataSet) => (
                <option key={dataSet.label} value={dataSet.label}>
                  {dataSet.label}
                </option>
              )) 
              :
               iotDataSet.map((dataSet) => (
                <option key={dataSet.label} value={dataSet.label}>
                  {dataSet.label}
                </option>
              ))
              }
            </select>
          </div>
          {<form className="dateRange__form" onSubmit={dateRangeHanlder}>
            <div className="chart__daterange__input">
              <input ref={startDate} type="date" placeholder="Date From" title="Date from" />
            </div>
            <div className="chart__daterange__input">
              <input ref={endDate} type="date" placeholder="Date upto" title="Date upto" />
            </div>
            <button type="submit">Submit</button>
          </form>}
        </div>
      </> :
        <h2>Select both farms to show combined data chart</h2>
      }
    </>
  )
}

export default CombinedChart