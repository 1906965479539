import "./QueryCard.css"
const QueryCard=({qid,prompt,date,team,createdAt ,onClick,selected})=>{
return(<>
<div onClick={onClick} className={!selected?"query_card_main":"query_card_main_selected"}>
    <div className="left_content">
        <div className="qid">
            Query ID: {qid.substring(0, 3)}
        </div>
        <div className="prompt">
            query text: {prompt}
        </div>
        <div className="date">
            {date}
        </div>
        <div className="status">
            {team}
        </div>
    </div>
        <div className="createdAt">
            {createdAt}
        </div>
</div>
</>)
}
export default QueryCard;