import "./topbar.css";
import EscortsLogo from "../../assets/Escort_kubota_limited.png";
import SearchIcon from "../../assets/search icon.svg";
import NotificationIcon from "../../assets/Notification.svg";
import UserIcon from "../../assets/User.svg";
import HamburgerIcon from "../../assets/Hamburger.svg";
import { useState, useContext, useRef, useEffect } from "react";
import { UserContext } from "../../context/UserContext";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import NotificationCenter from "../NotificationCenter/NotificationCenter.jsx";
import UserProfile from "../UserProfile/UserProfile.jsx";
// import { UserContext } from "../../context/UserContext";

const Topbar = ({
  routeTitle,
  searchFunc,
  dropDowndata,
  selectHandler,
  no_content,
}) => {
  const { state, dispatch } = useContext(UserContext);
  console.log(dropDowndata);
  const navigate = useNavigate();
  const searchQuery = useRef("");
  const [selectedValue, setSelectedValue] = useState("");
  const [farmerSelected, setFarmerSelected] = useState(false);
  const [showNotificationModal, setShowNotificationModal] = useState(false);
  const [showUserProfileModal, setShowUserProfileModal] = useState(false);
  const handleSearch = async (e) => {
    e.preventDefault();
    await searchFunc(searchQuery.current.value);
    setSelectedValue("");
    // searchQuery.current.value = ''
    setFarmerSelected(false);
  };
  const selectFarmer = (value) => {
    selectHandler(value);
    setFarmerSelected(true);
  };
  function navigateToOverview() {
    navigate("/overview");
  }
  useEffect(() => {
    if (searchFunc) {
      searchQuery.current.value = selectedValue?.id
        ? `Farmer ID: ${selectedValue?.id},  ${selectedValue?.name}, ${selectedValue?.phone_number}`
        : "";
    }
  }, [selectedValue]);
  async function activateWeatherStation() {
    const farm_id = prompt("Enter the farm ID to register the weather station");
    console.log(farm_id);
    
    const axiosConfig = {
      headers: {
        Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJudW1iZXIiOiI5NTIxNDQ5MjM5IiwiaWQiOiI1MSIsImlhdCI6MTY4Njg5NDMxOH0.SM1crLmjlDJ52OwAkhNj8rjACSMDt1MzHjYiA3prtbw`,
      },
    };
  
    try {
      const res = await axios.post(
        `https://fmserver.escorts.co.in/cropAndWeatherMonitoring/register/${farm_id}`,
        null, // Use null or an empty object for the request body if you don't need to send data.
        axiosConfig
      );
      alert(res.data.message);
    } catch (error) {
      console.error("Error:", error);
      alert(error.messagew);
    }
  }
  function NotificationModal(){
    setShowNotificationModal(!showNotificationModal);

  }
  function UserProfileModal(){
    setShowUserProfileModal(!showUserProfileModal);
  }
  return (
    <div className="topbar_notification">
    <div className="topbar">
      <div className="topbar-wrapper">
        <div className="topbar-left">
          <img
            onClick={navigateToOverview}
            className="topbar-logo"
            src={EscortsLogo}
            alt="logo"
          />
          <div className="topbar-left-left">
            <div className="route-title">{routeTitle}</div>
            <div className={`topbar-left-right ${no_content && "no_content"}`}>
              <div className="search-container">
                {searchFunc && (
                  <>
                    <form className="topbar-form" onSubmit={handleSearch}>
                      <input
                        ref={searchQuery}
                        className="search"
                        type="text"
                        placeholder="Type Farmer ID"
                        defaultValue=""
                      />
                      <img
                        onClick={handleSearch}
                        className="search-icon"
                        src={SearchIcon}
                        alt="search"
                      />
                    </form>

                    {dropDowndata && !farmerSelected && (
                      <div className="dropdown__list">
                        {dropDowndata.map((farmer, index) => {
                          return (
                            <div
                              key={`${farmer?.id}-${index}`}
                              className="dropdown__item"
                              onClick={(e) => {
                                setSelectedValue(farmer);
                                selectFarmer(farmer?.id);
                              }}
                            >
                              {`Farmer ID: ${farmer?.id},  ${farmer?.name}, ${farmer?.phone_number}`}
                            </div>
                          );
                        })}
                        {/* <select value={selectedValue} className='topbar-select' onChange={(e) => { setSelectedValue(e.target.value); selectHandler(e.target.value) }}>
                                    <option value='' disabled>Select Farmer</option>
                                    {dropDowndata.map((farmer, index) => (
                                        <option key={farmer?.id + index + farmer?.name} value={farmer?.id}>
                                            {`Farmer ID: ${farmer?.id},  ${farmer?.name}, ${farmer?.phone_number}`}
                                        </option>
                                    ))}
                                </select> */}
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="topbar-right active">
          <img
            className="topbar-right-icon"
            onClick={NotificationModal}
            src={NotificationIcon}
            alt="logo"
          />
          <img
            className="topbar-right-icon"
            onClick={UserProfileModal}
            src={UserIcon}
            alt="logout"
          />
          <img
            className="topbar-right-icon"
            // onClick={activateWeatherStation}
            src={HamburgerIcon}
            alt="register"
          />
        </div>
      </div>
    </div>
    {showNotificationModal?<NotificationCenter/>:<></>}
    {showUserProfileModal?<UserProfile/>:<></>}
    </div>
  );
};

export default Topbar;
