import React from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { useEffect,useState,useContext } from 'react';
import axios from 'axios';
import { UserContext } from "../../context/UserContext";
import Topbar from '../../components/topbar/Topbar';
import Navbar from '../../components/navbar/Navbar';
import "./DeviceDirectory.css";
function Row(props) {
  const { row, onClick,authToken } = props;
  // const [open, setOpen] = useState(false);
  console.log(row);
  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          {/* <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => {setOpen(!open);handleRowClick();}}
          >
            {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
          </IconButton> */}
        </TableCell>
        {/* <TableCell component="th" scope="row">
          {row.name}
        </TableCell> */}
        <TableCell align="right">{row?.id}</TableCell>
        <TableCell align="right">{row?.farm_name}</TableCell>
        <TableCell align="right">{row?.user_id}</TableCell>
        <TableCell align="right">{row?.current_crop}</TableCell>
        <TableCell align="right">{row?.area.toFixed(2)}</TableCell>
        <TableCell align="right">{row?.date_of_deployment}</TableCell>
        <TableCell align="right">{row?.current_status==="Active"?(<p style={{ color: "Green" }}>{row?.current_status}</p>):(<p style={{ color: "black" }}>{row?.current_status}</p>)}</TableCell>
        <TableCell align="right">{row?.hardware_deployment}</TableCell>
        <TableCell align="right">{row?.firmware_version}</TableCell>
        <TableCell align="right">{row?.AIR_SENSOR === "Not Present" ? (<p style={{ color: "black" }}>{row?.AIR_SENSOR}</p>) : row?.AIR_SENSOR === "Active" ? (<p style={{ color: "green" }}>{row?.AIR_SENSOR}</p>) : (<p style={{ color: "red" }}>{row?.AIR_SENSOR}</p>)}</TableCell>
        <TableCell align="right">{row?.SOIL_TEMPERTAURE === "Not Present" ? (<p style={{ color: "black" }}>{row?.SOIL_TEMPERTAURE}</p>) : row?.SOIL_TEMPERTAURE === "Active" ? (<p style={{ color: "green" }}>{row?.SOIL_TEMPERTAURE}</p>) : (<p style={{ color: "red" }}>{row?.SOIL_TEMPERTAURE}</p>)}</TableCell>
        <TableCell align="right">{row?.NPK_SENSOR === "Not Present" ? (<p style={{ color: "black" }}>{row?.NPK_SENSOR}</p>) : row?.NPK_SENSOR === "Active" ? (<p style={{ color: "green" }}>{row?.NPK_SENSOR}</p>) : (<p style={{ color: "red" }}>{row?.NPK_SENSOR}</p>)}</TableCell>
        <TableCell align="right">{row?.WIND_SPEED_SENSOR === "Not Present" ? (<p style={{ color: "black" }}>{row?.WIND_SPEED_SENSOR}</p>) : row?.WIND_SPEED_SENSOR === "Active" ? (<p style={{ color: "green" }}>{row?.WIND_SPEED_SENSOR}</p>) : (<p style={{ color: "red" }}>{row?.WIND_SPEED_SENSOR}</p>)}</TableCell>
        <TableCell align="right">{row?.WIND_DIRECTION_SENSOR === "Not Present" ? (<p style={{ color: "black" }}>{row?.WIND_DIRECTION_SENSOR}</p>) : row?.WIND_DIRECTION_SENSOR === "Active" ? (<p style={{ color: "green" }}>{row?.WIND_DIRECTION_SENSOR}</p>) : (<p style={{ color: "red" }}>{row?.WIND_DIRECTION_SENSOR}</p>)}</TableCell>
        <TableCell align="right">{row?.LEAF_SENSOR === "Not Present" ? (<p style={{ color: "black" }}>{row?.LEAF_SENSOR}</p>) : row?.LEAF_SENSOR === "Active" ? (<p style={{ color: "green" }}>{row?.LEAF_SENSOR}</p>) : (<p style={{ color: "red" }}>{row?.LEAF_SENSOR}</p>)}</TableCell>
        <TableCell align="right">{row?.SOLAR_PANEL === "Not Present" ? (<p style={{ color: "black" }}>{row?.SOLAR_PANEL}</p>) : row?.SOLAR_PANEL === "Active" ? (<p style={{ color: "green" }}>{row?.SOLAR_PANEL}</p>) : (<p style={{ color: "red" }}>{row?.SOLAR_PANEL}</p>)}</TableCell>
        <TableCell align="right">{row?.SOLAR_RADIATION_SENSOR === "Not Present" ? (<p style={{ color: "black" }}>{row?.SOLAR_RADIATION_SENSOR}</p>) : row?.SOLAR_RADIATION_SENSOR === "Active" ? (<p style={{ color: "green" }}>{row?.SOLAR_RADIATION_SENSOR}</p>) : (<p style={{ color: "red" }}>{row?.SOLAR_RADIATION_SENSOR}</p>)}</TableCell>
        <TableCell align="right">{row?.RAIN_GAUGE_SENSOR === "Not Present" ? (<p style={{ color: "black" }}>{row?.RAIN_GAUGE_SENSOR}</p>) : row?.RAIN_GAUGE_SENSOR === "Active" ? (<p style={{ color: "green" }}>{row?.RAIN_GAUGE_SENSOR}</p>) : (<p style={{ color: "red" }}>{row?.RAIN_GAUGE_SENSOR}</p>)}</TableCell>
        <TableCell align="right">{row?.BATTERY === "Not Present" ? (<p style={{ color: "black" }}>{row?.BATTERY}</p>) : row?.BATTERY === "Active" ? (<p style={{ color: "green" }}>{row?.BATTERY}</p>) : (<p style={{ color: "red" }}>{row?.BATTERY}</p>)}</TableCell>
        <TableCell align="right">{row?.IRROMETER_PRIMARY === "Not Present" ? (<p style={{ color: "black" }}>{row?.IRROMETER_PRIMARY}</p>) : row?.IRROMETER_PRIMARY === "Active" ? (<p style={{ color: "green" }}>{row?.IRROMETER_PRIMARY}</p>) : (<p style={{ color: "red" }}>{row?.IRROMETER_PRIMARY}</p>)}</TableCell>
        <TableCell align="right">{row?.IRROMETER_SECONDARY === "Not Present" ? (<p style={{ color: "black" }}>{row?.IRROMETER_SECONDARY}</p>) : row?.IRROMETER_SECONDARY === "Active" ? (<p style={{ color: "green" }}>{row?.IRROMETER_SECONDARY}</p>) : (<p style={{ color: "red" }}>{row?.IRROMETER_SECONDARY}</p>)}</TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function FarmerDirectory() {
  const { state } = useContext(UserContext);
  const authToken = state.authToken;
  const [directory,setDirectory] =useState([]);
  const [navExpanded, setNavExpanded] = useState(false);
  const [directoryState,setDirectoryState] =useState("Active Devices")
  const [originalArray,setOriginalArray] =useState([])
  function setStatus(val)
  {
    if(val==null || val==undefined || val?.reading==null || val?.reading==undefined)
        return "Not Present";
    if(val?.timestamp && isWithinOneHour(val?.timestamp))
    {
      {
        if(val?.reading==-1 || val?.reading?.N==-1 || val?.reading?.leaf_temp==-1)
        return "Inactive";
        else
        return "Active";
      }
    }
    else
    return "Inactive";


  }
  function isWithinOneHour(inputTimeStr){
    if(inputTimeStr==undefined || inputTimeStr==null)
    return false;
    const inputTime = new Date(inputTimeStr);
    const currentTime = new Date();
    const timeDifference = currentTime - inputTime;
    const hoursDifference = timeDifference / (1000 * 60 * 60);
    console.log(hoursDifference);
    return Math.abs(hoursDifference) <= 1;
  }
  


  async function fetchDirectory() {
    try {
      const response = await axios.get('https://fmserver.escorts.co.in/admin/general/allFarms', {
        headers: {
          Authorization: `Bearer ${authToken}`,
        }
      });
  
      const data = response.data.data;
      // Create an array of promises for fetching additional data
      const promises = data.map(async (el) => {
        const detailsResponse = await axios.get(`https://fmserver.escorts.co.in/admin/farmer/farm/${el.id}`, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          }
        });
        console.log(detailsResponse);
        console.log(detailsResponse?.data?.readings?.npk_readings?.[0]?.reading.N)
        el['current_status'] = isWithinOneHour(detailsResponse?.data?.readings?.atmospheric_tempratrature_readings?.slice(-1)[0]?.timestamp)?"Active":"Not Present";
        el['AIR_SENSOR'] = setStatus(detailsResponse?.data?.readings?.atmospheric_tempratrature_readings?.slice(-1)[0]);
        el['NPK_SENSOR'] = setStatus(detailsResponse?.data?.readings?.npk_readings?.slice(-1)[0]);
        el['SOIL_SENSOR'] = setStatus(detailsResponse?.data?.readings?.soil_temprature_readings?.slice(-1)[0]);
        el['LEAF_SENSOR'] = setStatus(detailsResponse?.data?.readings?.leaf_wetness_readings?.slice(-1)[0]);
        el['RAIN_GAUGE_SENSOR'] = setStatus(detailsResponse?.data?.readings?.rain_guage_readings?.slice(-1)[0]);
        el['WIND_DIRECTION_SENSOR'] = setStatus(detailsResponse?.data?.readings?.wind_direction_readings?.slice(-1)[0]);
        el['WIND_SPEED_SENSOR'] = setStatus(detailsResponse?.data?.readings?.wind_speed_readings?.slice(-1)[0]);
        el['SOLAR_RADIATION_SENSOR'] = setStatus(detailsResponse?.data?.readings?.lux_readings?.slice(-1)[0]);
        el['IRROMETER_PRIMARY'] = setStatus(detailsResponse?.data?.readings?.soil_moisture_reading_1?.slice(-1)[0]);
        el['IRROMETER_SECONDARY'] = setStatus(detailsResponse?.data?.readings?.soil_moistre_readings_2?.slice(-1)[0]);
        el['SOLAR_PANEL'] = setStatus(detailsResponse?.data?.readings?.solar_voltage_readings?.slice(-1)[0]);
        el['BATTERY'] = setStatus(detailsResponse?.data?.readings?.battery_voltage_readings?.slice(-1)[0]);
        el['imei_number'] = setStatus(detailsResponse?.data?.readings?.npk_readings?.slice(-1)[0]);
        return el;
      });
  
      // Wait for all promises to resolve and update the arrays
      const updatedData = await Promise.all(promises);
      console.log(updatedData);
      // Update the state or do other operations with updatedData
      setOriginalArray(updatedData);
      setDirectory(updatedData.filter(el => el.current_status === "Active"));
    } catch (error) {
      console.error(error);
    }
  }
  
  useEffect(()=>{
    fetchDirectory();
  },[])
  useEffect(()=>{
      console.log(originalArray);
      console.log(directoryState);
      console.log("3");
      if(directoryState==="")
      setDirectory(originalArray)
      else if(directoryState==="Inactive Devices")
      setDirectory(originalArray.filter(el => el.current_status === "Not Present"));
      else if(directoryState==="Active Devices")
      setDirectory(originalArray.filter(el => el.current_status === "Active"));
      else
      setDirectory(originalArray.filter(el => el.id.toString()===directoryState));
  },[directoryState]);
  const enterHandler = (e) => {
    setNavExpanded(true);
  };
  const leaveHandler = (e) => {
    setNavExpanded(false);
  };
  function findInactiveAccounts(){
    console.log("1");
    setDirectoryState("Inactive Devices");
  }
  function findActiveAccounts(){
    console.log("1");
    setDirectoryState("Active Devices");
  }
  function findAllDevices(){
    setDirectoryState("");
  }
  function searchHandler(e) {
    e.preventDefault(); 
    console.log(e);
    const searchTerm = e.target.querySelector('input').value;
    console.log(searchTerm)
    setDirectoryState(searchTerm);
  }
  return (
    <>
    <Topbar
        routeTitle="Device Directory "
      />
      <button onClick={findInactiveAccounts}>Inactive Devices</button>
      <button onClick={findAllDevices}>All Devices</button>
      <button onClick={findActiveAccounts}>Active Devices</button>
      <form onSubmit={searchHandler}>
        <input type="text" />
        <button type="submit">Search</button>
      </form>

      <div className="farmerDirectory-main">
        <Navbar
          expand={navExpanded}
          enterHandler={enterHandler}
          leaveHandler={leaveHandler}
        />
    <TableContainer component={Paper} className="table-container">
      {directory.length?<Table aria-label="collapsible table">
        <TableHead  className="sticky-header" >
          <TableRow >
            <TableCell style={{color:"blue"}} align="right"></TableCell>
            <TableCell style={{color:"blue"}} align="right">Farm Id</TableCell>
            <TableCell style={{color:"blue"}} align="right">Farm Name</TableCell>
            <TableCell style={{color:"blue"}} align="right">Farmer Id</TableCell>
            <TableCell style={{color:"blue"}} align="right">Current Crop</TableCell>
            <TableCell style={{color:"blue"}} align="right">Farm Area (acres)</TableCell>
            <TableCell style={{color:"blue"}}>Date of Deployment</TableCell>
            <TableCell style={{color:"blue"}}>Current Status</TableCell>
            <TableCell style={{color:"blue"}}>Hardware Deployment</TableCell>
            <TableCell style={{color:"blue"}}>Firmware Version</TableCell>
            <TableCell style={{color:"blue"}}>Air TPH</TableCell>
            <TableCell style={{color:"blue"}}>Soil TH</TableCell>
            <TableCell style={{color:"blue"}}>NPK</TableCell>
            <TableCell style={{color:"blue"}}>Wind Speed</TableCell>
            <TableCell style={{color:"blue"}}>Wind Direction</TableCell>
            <TableCell style={{color:"blue"}}>Leaf TH</TableCell>
            <TableCell style={{color:"blue"}}>Solar Voltage</TableCell>
            <TableCell style={{color:"blue"}}>Lux Read</TableCell>
            <TableCell style={{color:"blue"}}>Rain gauge</TableCell>
            <TableCell style={{color:"blue"}}>Battery Voltage</TableCell>
            <TableCell style={{color:"blue"}}>Irrometer Primary</TableCell>
            <TableCell style={{color:"blue"}}>Irrometer Secondary</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {directory?.map((row) => (
            <Row authToken={authToken} key={row.id} row={row} />
          ))}
        </TableBody>
      </Table>:<h1 style={{marginLeft:"20px"}}>Device Data is loading or no data is present, kindly wait</h1>
}
    </TableContainer>
    </div>
    </>
  );
}
