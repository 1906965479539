import React, { useContext, useState, useRef, useEffect } from "react";
import axios from "axios";
import Topbar from "../../components/topbar/Topbar";
import Navbar from "../../components/navbar/Navbar";
import "./RemoteSensing.css";

export default function RemoteSensing() {
  const [loading, setLoading] = useState(false);
  const [Data, setData] = useState(null);
  const farmID = useRef();
  const [currentFarmIndex, setCurrentFarmIndex] = useState("");
  // const { state } = useContext(UserContext);
  // const authToken = state.authToken;
  const [navExpanded, setNavExpanded] = useState(false);

  const enterHandler = () => setNavExpanded(true);
  const leaveHandler = () => setNavExpanded(false);

  const handleDownload = () => {
    console.log("Download Clicked");
    const link = document.createElement("a");
    link.href = `https://tonwv78p3g.execute-api.us-west-2.amazonaws.com/test1/download/${farmID.current.value}/${currentFarmIndex}`;
    link.download = "document.pdf"; // specify the filename
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const fetchData = async () => {
    // if(farmID.current.value==undefined)
    // alert("Enter farm ID")
    // if(currentFarmIndex=="")
    // alert("Select NDMI or NDVI ")
    setLoading(true);
    try {
      const response = await axios.get(
        `https://tonwv78p3g.execute-api.us-west-2.amazonaws.com/test1/report/${farmID.current.value}/${currentFarmIndex}`,
        {
          responseType: "blob",
          headers: {
            "Content-Type": "application/json",
            // Add your authorization header if needed
            // "Authorization": `Bearer ${authToken}`,
          },
        }
      );

      const contentType = response.headers["content-type"];
      if (contentType === "image/png" || contentType === "image/svg+xml") {
        const imageBlob = new Blob([response.data], { type: contentType });
        setData(URL.createObjectURL(imageBlob));
      } else {
        console.error("Unsupported content type:", contentType);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const remoteSensingImageForm = async (e) => {
    e.preventDefault();
    await fetchData();
  };

  useEffect(() => {
    fetchData();
  }, [farmID]);

  return (
    <>
      <Topbar routeTitle={"Remote Sensing Report"} no_content />
      <div className="RTWS_main">
        <Navbar
          expand={navExpanded}
          enterHandler={enterHandler}
          leaveHandler={leaveHandler}
        />
        <div className="form_body">
          <form
            onSubmit={remoteSensingImageForm}
            className="RTWS_filter_form"
          >
            <input
              ref={farmID}
              type="text"
              placeholder="Enter the farm ID"
              title="Enter the farm ID"
            />
            <select
              value={currentFarmIndex}
              onChange={(e) => setCurrentFarmIndex(e.target.value)}
            >
              <option value="">Select index</option>
              <option key="1" value="NDVI">
                NDVI
              </option>
              <option key="2" value="NDMI">
                NDMI
              </option>
            </select>
            <button type="submit">Submit</button>
          </form>
          <button onClick={handleDownload}>Download PDF</button>
        </div>
        {loading  ? (
          <div className="loader">Loading...</div>
        ) : (
          Data?<img src={Data} alt="NDVI Image" />:<></>
        )}
      </div>
    </>
  );
}
