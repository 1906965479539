import axios from "axios";
export default async function ActivateWeatherStation(authToken)
{
        console.log("Hello")
        const farm_id = prompt("Enter the farm ID to register the weather station");
        console.log(farm_id);
        
        const axiosConfig = {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        };
      
        try {
          const res = await axios.post(
            `https://fmserver.escorts.co.in/cropAndWeatherMonitoring/register/${farm_id}`,
            null, // Use null or an empty object for the request body if you don't need to send data.
            axiosConfig
          );
          alert(res.data.message);
        } catch (error) {
          console.error("Error:", error);
          alert(error.messagew);
        }
}
