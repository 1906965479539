// One improvement that can be made right now is at every filter be it button or text field the fresh API
// call occurs, this is not how it should be done. Make API call one time and then filter the responce 
// based on the requirement from the base responce.

import React from 'react'
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useEffect,useState,useContext } from 'react';
import axios from 'axios';
import { UserContext } from "../../context/UserContext";
import Topbar from '../../components/topbar/Topbar';
import Navbar from '../../components/navbar/Navbar';
import "./FarmerDirectory.css";
function Row(props) {
  const { row, onClick,authToken } = props;
  const [open, setOpen] = useState(false);
  const [dropdown,setDropdown] =useState([]);
   const handleRowClick =async () => {
    try{
      let response=await axios.get(`https://fmserver.escorts.co.in/admin/farmer/getdetails/${row.id}`,{
        headers: {
          Authorization: `Bearer ${authToken}`,
        }
      })
      console.log(response.data)
      setDropdown(response.data.farms);
      }
      catch(error)
      {
        console.log(error);
      }
   };
   useEffect(() => {
    console.log("Dropdown:", dropdown);
  }, [dropdown]); 
  function numberToMonth(number)
  {
    if(number==1)
    return "January"
    if(number==2)
    return "Febraury"
    if(number==3)
    return "March"
    if(number==4)
    return "April"
    if(number==5)
    return "May"
    if(number==6)
    return "June"
    if(number==7)
    return "July"
    if(number==8)
    return "August"
    if(number==9)
    return "September"
    if(number==10)
    return "October"
    if(number==11)
    return "November"
    if(number==12)
    return "December"

  }
  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => {setOpen(!open);handleRowClick();}}
          >
            {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
          </IconButton>
        </TableCell>
        {/* <TableCell component="th" scope="row">
          {row.name}
        </TableCell> */}
        <TableCell align="right">{row?.id}</TableCell>
        <TableCell align="right">{row?.name}</TableCell>
        <TableCell align="right">{row?.phone_number}</TableCell>
        <TableCell align="right">{row?.email}</TableCell>
        <TableCell align="right">{row?.location}</TableCell>
        <TableCell align="right">{row?.district}</TableCell>
        <TableCell align="right">{row?.numberOfFarms}</TableCell>
        <TableCell align="right">{row?.land_cultivated}</TableCell>
        <TableCell align="right">{row?.membership}</TableCell>
        {/* <TableCell align="right">{row?.holds_kcc}</TableCell> */}
        <TableCell align="right">{row?.lang_preference}</TableCell>
        <TableCell align="right">{row?.own_ekl_tractor}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Farms
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow >
                    <TableCell style={{color:"green"}}>Farm id</TableCell>
                    <TableCell style={{color:"green"}} align="right">Area (acres)</TableCell>
                    <TableCell style={{color:"green"}} align="right">Farm name</TableCell>
                    <TableCell style={{color:"green"}} align="right">Current crop</TableCell>
                    <TableCell style={{color:"green"}}>Sowing or Transplanting Date / Pruning month</TableCell>
                    {/* <TableCell style={{color:"green"}}>Date of Deployment</TableCell>
                    <TableCell style={{color:"green"}}>Current Status</TableCell>
                    <TableCell style={{color:"green"}}>Hardware Deployment</TableCell>
                    <TableCell style={{color:"green"}}>Air TPH</TableCell>
                    <TableCell style={{color:"green"}}>Soil TH</TableCell>
                    <TableCell style={{color:"green"}}>NPK</TableCell>
                    <TableCell style={{color:"green"}}>Wind Speed</TableCell>
                    <TableCell style={{color:"green"}}>Wind Direction</TableCell>
                    <TableCell style={{color:"green"}}>Leaf Cond, moist,temp</TableCell>
                    <TableCell style={{color:"green"}}>Solar Voltage</TableCell>
                    <TableCell style={{color:"green"}}>Lux Read</TableCell>
                    <TableCell style={{color:"green"}}>NPK</TableCell>
                    <TableCell style={{color:"green"}}>Wind Speed</TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dropdown.map((el) => (
                    <TableRow key={el.id}>
                      {/* <TableCell component="th" scope="row">
                        {historyRow.date}
                      </TableCell> */}
                      <TableCell>{el.id}</TableCell>
                      <TableCell align="right">{el.area.toFixed(2)}</TableCell>
                      <TableCell align="right">
                        {el.farm_name}
                      </TableCell>
                      <TableCell align="right">{el.current_crop}</TableCell>
                      <TableCell align="right">{el.crop_type==="ST"?el.sowing_date:numberToMonth(el.pruningDate)}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}



export default function FarmerDirectory() {
  const { state } = useContext(UserContext);
  const authToken = state.authToken;
  const [directory,setDirectory] =useState([]);
  const [navExpanded, setNavExpanded] = useState(false);
  const [directoryState,setDirectoryState] =useState("")
  const [originalArray,setOriginalArray] =useState([])

  async function fetchDirectory() {
    try {
      const response = await axios.get('https://fmserver.escorts.co.in/admin/farmer', {
        headers: {
          Authorization: `Bearer ${authToken}`,
        }
      });
  
      const data = response.data.result;
  
      // Create an array of promises for fetching additional data
      const promises = data.map(async (el) => {
        const detailsResponse = await axios.get(`https://fmserver.escorts.co.in/admin/farmer/getdetails/${el.id}`, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          }
        });
  
        const numberOfFarms = detailsResponse.data.farms.length;
        el['numberOfFarms'] = numberOfFarms;
        return el;
      });
  
      // Wait for all promises to resolve and update the arrays
      const updatedData = await Promise.all(promises);
  
      // Update the state or do other operations with updatedData
      setOriginalArray(updatedData);
      setDirectory(updatedData);
    } catch (error) {
      console.error(error);
    }
  }
  
  useEffect(()=>{
    fetchDirectory();
  },[])
  useEffect(()=>{
      console.log(originalArray);
      console.log("3");
      if(directoryState==="")
      setDirectory(originalArray)
      else if(directoryState==="Deleted Account")
      setDirectory(originalArray.filter(el => el.name.toLowerCase() === "deleted account"));
      else if(directoryState==="Non Deleted Account")
      setDirectory(originalArray.filter(el => el.name.toLowerCase() !== "deleted account"));
      else
      setDirectory(originalArray.filter(el => (el.name.toLowerCase().includes(directoryState.toLowerCase())||el.phone_number.includes(directoryState))));
  },[directoryState]);
  const enterHandler = (e) => {
    setNavExpanded(true);
  };
  const leaveHandler = (e) => {
    setNavExpanded(false);
  };
  function findDeletedAccount(){
    console.log("1");
    setDirectoryState("Deleted Account");
  }
  function findNonDeletedAccounts(){
    console.log("1");
    setDirectoryState("Non Deleted Account");
  }
  function findAllAccount(){
    setDirectoryState("");
  }
  function searchHandler(e) {
    e.preventDefault(); 
    console.log(e);
    const searchTerm = e.target.querySelector('input').value;
    setDirectoryState(searchTerm);
  }
  return (
    <>
    <Topbar
        routeTitle="Farmer Directory"
      />
      <button onClick={findDeletedAccount}>Deleted Accounts</button>
      <button onClick={findAllAccount}>All Accounts</button>
      <button onClick={findNonDeletedAccounts}>Non Deleted Accounts</button>
      <form onSubmit={searchHandler}>
        <input type="text" />
        <button type="submit">Search</button>
      </form>

      <div className="farmerDirectory-main">
        <Navbar
          expand={navExpanded}
          enterHandler={enterHandler}
          leaveHandler={leaveHandler}
        />
    <TableContainer component={Paper} className="table-container">
      {directory.length?<Table aria-label="collapsible table">
        <TableHead  className="sticky-header" >
          <TableRow >
            <TableCell style={{color:"blue"}} align="right"></TableCell>
            <TableCell style={{color:"blue"}} align="right">Farmer Id</TableCell>
            <TableCell style={{color:"blue"}} align="right">Name</TableCell>
            <TableCell style={{color:"blue"}} align="right">Phone</TableCell>
            <TableCell style={{color:"blue"}} align="right">Email</TableCell>
            <TableCell style={{color:"blue"}} align="right">State</TableCell>
            <TableCell style={{color:"blue"}} align="right">District</TableCell>
            <TableCell style={{color:"blue"}} align="right">Total no. of Farms</TableCell>
            <TableCell style={{color:"blue"}} align="right">Land Cultivated (acres)</TableCell>
            <TableCell style={{color:"blue"}} align="right">Membership</TableCell>
            <TableCell style={{color:"blue"}} align="right">Language Preference</TableCell>
            <TableCell style={{color:"blue"}} align="right">Own EKL Tractor</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {directory?.map((row) => (
            <Row authToken={authToken} key={row.id} row={row} />
          ))}
        </TableBody>
      </Table>:<h1 style={{marginLeft:"20px"}}>Farmer Data is loading, kindly wait</h1>
}
    </TableContainer>
    </div>
    </>
  );
}
