import Card from "../../components/card/Card";
import { useEffect, useContext, useReducer, useState } from "react";
import OverviewDataContent from "../../components/OverviewDataContent/OverviewDataContent";
import Topbar from "../../components/topbar/Topbar";
import "./overview.css";
import { UserContext } from "../../context/UserContext";
import { Link, useNavigate } from "react-router-dom";
import { INITIAL_STATE, overviewReducer } from "../../utils/OverviewReducer";
import axios from "axios";
import MapComponent from "../../components/mapComponent/MapComponent";
import Navbar from "../../components/navbar/Navbar";
const Overview = () => {
    const [overviewState, dispatch] = useReducer(overviewReducer, INITIAL_STATE)
    const { state } = useContext(UserContext);
    const navigate = useNavigate();
    const authToken = state.authToken;
    const department = state.department;
    useEffect(() => {
        console.log(authToken,department);
        authToken ?? navigate("/login");
    }, [])
    useEffect(() => {
        const fetchData = async () => {
            try {
                dispatch({ type: "FETCH_START" })
                const res = await axios.get(`https://fmserver.escorts.co.in/admin/general/home`, {
                    headers: {
                        Authorization: `Bearer ${authToken}`
                    }
                })
                dispatch({ type: "FETCH_SUCCESS", payload: res.data })
                console.log(res.data)

            } catch (error) {
                dispatch({ type: "FETCH_ERROR" })
                console.log(error)
            }
        }
        fetchData()
    }, [])
    const getDistricts = async (stateName) => {
        try {
            dispatch({ type: "DIST_FETCH_START" })
            const res = await axios.get(`https://fmserver.escorts.co.in/admin/general/districts?state=${stateName}`, {
                headers: {
                    Authorization: `Bearer ${authToken}`
                }
            })
            dispatch({ type: "DIST_SUCCESS", payload: res.data })

        } catch (error) {
            dispatch({ type: "DIST_ERROR" })
        }
    }
    const getDistData = async (distName) => {
        try {
            const res = await axios.get(`https://fmserver.escorts.co.in/admin/general/districtdata?state=${overviewState.currentState}&district=${distName}`, {
                headers: {
                    Authorization: `Bearer ${authToken}`
                }
            })
            console.log(res.data)
            dispatch({ type: "SET_DIST", payload: { ...res.data, district: distName } })
        } catch (error) {
            console.error(error)
        }
    }
    // Farm Locations
    const [mapPropsReady, setMapPropsReady] = useState(false);
    const [mapCenter, setMapCenter] = useState([48.85, 2.29]);
    const [locations, setLocations] = useState([])
    const [farmer, setFarmer] = useState({})
    const [farmInfo, setFarmInfo] = useState({})
    useEffect(() => {
        // console.log(farmer)
        let farmLocations = [];
        const getLocations = async () => {
            const res = await axios.get(`https://fmserver.escorts.co.in/admin/general/sensorstatus`, {
                headers: {
                    Authorization: `Bearer ${authToken}`
                }
            });
            setFarmer(res.data)
            console.log("%cRES.DATA", "color: red; font-size: large;")
            console.log(res.data)
            farmLocations = await res.data?.activeLocations?.map(farm => {
                return {
                    popup: `Id: ${farm?.id}, Name: ${farm?.farm_name}`,
                    geocode: [
                        farm?.land_coordinates?.[1],
                        farm?.land_coordinates?.[0]
                    ]
                }
            })

            setLocations(farmLocations)
            setMapCenter([
                farmLocations?.reduce((acc, val) => acc + val.geocode[0], 0) / farmLocations?.length,
                farmLocations?.reduce((acc, val) => acc + val.geocode[1], 0) / farmLocations?.length,
            ])
            if (farmLocations?.length) {
                setMapPropsReady(true)
            }
        }
        getLocations()
    }, [])
    useEffect(() => {
        setFarmInfo(farmer?.farms?.[6])
    }, [farmer])
    const enterHandler = (e) => {
        setNavExpanded(true);
      };
      const leaveHandler = (e) => {
        setNavExpanded(false);
      };
      const [navExpanded, setNavExpanded] = useState(false); // sidebar expanded ?
    return (
        <div>
            <Topbar routeTitle={"Overview"} no_content />
            <div className="overview__main">
            <Navbar
          expand={navExpanded}
          enterHandler={enterHandler}
          leaveHandler={leaveHandler}
        />
                <Card otherClass={"overview__main__part"} >
                    <div className="overview__main__left">
                        {/* <div className="navigation__links">
                            {authToken ?
                                (<Link className="navigation__link" to="/iot" > Goto IoT control </Link>) :
                                (<span style={{ color: 'gray', cursor: 'not-allowed' }}>Goto IoT control</span>)

                            }
                            {authToken ?
                                (<Link className="navigation__link" to="/agro" > Goto Agro control </Link>) :
                                (<span className='navigation__link' style={{ color: 'gray', cursor: 'not-allowed' }}>Goto Agro control</span>)

                            }
                        </div> */}
                        <h2 className="overview__card_title">PAN India</h2>
                        <OverviewDataContent
                            crops={overviewState?.cropsList}
                            active={overviewState?.statsData?.totalActive}
                            inactive={overviewState?.statsData?.totalInactive}
                            farmers={overviewState?.statsData?.totalFarmers}
                            area={overviewState?.statsData?.totalAcerage}
                        />
                        {overviewState.error && <h2>Something went wrong</h2>}
                        <h2 className="overview__card_title2">STATE & DISTRICT</h2>
                        <div className="overview__select">
                            <select value={overviewState.currentState}
                                onChange={(e) => {
                                    dispatch({ type: "SET_STATE", payload: `${e.target.value}` });
                                    getDistricts(e.target.value)
                                }}>
                                <option disabled value="">
                                    Select state
                                </option>
                                {overviewState?.states.map((state, index) => {
                                    return (
                                        <option key={state?.state + index} value={state?.state}>{state?.state}</option>
                                    )
                                })}
                            </select>
                            <select value={overviewState.currentDistrict}
                                onChange={e => { getDistData(e.target.value) }}>
                                <option disabled value="">
                                    {overviewState.distLoading ? 'Fetching' : 'Select district'}
                                </option>
                                {!overviewState?.distError ? overviewState?.districts.map((dist, index) => {
                                    return (<option key={dist.district + index} value={dist.district}>{dist.district}</option>)
                                }) : <option value="error">Fetch Error</option>}
                            </select>
                        </div>
                        <OverviewDataContent crops={overviewState?.distCropsList}
                            active={overviewState?.distStatsData?.totalActive}
                            inactive={overviewState?.distStatsData?.totalInactive}
                            farmers={overviewState?.distStatsData?.totalFarmers}
                            area={overviewState?.distStatsData?.totalAcerage}
                        />
                    </div>
                </Card>
                <Card otherClass={"overview__main__part"}>
                    <div className="overview__main__right">
                        <h2 className="overview__card_title">Map</h2>
                        <MapComponent
                            mapCenter={mapPropsReady ? mapCenter : undefined}
                            locationsArray={mapPropsReady ? locations : undefined}
                        //   setMapCenter={setMapCenter}
                        />
                    </div>
                </Card>
            </div>
        </div>
    );
};

export default Overview;
