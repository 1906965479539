import "./agroControl.css";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../context/UserContext";
import { useNavigate } from "react-router-dom";
import Topbar from "../../components/topbar/Topbar";
import Navbar from "../../components/navbar/Navbar";
import Card from "../../components/card/Card";
import axios from "axios";
import { Modal } from "@mui/material";
import AgroFarmerInfoCard from "../../components/farmerInfoCard/agro_farmerInfoCard";
import AgroTable from "../../components/IotTable/AgroTable";
import CropTable from "../../components/IotTable/CropTable";
import AgroChart from "../../components/Chart/AgroChart";
import MapComponent from "../../components/mapComponent/MapComponent";
import FarmerInfoCard from "../../components/farmerInfoCard/FarmerInfoCard";
import { CSVLink } from "react-csv";
import IotTable from "../../components/IotTable/IotTable";
import ChartRange from "../../components/Chart/ChartRange";
import Histogram from "../../components/Chart/Histogram.tsx";
import ChartSeperated from "../../components/Chart/ChartSeperated";
import DownloadButton from "../../components/DownloadButton/DownloadButton"
import CropCalendar from "../../components/CropCalendar/CropCalendar";
const headers = [
  { label: "Timestamp", key: "timestamp" },
  { label: "Atmospheric Temprature", key: "atmosphericTemprature" },
  { label: "Atmospheric Pressure", key: "atmosphericPressure" },
  { label: "Atmospheric Humidity", key: "atmosphericHumidity" },
  { label: "Wind Speed", key: "windSpeed" },
  { label: "Wind Direction", key: "windDirection" },
  { label: "Canopy Temprature", key: "canopyTemprature" },
  { label: "Leaf Wetness", key: "leafWetness" },
  { label: "Solar Voltage", key: "solarVoltage" },
  { label: "Lux Readings", key: "luxReadings" },
  { label: "N Readings", key: "nReadings" },
  { label: "P Readings", key: "pReadings" },
  { label: "K Readings", key: "kReadings" },
  { label: "Soil Moisture", key: "soilMoisture1" },
  { label: "Soil Humidity", key: "soilHumidity" },
  { label: "CO2 Readings", key: "co2Readings" },
  { label: "Precipitation", key: "precipitation" },
  { label: "Battery Voltage", key: "batteryVoltage" },
];

const AgroControl = () => {
  const fieldNames = [
    {
      api_name: "atmospheric_tempratrature_readings",
      label_name: "Air Temperature (°C)",
      borderColor: "rgb(243, 58, 29)",
      backgroundColor: "rgba(243, 58, 29,0.5)",
    },
    {
      api_name: "atmospheric_pressure_redings",
      label_name: "Air Pressure (hPa)",
      borderColor: "rgb(30, 179, 55)",
      backgroundColor: "rgba(30, 179, 55,0.5)",
    },
    {
      api_name: "atmospheric_humidity_readings",
      label_name: "Air Humidity ( %RH)",
      borderColor: "rgb(202, 76, 207)",
      backgroundColor: "rgba(202, 76, 207,0.5)",
    },
    {
      api_name: "wind_speed_readings",
      label_name: "Wind Speed (km/h)",
      borderColor: "#B2ABF2",
      backgroundColor: "#B2ABF2",
    },
    // {
    //   api_name: "wind_direction_readings",
    //   label_name: "Wind Direction (°N)",
    //   borderColor: "#D7C9AA",
    //   backgroundColor: "#D7C9AA",
    // },
    {
      api_name: "leaf_wetness_readings",
      label_name: "Canopy Temperature (°C)",
      borderColor: "rgb(156, 155, 152)",
      backgroundColor: "rgba(156, 155, 152,0.5)",
    },
    {
      api_name: "leaf_wetness_readings",
      label_name: "Leaf Wetness (%RH)",
      borderColor: "rgb(222, 217, 89)",
      backgroundColor: "rgba(222, 217, 89,0.5)",
    },
    {
      api_name: "solar_voltage_readings",
      label_name: "Solar Voltage (V)",
      borderColor: "#F3A712",
      backgroundColor: "#F3A712",
    },
    {
      api_name: "lux_readings",
      label_name: "Lux Readings (w/m2)",
      borderColor: "#29335C",
      backgroundColor: "#29335C",
    },
    {
      api_name: "npk_readings",
      label_name: "N Readings (mg/kg)",
      borderColor: "#29335C",
      backgroundColor: "#29335C",
    },
    {
      api_name: "npk_readings",
      label_name: "P Readings (mg/kg)",
      borderColor: "#29335C",
      backgroundColor: "#29335C",
    },
    {
      api_name: "npk_readings",
      label_name: "K Readings (mg/kg)",
      borderColor: "#29335C",
      backgroundColor: "#29335C",
    },
    {
      api_name: "soil_moisture_reading_1",
      label_name: "Soil Moisture_1 (cb)",
      borderColor: "rgb(243, 141, 46)",
      backgroundColor: "rgba(243, 141, 46,0.5)",
    },
    {
      api_name: "soil_moistre_readings_2",
      label_name: "Soil Moisture_2 (cb)",
      borderColor: "rgb(24, 141, 46)",
      backgroundColor: "rgba(23, 141, 46,0.5)",
    },
    {
      api_name: "soil_humidity_readings",
      label_name: "Soil Humidity (%RH)",
      borderColor: "#6C91C2",
      backgroundColor: "#6C91C2",
    },
    // {
    //   api_name: "co2_level_readings",
    //   label_name: "CO2 Readings",
    //   borderColor: "#534D41",
    //   backgroundColor: "#534D41",
    // },
    {
      api_name: "rain_guage_readings",
      label_name: "Precipitation (mm)",
      borderColor: "rgb(53, 122, 212)",
      backgroundColor: "rgba(53, 122, 212,0.5)",
    },
    {
      api_name: "battery_voltage_readings",
      label_name: "Battery Voltage (V)",
      borderColor: "rgb(1, 163, 170)",
      backgroundColor: "rgba(1, 163, 170, 0.5)",
    },
    {
      api_name: "soil_temprature_readings",
      label_name: "Soil Temperature (°C)",
      borderColor: "rgb(1, 163, 170)",
      backgroundColor: "rgba(1, 163, 170, 0.5)",
    },
  ];
  const [navExpanded, setNavExpanded] = useState(false);
  const [results, setResults] = useState(null); // search Results OR rather Farmer results
  const [farmer, setFarmer] = useState({}); //farmer detail
  const [farmInfo, setFarmInfo] = useState({}); //farm Data
  const [iot, setIot] = useState({}); //IOT data of a farm
  const [mapPropsReady, setMapPropsReady] = useState(false); // these three states are for map
  const [locations, setLocations] = useState([]); // they will run every time the farmer state is changed
  const [mapCenter, setMapCenter] = useState([48.85, 2.29]);
  const [csvLinkData, setCsvLinkData] = useState(""); // for downloading the Iot data
  const [roundedOfWeekPassed, setRoundedOfWeekPasses] = useState(null);
  const [currentStage, setCurrentStage] = useState("");
  let tempCurrentStage;
  const { state } = useContext(UserContext);
  const authToken = state.authToken;
  const navigate = useNavigate();
  useEffect(() => {
    authToken ?? navigate("/login");
  }, []);
  const enterHandler = (e) => {
    setNavExpanded(true);
  };
  const leaveHandler = (e) => {
    setNavExpanded(false);
  };

  useEffect(() => {
    const timestamps = iot?.readings?.rain_guage_readings?.map(
      (item) => item?.timestamp
    );
    // console.log(timestamps)
    const data = [];
    for (let i = 0; i < timestamps?.length; i++) {
      data.push({
        timestamp: timestamps[i],
        atmosphericTemprature:iot?.readings?.atmospheric_tempratrature_readings?.[i]?.reading,
        atmosphericPressure:iot?.readings?.atmospheric_pressure_redings?.[i]?.reading,
        atmosphericHumidity:iot?.readings?.atmospheric_humidity_readings?.[i]?.reading,
        windSpeed: iot?.readings?.wind_speed_readings?.[i]?.reading,
        windDirection: iot?.readings?.wind_direction_readings?.[i]?.reading,
        canopyTemprature:iot?.readings?.canopy_temparture_readings?.[i]?.reading,
        leafWetness: iot?.readings?.leaf_wetness_readings?.[i]?.reading,
        solarVoltage: iot?.readings?.solar_voltage_readings?.[i]?.reading,
        luxReadings: iot?.readings?.lux_readings?.[i]?.reading,
        nReadings: iot?.readings?.npk_readings?.[i]?.reading?.N,
        pReadings: iot?.readings?.npk_readings?.[i]?.reading?.P,
        kReadings: iot?.readings?.npk_readings?.[i]?.reading?.K,
        // soilTemprature: iot?.readings?.soil_temprature_readings?.[i]?.reading,
        soilMoisture1: iot?.readings?.soil_moisture_reading_1?.[i]?.reading,
        soilHumidity: iot?.readings?.soil_humidity_readings?.[i]?.reading,
        co2Readings: iot?.readings?.co2_level_readings?.[i]?.reading,
        precipitation: iot?.readings?.rain_guage_readings?.[i]?.reading,
        batteryVoltage: iot?.readings?.battery_voltage_readings?.[i]?.reading,
      });
    }
    // console.log(data)
    // setCsvData(data)
    setCsvLinkData({ filename: "iot_data.csv", headers: headers, data: data });
    // console.log({filename: 'file.csv', headers: headers, data: data})
  }, [iot]);

  const searchHandler = async (searchQuery) => {
    const res = await axios.get(
      `https://fmserver.escorts.co.in/admin/farmer?search=${searchQuery}`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
    );
    if (res.data.success) {
      setResults(res.data.result);
    }
  };

  // Getting the selected farmer info
  const tableData = (id) => {
    console.log("%ctable Data", "color: red;");
    axios
      .get(`https://fmserver.escorts.co.in/admin/farmer/getdetails/${id}`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then((response) => {
        console.log(response.data);
        setFarmer(response.data);
        setIot("");
      })
      .catch((error) => alert(error));
  };

  useEffect(() => {
    // console.log(farmer)
    let farmLocations = [];
    const getLocations = async () => {
      farmLocations = await farmer?.farms?.map((farm) => {
        return {
          popup: `Id: ${farm?.id}, Name: ${farm?.farm_name}`,
          geocode: [
            farm?.land_coordinates?.reduce((acc, val) => acc + val[1], 0) /
              farm?.land_coordinates?.length,
            farm?.land_coordinates?.reduce((acc, val) => acc + val[0], 0) /
              farm?.land_coordinates?.length,
          ],
        };
      });
      setLocations(farmLocations);
      setMapCenter([
        farmLocations?.reduce((acc, val) => acc + val.geocode[0], 0) /
          farmLocations?.length,
        farmLocations?.reduce((acc, val) => acc + val.geocode[1], 0) /
          farmLocations?.length,
      ]);
      // console.log(farmLocations)
      // console.log([
      //   farmLocations?.reduce((acc,val) => acc + val.geocode[1], 0) / farmLocations?.length,
      //   farmLocations?.reduce((acc,val) => acc + val.geocode[0], 0) / farmLocations?.length,
      // ])
      if (farmLocations?.length) {
        setMapPropsReady(true);
      }
    };
    getLocations();
  }, [farmer]);

  const farmData = (index) => {
    setFarmInfo(farmer?.farms[index]);
    console.log(farmer?.farms[index]);
    axios
      .get(
        `https://fmserver.escorts.co.in/admin/farmer/farm/${farmer?.farms[index]?.id}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      )
      .then((response) => {
        if (response.data.success === true) {
          console.log(response.data);
          setIot(response.data);
        } else {
          console.log(`%c${response?.data?.message}`, "color: red;");
          setIot("");
        }
      })
      .catch((error) => alert(error));
  };

  const chartData = (index) => {
    setFarmInfo(farmer?.farms[index]);
    axios
      .get(`https://fmserver.escorts.co.in/admin/farmer/farm/${3}`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then((response) => {
        if (response.data.success === true) {
          console.log(response.data);
          setIot(response.data);
        } else {
          console.log(`%c${response?.data?.message}`, "color: red;");
        }
      })
      .catch((error) => alert(error));
  };

  const [chartType, setChartType] = useState("AGRO");
  const chartTypeHandler = (e) => {
    setChartType(e.target.value);
  };
  function weeksPassedFromDateToDate(startDate) {
    const oneDay = 24 * 60 * 60 * 1000; // One day in milliseconds
    const currentDate = new Date(); // Current date
    const timeDiff = Math.abs(currentDate - startDate); // Time difference in milliseconds
    const numberOfWeeks = Math.floor(timeDiff / (7 * oneDay)); // Number of full weeks passed
    const numberOfPartialWeeks = Math.ceil((timeDiff % (7 * oneDay)) / oneDay); // Number of days in the partial week

    return numberOfWeeks + numberOfPartialWeeks / 7; // Add partial weeks to the total
  }

  // Example usage:
  const startDate = new Date(farmInfo.sowing_date); // Replace this with your desired start date
  const weeksPassed = weeksPassedFromDateToDate(startDate);
  // setRoundedOfWeekPasses(weeksPassed);
  console.log(
    `Weeks passed since ${startDate.toDateString()}: ${weeksPassed} weeks`
  );
  function checkForColor(item) {
    // console.log(item?.stage)
    if (
      weeksPassedFromDateToDate(startDate) >= item?.week?.[0] &&
      weeksPassedFromDateToDate(startDate) <=
        item?.week?.[item?.week.length - 1]
    )
      return "rgb(230,230,105)";
    else if (weeksPassedFromDateToDate(startDate) <= item?.week?.[0])
      return "rgb(230,159,105)";
    else return "rgb(146,240,146)";
  }
  // function checkForStage()
  // {
  //   console.log("function running")
  //   iot?.cropCalendar?.map((item, index) => {
  //     if(weeksPassedFromDateToDate(startDate)>=item?.week?.[0] && weeksPassedFromDateToDate(startDate)<= item?.week?.[(item?.week.length)-1])
  //     {console.log(item?.stage);
  //     return item?.stage;}
  //   })
  // }

  return (
    <div>
      <Topbar
        routeTitle="Agro Control Center"
        searchFunc={searchHandler}
        dropDowndata={results}
        selectHandler={tableData}
      />

      <div className="agro-control-main">
        <Navbar
          expand={navExpanded}
          enterHandler={enterHandler}
          leaveHandler={leaveHandler}
          compareRoute={"/agro2"}
        />
        <div className="page-main-content agro-main-content">
          <div className="agro__body">
            {farmer.success ? (
              <div className="farm_details">
                <div className="farm_name">
                  <p>Farm Name:&nbsp;</p>
                  <p style={{ fontWeight: "bold" }}>
                    {farmInfo.id ? farmInfo.farm_name : " select farm"}
                  </p>
                </div>
                <div className="current_crop">
                  <p>Current Crop:&nbsp;</p>
                  <p style={{ fontWeight: "bold" }}>
                    {farmInfo.id&&farmInfo.current_crop ? farmInfo.current_crop : "None"}
                  </p>
                </div>
                <div className="farm_area">
                  <p>Farm area:&nbsp;</p>
                  <p style={{ fontWeight: "bold" }}>
                    {farmInfo.id ? `${farmInfo.area.toFixed(2)} acres `: " select farm"}
                  </p>
                </div>
              </div>
            ) : (
              ""
            )}
            {farmer.success ? (
              <>
                <div className="agro__body__main">
                  <div className="agro__left">
                    {/* <AgroFarmerInfoCard farmerInfo={farmer} farmInfo={farmInfo} /> */}
                    <FarmerInfoCard farmerInfo={farmer} farmInfo={farmInfo} />
                    <div className="agro-table__iot">
                      <div className="table__top__flex">
                        <span className="agro-table__name">Agro</span>
                        <select
                          defaultValue=""
                          className="farm__selector__dropdown"
                          onChange={(e) => {
                            farmData(e.target.value);
                          }}
                        >
                          <option value="" disabled>
                            {" "}
                            Select farm{" "}
                          </option>
                          {farmer.farms.map((farm, index) => (
                            <option
                              key={`farm${index}`}
                              value={index}
                              className="agro-farmItem"
                            >
                              Farm ID : {farm.id}, &nbsp;
                              {farm.farm_name}, &nbsp; {farm.district}
                            </option>
                          ))}
                        </select>
                      </div>
                      {iot ? (
                        <div className="agro-float-child">
                          <div className="agro-float-child1">
                            <CropTable farmInfo={farmInfo} />
                          </div>
                          <div className="agro-float-child2">
                            <AgroTable iot={iot} />
                          </div>
                        </div>
                      ) : (
                        <>
                          <center>
                            <h1>Select a farm to view data</h1>
                          </center>
                        </>
                      )}
                    </div>

                    <Card>
                      {/* <div className="chart__select__container">
                      <label htmlFor="chart-select">Chart : </label>
                      <select defaultValue="AGRO" id="chart-select" onChange={chartTypeHandler}>
                        <option value="AGRO">Agro chart</option>
                        <option value="IOT">IOT chart</option>
                      </select>
                    </div> */}
                      <div className="agro__chart">
                        {farmInfo.current_crop ? (
                          <>
                            <AgroChart
                              farmID={farmInfo?.id}
                              farmName={farmInfo?.farm_name}
                              iot={iot}
                              chartType={"AGRO"}
                              setCsvLinkData={setCsvLinkData}
                            />
                            {/* <AgroChart
                            farmID={farmInfo?.id}
                            farmName={farmInfo?.farm_name}
                            iot={iot}
                            chartType={"IOT"}
                            setCsvLinkData={setCsvLinkData}
                          /> */}
                          </>
                        ) : (
                          <h1 style={{ textAlign: "center" }}>
                            Select farm to show data chart
                          </h1>
                        )}
                      </div>
                      <div className="download__report">
                      {farmInfo?.id && iot && (
                            <DownloadButton farmName={farmInfo.farm_name} farmID={farmInfo?.id} fieldValue={fieldNames}/>
                          )}
                      </div>
                    </Card>
                  </div>

                  <div className="agro__right">
                    {/* <div className="iotRight__layer1">
                    <div className="queries">
                      <span>NDVI Gradient</span>
                      <span>00</span>
                    </div>
                    <div className="queries">
                      <span>NDWI Gradient</span>
                      <span>00</span>
                    </div>
                    <div className="queries">
                      <span>GCI</span>
                      <span>00</span>
                    </div>
                  </div>
                  <div className="iotRight__layer2">
                    <div className="sensors">
                      <div className="sensors__stats__item">
                        <span>Soil moisture gradient</span>
                        <span style={{ fontWeight: 'bold', fontSize: 'larger' }}>xx</span>
                      </div>
                      <div className="sensors__stats__item">
                        <span>Img upload location</span>
                        <span style={{ fontWeight: 'bold', fontSize: 'larger' }}>xx</span>
                      </div>
                    </div>
                  </div> */}

                    {/* <form onSubmit={trigger}>                 
                   <select
                   onChange={handleIndexChange} >
                   <option value="NDVI">NDVI</option>
                   <option value="NDMI">NDMI</option>
                  </select>
                  <input onChange={handleDateChange} defaultValue={fDate} type="date"></input>
                  <button type="submit">submit</button>
                  </form> */}

                    <div className="agroRight__layer3">
                      <MapComponent
                        mapCenter={mapPropsReady ? mapCenter : undefined}
                        locationsArray={mapPropsReady ? locations : undefined}
                        currentFarm={farmInfo}
                        setMapCenter={setMapCenter}
                        iot={iot}
                      />
                    </div>
                    <Card>
                      <CropCalendar farmInfo={farmInfo} iot={iot}/>
                    </Card>
                  </div>
                </div>
                <div className="iot__chart">
                  {iot ? (
                    <div className="iot__chart__container">
                      {" "}
                      {fieldNames.map((value, key) => {
                        return (
                          <ChartSeperated
                            fieldValue={value}
                            key={key}
                            iot={iot}
                            farmId={farmInfo?.id}
                            setCsvLinkData={setCsvLinkData}
                          />
                        );
                      })}{" "}
                    </div>
                  ) : (
                    <h2>
                      "No sensors deployed"
                    </h2>
                  )}
                </div>
              </>
            ) : (
              <div
                style={{
                  height: "calc(100vh - 100px)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <center>
                  <h1>Select a farmer to view details</h1>
                </center>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AgroControl;
